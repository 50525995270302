import React from "react";
import Menu from "../Components/Menu";
import { useEffect, useState } from "react";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CustomAlert from "../Components/CustomAlert";

export default function Terms_of_use({language,changeLanguage,userAvatar,setUserAvatar,setUserVisibility,currency,setCurrency,loggedIn,setLoggedIn,addBalance,userProfileOptionsState,setUserProfileOptionsState,userProfileOptions,logOut,link,userBalance,setUserBalance,userID,set_userID,userName,setUserName,set_user_items,setCreatedAt,setEmail,setTradeLink,getuserrefferals,overlay,setOverlay,addPopup,fetchUserUsingPromoCode,promoCodeUsed,tradeLink}) {

    const [click_to_top, set_click_to_top] = useState(false); // State for the "Click to top" button

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: Add smooth scrolling animation
          }); // Scroll to the top of the page on component mount
    }, [click_to_top]);


    return(
        <div>
            <CustomAlert isOpen={overlay} onClose={() =>  setOverlay(false)} addBalance={addBalance} setOverlay={setOverlay} link={link} addPopup={addPopup} fetchUserUsingPromoCode={fetchUserUsingPromoCode} promoCodeUsed={promoCodeUsed} logOut={logOut}/>

            <Menu language={language} changeLanguage={changeLanguage} currency={currency} setCurrency={setCurrency} loggedIn={loggedIn} userBalance={userBalance} addBalance={addBalance} setUserProfileOptionsState={setUserProfileOptionsState} userProfileOptions={userProfileOptions} logOut={logOut} userAvatar={userAvatar} userName={userName} userProfileOptionsState={userProfileOptionsState} userID={userID} set_userID={set_userID} setUserAvatar={setUserAvatar} setUserBalance={setUserBalance} setUserVisibility={setUserVisibility} setLoggedIn={setLoggedIn} setUserName={setUserName} set_user_items={set_user_items} link={link} setCreatedAt={setCreatedAt} setEmail={setEmail} setTradeLink={setTradeLink} getuserrefferals={getuserrefferals} fetchUserUsingPromoCode={fetchUserUsingPromoCode} addPopup={addPopup} promoCodeUsed={promoCodeUsed} tradeLink={tradeLink}/>
            
            <div className="mt-5 container selectText" style={{padding:'25px',fontWeight:'500'}}>
            

            <h2 className="mt-3" style={{color:'#dcdcdc',fontSize:'17px'}}>How We Gather Data About You</h2>

            <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>Any information that can be used to identify you specifically, such as your name or email address, or that can be used to indirectly identify you, such your preferred language or unit of currency, is referred to as personal data or data.</p>

            <p style={{color:'#7F808E',fontSize:'15px'}}>Directly from you or one of our partners, we gather data.</p>

            <p style={{color:'#7F808E',fontSize:'15px'}}>We gather technical data and information about how you use our websites and mobile applications when you visit them.</p>

            <p style={{color:'#7F808E',fontSize:'15px'}}>Our partners' or our own cookies are used to accomplish this. We require this information in order to give you the websites and apps, analyze how you use them, remember your preferences, and tailor the content and adverts you see.</p>

            <h2 className="mt-5" style={{color:'#dcdcdc',fontSize:'17px'}}>Data we collect :</h2>

            <p className="mt-4" style={{color:'#dcdcdc',fontSize:'15px'}}>1. Steam Trade URL</p>
            <p style={{color:'#dcdcdc',fontSize:'15px'}}>2. Steam ID</p>
            <p style={{color:'#dcdcdc',fontSize:'15px'}}>3. Email (optional)</p>
            <p style={{color:'#dcdcdc',fontSize:'15px'}}>4. IP address (for security reasons)</p>

            <p className="mt-4" style={{color:'#7F808E',fontSize:'13px'}}>To identify you and give you access to the websites or apps that can access your device, location, etc., we use these data.</p>

            <p style={{color:'#dcdcdc',fontSize:'15px'}}>5. Account Information</p>

            <p style={{color:'#7F808E',fontSize:'13px'}}>We obtain particular information from a corresponding third party when you access the websites using your Steam account.</p>

            <p style={{color:'#7F808E',fontSize:'13px'}}>Your account ID (which is  your Steam ID), username, avatar, list of your virtual items, and other publicly available data may be included in this data.</p>

            <p style={{color:'#7F808E',fontSize:'13px'}}>Provides information about your registration, the quantity of trades you have made and your trade link.</p>

            <p style={{color:'#7F808E',fontSize:'13px'}}>In your profile, you can include extra details like your avatar.</p>

            <h5 className="mt-5" style={{color:'#dcdcdc',fontSize:'15px'}}>We make every effort to stop fraud and other unlawful conduct. We may request more information from you if your activity, transaction, or payment method raises suspicion in our eyes or causes us to authorize withdrawals:</h5>

            <p className="mt-4" style={{color:'#7F808E',fontSize:'13px'}}>1. Initials and last name</p>

            <p style={{color:'#7F808E',fontSize:'13px'}}>2. A photograph of you holding an ID</p>

            <p style={{color:'#7F808E',fontSize:'13px'}}>3. Your bank card's image</p>

            <h2 className="mt-5" style={{color:'#dcdcdc',fontSize:'17px'}}>Purchase and Payment Information</h2>

            <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>You give your payment information to a specific payment service provider, like PayPal, when you make a purchase or ask for a refund. Their privacy policies govern how they handle your financial information.</p>

            <p style={{color:'#7F808E',fontSize:'15px'}}>We might get information about your credit card issuer from our payment partners and process a portion of your credit card number.</p>

            <p style={{color:'#7F808E',fontSize:'15px'}}>Your complete address might be gathered for billing purposes.</p>

            <h2 className="mt-5" style={{color:'#dcdcdc',fontSize:'17px'}}>Survey Results</h2>

            <p style={{color:'#7F808E',fontSize:'15px'}}>We occasionally plan surveys purely for research purposes.</p>

            <p style={{color:'#7F808E',fontSize:'15px'}}>We gather questionnaire responses and respondent profiles (gender, age, education level, occupation, and place of residence) for this purpose.</p>

            <p style={{color:'#7F808E',fontSize:'15px'}}>Our study findings may be published in journals on open access websites. Unless otherwise specified in writing with the respondent, research results contain aggregated, depersonalized data.</p>

            <p style={{color:'#7F808E',fontSize:'15px'}}>You have the right to revoke your permission at any time if the processing of your personal data is based on it by sending us an email at<a href="mailto:support@toptrade.gg" style={{color:'#dcdcdc',textDecoration:'none'}}><> support@toptrade.gg</></a></p>

            <h2 className="mt-5" style={{color:'#dcdcdc',fontSize:'17px'}}>Our proper interests are as follows:</h2>

            <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>To create, enhance, and provide our services; to establish our services as leaders in the gaming industry; to make our users' use of our services secure; to contest chargebacks and settle disputes; and to stop fraud and other illegitimate use of our services.</p>

            <h2 className="mt-5" style={{color:'#dcdcdc',fontSize:'17px'}}>Underaged privacy</h2>

            <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>We never knowingly collect or process Personal Data from children.</p>

            <p style={{color:'#7F808E',fontSize:'15px'}}>For the purposes of this privacy statement, a child is defined as a person who is younger than 13 years old in the United States or younger than 16 years old in the European Union (or the minimum age required by relevant legislation for consent to the collection and processing of Personal Data, if different).</p>

            <p style={{color:'#7F808E',fontSize:'15px'}}>Please get in touch with us at support@toptrade.gg if you're a parent or legal guardian and you think your child has provided us with personal information.</p>

            <h2 className="mt-5" style={{color:'#dcdcdc',fontSize:'17px'}}>Breach of Personal Data</h2>

            <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>If a Personal Data breach is not properly handled straight away, it could put your rights and freedoms at danger on a physical, material, or immaterial level.</p>

            <p style={{color:'#7F808E',fontSize:'15px'}}>Therefore, as soon as we learn of a personal data breach, we will report it to the Commissioner for personal data protection without undue delay and, where practical, within 72 hours of learning of it, unless we can prove, in accordance with the accountability principle, that the personal data breach is unlikely to put your rights and freedoms at risk.</p>

            <p style={{color:'#7F808E',fontSize:'15px'}}>The reasons for the delay shall be included with the notice without further delay if such notification cannot be made within 72 hours.</p>

            <h2 className="mt-5" style={{color:'#dcdcdc',fontSize:'17px'}}>Revisions to the Privacy Policy</h2>

            <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>We may occasionally change this privacy statement. Any modifications to the Privacy Policy will be posted. You confirm that you are aware of the amended Privacy Policy if you keep using our services.</p>

            <p style={{color:'#7F808E',fontSize:'15px'}}>Before using the Services, visiting the websites, or using the Application, we advise you to check our Privacy Policy and to promptly read any revisions.</p>

            <h2 className="mt-5" style={{color:'#dcdcdc',fontSize:'17px'}}>When can I receive my refferal funds?</h2>

            <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>As off curently you can receive your refferal funds, transfer them to you tradable balance, once you have reached $5.00 or more, or else, once your refferals have traded more than $500.00 (estimate) on TopTrade.gg.</p>

            <ExpandLessIcon className="mt-5 mb-5" onClick={() => set_click_to_top(!click_to_top)} style={{float:'right',color:'#7F808E',fontSize:'2.3rem',cursor:'pointer'}}/>
        </div>
        </div>
        
    )
}