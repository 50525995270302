import React from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import '../Css/Item.css';
import LockIcon from '@mui/icons-material/Lock';
import PopupWindow from './PopupWindow';
import { useEffect } from "react";

export default function Bot_item({bInvClickAbove,checkPositionBot,closeOtherCards,userID,overlay,currency,language,index,bot_offer_value,set_bot_offer_value,set_bot_items_trade,bot_items,item,refreshingBotInv,addPopup}) {

    const item_price = item[9]

    function display(){
        bot_items.splice(index,1)
        set_bot_items_trade(bot_items_trade => [...bot_items_trade,item])
        set_bot_offer_value(bot_offer_value + item_price)
        hidePopUp()
    }

    var stattrak = false
    var souvenir = false

    if (item[4].includes('StatTrak')) {
        var stattrak = true
    } else if (item[4].includes('Souvenir')) {
        var souvenir = true
    }

    function showPopUp() {
        const name = 'popUp'+index;
        if (document.getElementById(name).style.display == 'none'){
            document.getElementById(name).style.display = 'block';
            document.getElementById(cardId()).style.backgroundColor = '#3F414D';
            document.getElementById(buttonID()).style.backgroundColor = '#ffca4f';
            document.getElementById(cartID()).style.color = '#302E3A';
    
            document.getElementById('popUpImage' + index).style.position = 'relative';
            document.getElementById('popUpImage' + index).style.transform = 'rotate(-1deg) scale(1.025)';
            document.getElementById('popUpImage' + index).style.transition = 'transform 0.3s';
            document.getElementById('popUpClickIcon' + index).style.display = 'block';
        } else {
            hidePopUp()
        }
    }

    function hidePopUp() {
        const name = 'popUp' + index;
        const popupElement = document.getElementById(name);
        if (popupElement !== null) {
            popupElement.style.display = 'none';
        }
    
        const cardElement = document.getElementById(cardId());
        if (cardElement !== null) {
            cardElement.style.backgroundColor = '#24252E';
        }
    
        const buttonElement = document.getElementById(buttonID());
        if (buttonElement !== null) {
            buttonElement.style.backgroundColor = '#333540';
        }
    
        const cartElement = document.getElementById(cartID());
        if (cartElement !== null) {
            cartElement.style.color = '#7F808E';
        }
    
        const imageElement = document.getElementById('popUpImage' + index);
        if (imageElement !== null) {
            imageElement.style.position = 'relative';
            imageElement.style.transform = 'rotate(+1deg) scale(1.0)';
            imageElement.style.transition = 'transform 0.3s';
        }
    
        const clickIconElement = document.getElementById('popUpClickIcon' + index);
        if (clickIconElement !== null) {
            clickIconElement.style.display = 'none';
        }
    }
    
    function cardId(){
        return 'botCard'+index
    }

    function buttonID(){
        return 'botCardButton'+index
    }

    function cartID(){
        return 'botCardCart'+index
    }

    function adjustWear(float){ 
        if (parseFloat(float) < 0.07){
            return 'FN' +' / ' + float
        } else if (parseFloat(float) < 0.15 && parseFloat(float) >= 0.07){ 
            return 'MW'+' / ' + float
        } else if (parseFloat(float) < 0.38 && parseFloat(float) >= 0.15){
            return 'FT'+' / ' + float
        } else if (parseFloat(float) < 0.45 && parseFloat(float) >= 0.38){
            return 'WW'+' / ' + float
        } else if (parseFloat(float) >= 0.45){
            return 'BS'+' / ' + float
        } else {
            return float
        }
    }

    function getDaysLeft(dateString) {
        // Parse the given date string into a Date object
        const targetDate = new Date(dateString);
      
        // Get the current date
        const currentDate = new Date();
      
        // Calculate the difference between the two dates in milliseconds
        const timeDifference = targetDate.getTime() - currentDate.getTime();
      
        // Convert the milliseconds difference to days
        const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      
        return daysLeft;
    }

    function checkSticker(item){
        if (item != [] && item != undefined){
            if (item.length == 0 || refreshingBotInv == true || item['marketHashName'] == null){
                return ''
            } else {
                return 'https://community.akamai.steamstatic.com/economy/image/'+item['image']
            }
        } else {
            return ''
        }
    }

    function parseDateString(input) {
        // Use regular expression to extract date and time components
        const regex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/;
        const matches = input.match(regex);

        if (!matches) {
            // Invalid input format, return the original input
            return input;
        }

        const [, year, month, day, hour, minute] = matches;

        // Convert month and day to numbers and remove leading zeros
        var monthNumber = parseInt(month, 10);
        if (monthNumber < 10) {
            monthNumber = '0' + monthNumber.toString();
        }

        const dayNumber = parseInt(day, 10);

        // Remove leading zero from hour if it exists
        const formattedHour = hour.replace(/^0/, '');

        // Create the output string
        const output = `${dayNumber}.${monthNumber}.${year}, ${formattedHour}:${minute}`;
        return output;
    }

    const handleRightClick = (event) => {
        event.preventDefault();
    };

    const handleLeftClick = (event) => {
    event.preventDefault();
    checkPositionBot(event);
    };

    const numberOfStickers=(item[12].length == 0?0:1) + (item[13].length == 0?0:1) + (item[14].length == 0?0:1) + (item[15].length == 0?0:1) + (item[16].length == 0?0:1)
    const CardstickerNumber = [1,2,3,4,5].slice(0, numberOfStickers)

    function adjustPopUp(){
        if (document.getElementById('popUp'+index) !== null){
            // if hovering over different card, close other popups
            if (document.getElementById('popUp'+index).style.display == 'none'){
                closeOtherCards('bot')
            }
        }
    }

    return (
        <div className="col-2 gx-1 mb-1 hovereff-b" style={{display:(item[17] == true || item[18]?'none':'block')}}>
            {item[9] != null?

            <div>
                <Card onContextMenu={handleRightClick} onClick={handleLeftClick} onMouseOver={adjustPopUp} className="botCard" id={cardId()} style={{position:'relative', overflow:'hidden', visibility:(item[0] == '' && item[9] == 0?'hidden':'')}} onMouseLeave={hidePopUp}>
                
                {refreshingBotInv == false
                ?
                <Card.Text onClick={showPopUp} style={{position:'absolute'}}>{getDaysLeft(item[8]) > 0?<a className="lockedItems" style={{textDecoration:'none',color:'#7F808E',opacity:'1',top:'0px',left:'4px',fontSize:'12px'}}><LockIcon style={{width:'16',height:'16',top:'-2px',position:'relative'}}/>{getDaysLeft(item[8])} Days<div className="exactTime">{parseDateString(item[8])}</div></a>:<></>}</Card.Text>
                :
                <div className="lockedItems loading" style={{width:'25%',height:'10%',backgroundColor:'#3C3F4A',color:'transparent',position:'absolute',left:'5%',top:'5%'}}></div>}
                
                {refreshingBotInv == false
                ?
                <center className="next_to_image_filler" onClick={showPopUp}><Card.Img id={'popUpImage'+index} className="botCardImage" variant="top" src={"https://steamcommunity-a.akamaihd.net/economy/image/"+item[2]} loading="lazy"/></center>
                :
                <div className="next_to_image_filler"><div className="botCardImage" variant="top" loading="lazy"/></div>
                }
                
                {checkSticker(item[12]) != ''?<img className={'Cardsticker'+CardstickerNumber.shift()} src={checkSticker(item[12])}></img>:<img className='CardstickerHide' src={checkSticker(item[12])}></img>}
                {checkSticker(item[13]) != ''?<img className={'Cardsticker'+CardstickerNumber.shift()} src={checkSticker(item[13])}></img>:<img className='CardstickerHide' src={checkSticker(item[13])}></img>}
                {checkSticker(item[14]) != ''?<img className={'Cardsticker'+CardstickerNumber.shift()} src={checkSticker(item[14])}></img>:<img className='CardstickerHide' src={checkSticker(item[14])}></img>}
                {checkSticker(item[15]) != ''?<img className={'Cardsticker'+CardstickerNumber.shift()} src={checkSticker(item[15])}></img>:<img className='CardstickerHide' src={checkSticker(item[15])}></img>}
                {checkSticker(item[16]) != ''?<img className={'Cardsticker'+CardstickerNumber.shift()} src={checkSticker(item[16])}></img>:<img className='CardstickerHide' src={checkSticker(item[16])}></img>}
                <Card.Body style={{height:'150px'}}>

                    {refreshingBotInv == false
                    ?
                    <Card.Text onClick={showPopUp} className="botCardText" style={{color:'#7F808E'}} >
                    {stattrak?<div className="last-letter-color">S {adjustWear(Math.round(parseFloat(item[11]) * 10000) / 10000)}</div>:(souvenir?<div className="last-letter-color-souvenir">S {adjustWear(Math.round(parseFloat(item[11]) * 10000) / 10000)}</div>:<div>{adjustWear(Math.round(parseFloat(item[11]) * 10000) / 10000)}</div>)}
                    </Card.Text>
                    :
                    <div><div className="botCardText loading" style={{height:'50%',width:'75%',backgroundColor:'#3C3F4A',color:'transparent'}}>loading</div><br></br></div>
                    }

                    {refreshingBotInv == false
                    ?
                    <Card.Title onClick={showPopUp} className="botCardTitle" style={{color:'#dcdcdc',position:'relative',fontWeight:'bold'}}>{currency == '$ USD'?<div>$ {item_price.toFixed(2)}</div>:<div>€ {(item_price*0.92).toFixed(2)}</div>}<img id={'popUpClickIcon' + index} className="right-click-icon" src={require('../images/r-click.png')}></img></Card.Title>
                    :
                    <div className="botCardTitle loading" style={{width:'100%',backgroundColor:'#3C3F4A',color:'transparent'}}>loading</div>
                    }
                    
                    {refreshingBotInv == false
                    ?
                    <div>
                        {overlay?<Button className="botCardButton" id={buttonID()}><ShoppingCartIcon className="cart" id={cartID()} style={{color:'#7F808E',height:'15px',position:'relative',top:'-8px'}}/></Button>:<Button className="botCardButton" onClick={display} id={buttonID()}><ShoppingCartIcon id={cartID()} className="cart" style={{color:'#7F808E',height:'15px',position:'relative',top:'-8px'}}/></Button>}
                    </div>
                    :
                    <div></div>
                    }
                    
                </Card.Body>
            </Card>
            <PopupWindow bInvClickAbove={bInvClickAbove} addPopup={addPopup} language={language} showPopUp={showPopUp} hidePopUp={hidePopUp} userID={userID} overlay={overlay} index={index} item={item} calledFrom={'bot'}/>
            </div>
            :null}
        </div>
    );
}
