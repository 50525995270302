import React, { Component } from 'react';
import '../Css/CustomPopup.css';

class CustomPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: true,
    };
    this.timeoutId = null;
  }

  componentDidMount() {
    if (!this.props.noAutoHide) {
      this.startAutoHideTimer();
    }
  }

  componentWillUnmount() {
    this.clearAutoHideTimer();
  }

  startAutoHideTimer = () => {
    this.timeoutId = setTimeout(this.hidePopup, 4000);
  }

  clearAutoHideTimer = () => {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  hidePopup = () => {
    this.setState({ visible: false }, () => {
      if (this.props.onDestroy) {
        setTimeout(this.props.onDestroy, 350); // Adjust the delay to match the transition duration
      }
    });
  };

  render() {
    const { visible } = this.state;
    const { message } = this.props;

    return (
      <div className={`custom-popup-container ${visible?'visible':''}`}>
        {visible && (
          <>
            <div className="custom-popup-content">{message}</div>
            <div className="custom-bottom-bar" />
          </>
        )}
      </div>
    );
  }
}

export default CustomPopup;