import React from "react";
import Menu from "../Components/Menu";
import {Link} from 'react-router-dom';
import Language_wrapper from '../Components/Language_wrapper';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';import AddLinkIcon from '@mui/icons-material/AddLink';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CustomAlert from "../Components/CustomAlert";
import Transaction_block from "../Components/Transaction_block";
import '../Css/Main_app.css';

export default function Transactions({getuserrefferals,language,changeLanguage,userAvatar,setUserAvatar,setUserVisibility,currency,setCurrency,loggedIn,setLoggedIn,addBalance,overlay,userProfileOptionsState,setUserProfileOptionsState,userProfileOptions,logOut,link,userBalance,setUserBalance,userID,set_userID,userName,setUserName,set_user_items,createdAt,setCreatedAt,setEmail,setTradeLink,email,tradeLink,backpackitems,setOverlay,user_transactions,addPopup,fetchUserUsingPromoCode,promoCodeUsed}) {
  
    return (
        <div>
            <CustomAlert isOpen={overlay} onClose={() =>  setOverlay(false)} addBalance={addBalance} setOverlay={setOverlay} link={link} addPopup={addPopup} fetchUserUsingPromoCode={fetchUserUsingPromoCode} promoCodeUsed={promoCodeUsed} logOut={logOut}/>

            <Menu language={language} changeLanguage={changeLanguage} currency={currency} setCurrency={setCurrency} loggedIn={loggedIn} userBalance={userBalance} addBalance={addBalance} setUserProfileOptionsState={setUserProfileOptionsState} userProfileOptions={userProfileOptions} logOut={logOut} userAvatar={userAvatar} userName={userName} userProfileOptionsState={userProfileOptionsState} userID={userID} set_userID={set_userID} setUserAvatar={setUserAvatar} setUserBalance={setUserBalance} setUserVisibility={setUserVisibility} setLoggedIn={setLoggedIn} setUserName={setUserName} set_user_items={set_user_items} link={link} setCreatedAt={setCreatedAt} setEmail={setEmail} setTradeLink={setTradeLink} getuserrefferals={getuserrefferals} fetchUserUsingPromoCode={fetchUserUsingPromoCode} addPopup={addPopup} promoCodeUsed={promoCodeUsed} tradeLink={tradeLink}/>

            <div className="mt-4 profileLeft" style={{color:'#dcdcdc',backgroundColor:'#2E303A', borderRadius:'8px'}}>
                <div className="on_profile_padding">
                    <Link to='/profile' style={{textDecoration:'none'}}><div className='userProfileOptions_item_on_profile mt-2'><AccountCircleIcon style={{fontSize:'17px',marginRight:'3px'}} className="icon"/> <Language_wrapper string_in_slovak={'Profil'} string_in_english={"Profile Details"} string_in_russian={'детали профиля'} string_in_german={'Profildetails'} language={language}/></div></Link>
                    <Link to='/backpack' style={{textDecoration:'none'}}><div className='userProfileOptions_item_on_profile mt-2'><Inventory2Icon style={{fontSize:'17px',marginRight:'3px',marginTop:'1px'}} className="icon"/> <Language_wrapper string_in_slovak={'Tvoj Batoh'} string_in_english={"Your Backpack"} string_in_russian={'Ваш рюкзак'} string_in_german={'Dein Rucksack'} language={language}/></div></Link>
                    <Link to='/transactions' style={{textDecoration:'none'}}><div className='userProfileOptions_item_on_profile mt-2' style={{color:'#dcdcdc',backgroundColor:'#383a45', borderRadius:'3px'}}><ReceiptLongIcon style={{color:'#ffca4f',fontSize:'17px',marginRight:'3px'}} className="icon" /> <Language_wrapper string_in_slovak={'Transakcie'} string_in_english={"Transaction History"} string_in_russian={'История транзакций'} string_in_german={'Verlauf der Transaktionen'} language={language}/></div></Link>
                    <Link to='/affiliate' style={{textDecoration:'none'}}><div className='userProfileOptions_item_on_profile mt-2'><AddLinkIcon style={{fontSize:'17px',marginRight:'3px'}} className="icon"/> <Language_wrapper string_in_slovak={'Partnerský program'} string_in_english={"Affiliate Program"} string_in_russian={'Партнерская программа'} string_in_german={'Partnerprogramm'} language={language}/></div></Link>
                    <div className='userProfileOptions_item_on_profile logout' onClick={() => (logOut(),window.location.href=(link == 'http://127.0.0.1:5000'?'http://127.0.0.1:3000':'https://toptrade.gg/'))}><ArrowOutwardIcon style={{fontSize:'17px',marginRight:'3px',position:'relative',top:'-2px'}}/> Sign Out</div>
                </div>
            </div>
            
            <div className="mt-4 profileRight on_profile_padding_right_box selectCursor selectText" style={{backgroundColor:'#24252E',borderRadius:'8px'}}>
                <div className="Profile_details "><ReceiptLongIcon style={{color:'#ffd269',top:'-2px',position:'relative',fontSize:'20px',left:'1px',marginLeft:'0px'}} className="icon"/> <strong style={{color:'#dcdcdc',fontSize:'17px',fontWeight:'400',position:'relative',top:'-1px',marginLeft:'2px'}}><Language_wrapper string_in_slovak={"Transakcie"} string_in_english={"Transaction History"} string_in_russian={'История транзакций'} string_in_german={'Verlauf der Transaktionen'} language={language}/></strong></div>

                <div className="mb-3" style={{paddingLeft:'15px',paddingRight:'15px'}}>
                    <div style={{padding:'20px 20px 0px 20px'}}>
                        <div className="row">
                            <div className="col-3 col-sm-2 col-md-3 col-lg-2 selectCursor selectText" style={{color:'#7F808E',fontSize:'15px',fontWeight:'400'}}>Type</div>
                            <div className="col-3 col-sm-6 col-md-3 col-lg-4 selectCursor selectText" style={{color:'#7F808E',fontSize:'15px',fontWeight:'400'}}>Details</div>
                            <div className="col-3 col-sm-2 col-md-3 col-lg-3 selectCursor selectText" style={{color:'#7F808E',fontSize:'15px',fontWeight:'400',paddingRight:'130px'}}><center>Date</center></div>
                            <div className="col-3 col-sm-2 col-md-3 col-lg-3 selectCursor selectText" style={{color:'#7F808E',fontSize:'15px',fontWeight:'400'}}><center>Status</center></div>
                        </div>
                    </div>
                    <div style={{height:'650px',overflowY:'scroll', paddingTop:'10px'}}>
                        {user_transactions.slice().sort((a, b) => b[0] - a[0]).map((item, index) => (<Transaction_block addPopup={addPopup} item={item} index={index} key={index} userAvatar={userAvatar} />))}
                    </div>
                </div>                
            </div>
        </div>   
    )
} 