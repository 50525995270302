import React, { useEffect, useRef } from 'react';
import noUiSlider from 'nouislider';
import 'nouislider/dist/nouislider.css';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import throttle from 'lodash.throttle';
import Language_wrapper from './Language_wrapper';

const TradeFilterLockSlider = ({ language, setTradeLock, tradeLockValue }) => {
  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current) {
      const slider = noUiSlider.create(sliderRef.current, {
        start: tradeLockValue,
        range: {
          min: 0,
          max: 8,
        },
        connect: [true, false],
        step: 1, // Specify the step size
      });

       // Wrap handleSlide in a throttled function
      const throttledHandleSlide = throttle(handleSlide, 1); // Adjust the throttle delay as needed

      slider.on('slide', throttledHandleSlide);

      return () => {
        slider.destroy(); // Clean up the slider when the component unmounts
      };
    }
  }, [tradeLockValue]); // Trigger effect when tradeLockValue changes

  const handleSlide = (values) => {
    const snappedValue = Math.round(parseFloat(values[0])); // No need for snapToStep function
    setTradeLock(snappedValue);
  };

  const days = Array.from({ length: 9 }, (_, index) => (
    <div
      key={index}
      role="button"
      className={`trade-filter-lock-slider__day ${index <= tradeLockValue ? 'active' : ''}`}
      onClick={() => setTradeLock(index)}
    ></div>
  ));

  return (
    <div className="trade-filter-lock-slider" data-trade-lock-filter={tradeLockValue}>
      <div className="trade-filter-lock-slider__container">
        <div className="trade-filter-lock-slider__days">{days}</div>
        <div className="trade-filter-lock-slider__bar"></div>
        <div className="trade-filter-lock-slider__core noUi-target noUi-ltr noUi-horizontal noUi-txt-dir-ltr" ref={sliderRef}>
          <div className="noUi-base">
            <div className="noUi-connects">
              <div className="noUi-connect" style={{ transform: 'translate(0%, 0px) scale(1, 1)' }}></div>
            </div>
            <div className="noUi-origin">
              <div className="noUi-handle noUi-handle-lower"></div>
            </div>
          </div>
        </div>
      </div>
      <span>
      <center className=''>{tradeLockValue==0?<div style={{color:'#7f808e',fontSize:'15px',fontWeight:'500'}}><LockOpenIcon style={{width:'14',height:'14',position:'relative',top:'-2px',left:'-1px'}}/>Tradable</div>:<div className='tradeLockFilterText' style={{color:'#7f808e',fontSize:'15px',fontWeight: '500',marginLeft:'-3px',marginTop:'0px'}}><LockIcon style={{width:'14',height:'14',position:'relative',marginTop:'-3px',paddingRight:'0px'}}/> <Language_wrapper string_in_slovak={'Menej ako'} string_in_english={"Less than "} string_in_russian={'менее '} string_in_german={'Weniger als '} language={language}/> {tradeLockValue} <Language_wrapper string_in_slovak={'Dní'} string_in_english={"Days"} string_in_russian={'дней'} string_in_german={'Tage'} language={language}/></div>}</center>
      </span>
    </div>
  );
};

export default TradeFilterLockSlider;