import React from "react";
import Menu from "../Components/Menu";
import CustomAlert from "../Components/CustomAlert";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Language_wrapper from "../Components/Language_wrapper";
import { Link } from "react-router-dom";

export default function Help({language,changeLanguage,userAvatar,setUserAvatar,setUserVisibility,currency,setCurrency,loggedIn,setLoggedIn,addBalance,overlay,userProfileOptionsState,setUserProfileOptionsState,userProfileOptions,logOut,link,userBalance,setUserBalance,userID,set_userID,userName,setUserName,set_user_items,setCreatedAt, setEmail, setTradeLink,getuserrefferals,setOverlay,addPopup,fetchUserUsingPromoCode,promoCodeUsed,tradeLink}) {

    const [whoAreWe,setWhoAreWe] = React.useState(false)
    const [whoAreWeChevron,setWhoAreWeChevron] = React.useState(0)

    const [howToTrade,setHowToTrade] = React.useState(false)
    const [howToTradeChevron,setHowToTradeChevron] = React.useState(0)

    const [howToTradeLocked,setHowToTradeLocked] = React.useState(false)
    const [howToTradeLockedChevron,setHowToTradeLockedChevron] = React.useState(0)

    const [howToBalance,setHowToBalance] = React.useState(false)
    const [howToBalanceChevron,setHowToBalanceChevron] = React.useState(0)

    const [howToItemStatus,setHowToItemStatus] = React.useState(false)
    const [howToItemStatusChevron,setHowToItemStatusChevron] = React.useState(0)

    const [howToProblemsWithTrading,setHowToProblemsWithTrading] = React.useState(false)
    const [howToProblemsWithTradingChevron,setHowToProblemsWithTradingChevron] = React.useState(0)

    const [howToAccountSecurity,setHowToAccountSecurity] = React.useState(false)
    const [howToAccountSecurityChevron,setHowToAccountSecurityChevron] = React.useState(0)

    const [howToItemPrices,setHowToItemPrices] = React.useState(false)
    const [howToItemPricesChevron,setHowToItemPricesChevron] = React.useState(0)

    return (
        <div>
            <CustomAlert isOpen={overlay} onClose={() =>  setOverlay(false)} addBalance={addBalance} setOverlay={setOverlay} link={link} addPopup={addPopup} fetchUserUsingPromoCode={fetchUserUsingPromoCode} promoCodeUsed={promoCodeUsed} logOut={logOut}/>

            <Menu language={language} changeLanguage={changeLanguage} currency={currency} setCurrency={setCurrency} loggedIn={loggedIn} userBalance={userBalance} addBalance={addBalance} setUserProfileOptionsState={setUserProfileOptionsState} userProfileOptions={userProfileOptions} logOut={logOut} userAvatar={userAvatar} userName={userName} userProfileOptionsState={userProfileOptionsState} userID={userID} set_userID={set_userID} setUserAvatar={setUserAvatar} setUserBalance={setUserBalance} setUserVisibility={setUserVisibility} setLoggedIn={setLoggedIn} setUserName={setUserName} set_user_items={set_user_items} link={link} setCreatedAt={setCreatedAt} setEmail={setEmail} setTradeLink={setTradeLink} getuserrefferals={getuserrefferals} fetchUserUsingPromoCode={fetchUserUsingPromoCode} addPopup={addPopup} promoCodeUsed={promoCodeUsed} tradeLink={tradeLink}/>

            <div className="container mt-5">
                <div className="h2 mt-5 selectText" style={{color:'#dcdcdc',fontWeight:'500'}}>Support <a style={{color:"#ffca4f"}}>& FAQ</a></div>

                <div className="row" style={{float:'right',width:'100%'}}>
                    <div className="h5 col-12 selectText" style={{color:'#dcdcdc',margin:'0px',fontSize:'17px'}}><a style={{float:'right',marginTop:'-42px'}}>Still need help?</a></div>
                    <div className="h6 col-12 selectText"><a className="supportPage" style={{color:'#dcdcdc',float:'right',marginLeft:'3px',textDecoration:'none',fontSize:'15px',fontWeight:'500',marginTop:'-22px'}}> support@toptrade.gg </a><a style={{color:'#7F808E',float:'right',fontSize:'15px',fontWeight:'500',marginTop:'-22px',marginRight:'145px'}}>Contact us at </a></div>
                </div>

                <div className="supportPage" style={{fontWeight:'500'}}>

                    <div className="row" style={{width:'100%',paddingTop:'35px'}}>
                        <div className="col-12 mt-3 supportHover unSelectText" onClick={() => (setWhoAreWe(!whoAreWe),setWhoAreWeChevron(whoAreWeChevron+180))} style={{position:'relative',cursor:'pointer'}}><a className="itemHeading" style={{color:(whoAreWe?'#ffca4f':'#7F808E'),fontSize:'17px',transition:'0.25s'}}><Language_wrapper string_in_slovak={'Kto sme?'} string_in_english={'About us'} string_in_russian={'Кто мы?'} string_in_german={'Wer sind wir?'} language={language} /></a><ExpandMoreIcon className="itemHeading" style={{color:(whoAreWe?'#ffca4f':'#7F808E'),fontSize:'1.4rem',position:'absolute',top:'3px',right:'3px',rotate:whoAreWeChevron+'deg',transition:'0.25s'}}/></div>
                    </div>

                    <div className="row mt-3" style={{width:'100%',transition: 'max-height 0.25s ease-in-out, opacity 0.25s ease-in-out',maxHeight: whoAreWe ? '750px' : '0',opacity: whoAreWe ? 1 : 0,overflow: 'hidden'}}>
                        <div className="col-12 col-sm-6 col-lg-4 px-3" >
                            <h5 style={{color:'#dcdcdc',fontSize:'17px'}}><Language_wrapper string_in_slovak={'O nás'} string_in_english={'Who are we?'} string_in_russian={'О нас'} string_in_german={'Über uns'} language={language} /></h5>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Sme nová obchodná platforma CS2 zo Slovenska, vytvorená malou skupinou ľudí so zameraním na slovenskú a českú scénu hry Counter-Strike 2.'} string_in_english={'We are a new Counter-Strike 2 skin trading platform from Slovakia, created by a small group of people with a focus on the Slovak and Czech Counter-Strike 2 scene.'} string_in_russian={'Мы новая платформа для торговли CS2 из Словакии, созданная небольшой группой людей с акцентом на словацкую и чешскую сцену игры Counter-Strike 2.'} string_in_german={'Wir sind eine neue CS2-Handelsplattform aus der Slowakei, erstellt von einer kleinen Gruppe von Personen mit Fokus auf die slowakische und tschechische Counter-Strike 2-Szene.'} language={language} /></p>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4 px-3" >
                            <h5 style={{color:'#dcdcdc',fontSize:'17px'}}><Language_wrapper string_in_slovak={'Ako viem, že je stránka dôveryhodná?'} string_in_english={'How do I know the website is legit?'} string_in_russian={'Как я могу быть уверен, что этот веб-сайт надежен?'} string_in_german={'Wie weiß ich, dass diese Website vertrauenswürdig ist?'} language={language} /></h5>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Snažíme sa byť čo najtransparentnejší ako vieme. Môžete si prečítať recenzie o nás '} string_in_english={'We strive to be as transparent as possible. You can read reviews about us '} string_in_russian={'Мы стараемся быть максимально прозрачными. Вы можете прочитать отзывы о нас '} string_in_german={'Wir bemühen uns, so transparent wie möglich zu sein. Sie können '} language={language} />

                            <a style={{textDecoration:'none',color:'#7F808E',textDecoration:'underline'}} href="https://www.trustpilot.com/review/toptrade.gg" target="_blank"><Language_wrapper string_in_slovak={'tu'} string_in_english={'here'} string_in_russian={'здесь'} string_in_german={'hier'} language={language} /></a>

                            <Language_wrapper string_in_slovak={'. Taktiež spolupracujeme s ľuďmi zo slovenskej a českej scény, ktorí majú v komunite pozitívnu reputáciu.'} string_in_english={'. Additionally, we collaborate with individuals from the Slovak and Czech scene who have a positive reputation in the community.'} string_in_russian={'. Кроме того, мы сотрудничаем с людьми из словацкой и чешской сцены, которые имеют положительную репутацию в сообществе.'} string_in_german={' Bewertungen über uns lesen. Zusätzlich arbeiten wir mit Personen aus der slowakischen und tschechischen Szene zusammen, die einen positiven Ruf in der Community haben.'} language={language} /></p>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4 px-3" >
                            <h5 style={{color:'#dcdcdc',fontSize:'17px'}}><Language_wrapper string_in_slovak={'Ako sa môžem s vami spojiť?'} string_in_english={'How can I get in contact with you?'} string_in_russian={'Как я могу связаться с вами?'} string_in_german={'Wie kann ich Kontakt mit Ihnen aufnehmen?'} language={language} /></h5>
                                <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Najrýchlejší spôsob ako nás kontaktovať je prostredníctvom nášho Discord servera, kde môžete napísať správu jednému z našich adminov alebo vytvoriť podporný lístok so svojou požiadavkou.'} string_in_english={'The quickest way to contact us is through our Discord server, where you can send a message to one of our admins or create a support ticket with your request.'} string_in_russian={'Самый быстрый способ связаться с нами - через наш сервер в Discord, где вы можете отправить сообщение одному из наших администраторов или создать техническую поддержку с вашим запросом.'} string_in_german={'Der schnellste Weg, um Kontakt mit uns aufzunehmen, ist über unseren Discord-Server, wo Sie eine Nachricht an einen unserer Admins senden oder ein Support-Ticket mit Ihrem Anliegen erstellen können.'} language={language} /></p>
                        </div>

                    </div>

                    <div className="row" style={{width:'100%',paddingTop:'5px'}}>
                        <div className="col-12 supportHover unSelectText" onClick={() => (setHowToTrade(!howToTrade),setHowToTradeChevron(howToTradeChevron+180))} style={{position:'relative',cursor:'pointer'}}><a className="itemHeading" style={{color:(howToTrade?'#ffca4f':'#7F808E'),fontSize:'17px',transition:'0.25s'}}><Language_wrapper string_in_slovak={'Ako používať TopTrade'} string_in_english={'How to use TopTrade.gg'} string_in_russian={'Как использовать TopTrade'} string_in_german={'Wie man TopTrade benutzt'} language={language} /></a><ExpandMoreIcon className="itemHeading" style={{color:(howToTrade?'#ffca4f':'#7F808E'),fontSize:'1.4rem',position:'absolute',top:'3px',right:'3px',rotate:howToTradeChevron+'deg',transition:'0.25s'}}/></div>
                    </div>

                    <div className="row mt-3" style={{width:'100%',transition: 'max-height 0.25s ease-in-out, opacity 0.25s ease-in-out',maxHeight: howToTrade ? '750px' : '0',opacity: howToTrade ? 1 : 0,overflow: 'hidden'}}>
                        <div className="col-12 col-sm-6 col-lg-4 px-3" >
                            <h5 style={{color:'#dcdcdc',fontSize:'17px'}}><Language_wrapper string_in_slovak={'Ako obchodovať?'} string_in_english={'How to trade?'} string_in_russian={'Как торговать?'} string_in_german={'Wie handeln?'} language={language} /></h5>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'V prvom rade sa uistite, že máte nastavený Steam Trade Link v Profile. Aby ste sa naučili obchodovať na TopTrade, pripravili sme pre vás jednoduchý návod:'} string_in_english={'First of all, make sure to have your Steam Trade Link set in your Profile Details. To learn how to trade on TopTrade, we made a simple YouTube tutorial for you:'} string_in_russian={'Чтобы узнать, как торговать на Toptrade, мы сделали простое руководство YouTube для вас'} string_in_german={'Um zu lernen, wie man auf Toptrade handelt, haben wir ein einfaches YouTube-Tutorial für Sie gemacht'} language={language} /></p>

                            <div><iframe style={{width:'35vh',height:'19.6875vh',marginTop:'-10px'}} src='https://www.youtube.com/embed/V3k039QYiUI?si=YA9jTO-TTVgw1BEP' title="YouTube Video" allow="autoplay; encrypted-media; picture-in-picture" allowFullScreen></iframe></div>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4 px-3" >
                            <h5 style={{color:'#dcdcdc',fontSize:'17px'}}><Language_wrapper string_in_slovak={'Čo je potrebné pre obchodovanie?'} string_in_english={'What is required for me to trade?'} string_in_russian={'Что мне нужно для торговли?'} string_in_german={'Was ist erforderlich, damit ich handeln kann?'} language={language} /></h5>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Ak chcete obchodovať na TopTrade, uistite sa, že máte svoj Steam Trade Link, verejný Steam účet a aktívny Steam Guard Mobile Authenticator.'} string_in_english={"To trade on TopTrade, make sure you have your Steam Trade Link, a public Steam account and an active Steam Guard Mobile Authenticator."} string_in_russian={'Чтобы торговать на TopTrade, убедитесь, что у вас есть торговый адрес учетной записи Steam, публичный аккаунт Steam и активный Steam Guard Mobile Authenticator.'} string_in_german={'Um mit TopTrade zu handeln, stellen Sie sicher, dass Sie die Trade-URL Ihres Steam-Kontos, ein öffentliches Steam -Konto und einen aktiven Steam Guard Mobile Authenticator haben.'} language={language} /></p>

                            <h5 style={{color:'#dcdcdc',fontSize:'17px'}}><Language_wrapper string_in_slovak={'Čo ak obchodujem cez referenčný odkaz?'} string_in_english={'What if I trade through refferal link?'} string_in_russian={'Что произойдет, если я буду торговать через реферальную ссылку?'} string_in_german={'Was passiert, wenn ich über die Empfehlungs-URL handele?'} language={language} /></h5>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Keď obchodujete prostredníctvom odkazu, ktorý obsahuje referenčný kód a na svojom účte používate iný promokód, kód v odkaze bude mať prioritu, ak je platný, inak sa pri obchodovaní použije ten, ktorý používate.'} string_in_english={'When you trade through link which has refferal code inside of it and you have different promocode in use on your account the code in the link will take priority if valid, otherwise the one you have in use will be used in the trade.'} string_in_russian={'Когда вы торгуете через URL-адрес, внутри которого есть реферальный код, и в вашей учетной записи используется другой промокод, код в URL-адресе будет иметь приоритет, если он действителен, в противном случае в сделке будет использоваться тот, который вы используете.'} string_in_german={'Wenn Sie über eine URL handeln, die einen Empfehlungscode enthält, und in Ihrem Konto einen anderen Promocode verwenden, hat der Code in der URL Vorrang, sofern er gültig ist. Andernfalls wird der von Ihnen verwendete Code für den Handel verwendet.'} language={language} /></p>
                        </div>

                        <div className="col-12 col-sm-12 col-lg-4 px-3" >
                            <h5 style={{color:'#dcdcdc',fontSize:'17px'}}><Language_wrapper string_in_slovak={'Môžem si kúpiť predmety?'} string_in_english={'Can I buy items?'} string_in_russian={'Могу я купить шкуры?'} string_in_german={'Kann ich Leder kaufen?'} language={language} /></h5>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Predmety je možné nakupovať na TopTrade pomocou vášho zostatku. Postupujte podľa týchto krokov:'} string_in_english={'It is possible to buy skins on TopTrade using your balance during Trading. Just follow these steps:'} string_in_russian={'Можно купить кожи на TopTrade, используя ваш баланс во время торговли. Просто следуйте этим шагам:'} string_in_german={'Es ist möglich, Skins auf TopTrade zu kaufen, indem Sie Ihr Guthaben während des Handels verwenden. Folgen Sie einfach diesen Schritten:'} language={language} />
                                <ol>
                                    <li><Language_wrapper string_in_slovak={'Prihláste sa do svojho účtu.'} string_in_english={'Log into your account.'} string_in_russian={'Войдите в свою учетную запись.'} string_in_german={'Loggen Sie sich in Ihr Konto ein.'} language={language} /></li>
                                    <li><Language_wrapper string_in_slovak={'Vložte prostriedky na váš zostatok.'} string_in_english={'Deposit funds in your balance.'} string_in_russian={'Депозитные средства в вашем остатке.'} string_in_german={'Kaution von Mitteln in Ihrem Saldo.'} language={language} /></li>
                                    <li><Language_wrapper string_in_slovak={'Kliknite na "Obchodovať" v hornom menu.'} string_in_english={'Click on "Trade" at the top menu.'} string_in_russian={'Нажмите на «Торговля» в верхнем меню.'} string_in_german={'Klicken Sie auf "Handel" im oberen Menü.'} language={language} /></li>
                                    <li><Language_wrapper string_in_slovak={'Vyberte predmety, ktoré chcete dostať.'} string_in_english={'Choose the items you want to receive.'} string_in_russian={'Выберите шкуры, которые вы хотите получить с правой стороны.'} string_in_german={'Wählen Sie die Haut, die Sie von der rechten Seite erhalten möchten.'} language={language} /></li>
                                    <li><Language_wrapper string_in_slovak={'Kliknite "Obchodovať" bez toho, aby ste vybrali niektorý z vašich predmetov.'} string_in_english={'Click "Trade items" without selecting any of your skins. The trading bot will deduct the necessary amount from your balance.'} string_in_russian={'Нажмите "Торговые товары" без выбора любой из ваших шкурок. Торговый бот вычитает необходимую сумму из вашего баланса.'} string_in_german={'Klicken Sie auf "Trading-Artikel" ohne eine Ihrer Skins auszuwählen. Der Trading-Bot wird den notwendigen Betrag von Ihrem Saldo abziehen.'} language={language} /></li>
                                    <li><Language_wrapper string_in_slovak={'Príjmite obchodnú ponuku.'} string_in_english={'Accept the Trade Offer'} string_in_russian={'Принять торговое предложение'} string_in_german={'Das Handelsangebot annehmen'} language={language} /></li>
                                </ol>
                            </p>
                        </div>
                    </div>

                    {/*tradelocked items*/}
                    <div className="row" style={{width:'100%',paddingTop:'5px'}}>
                        <div className="col-12 supportHover unSelectText" onClick={() => (setHowToTradeLocked(!howToTradeLocked),setHowToTradeLockedChevron(howToTradeLockedChevron+180))} style={{position:'relative',cursor:'pointer'}}><a className="itemHeading" style={{color:(howToTradeLocked?'#ffca4f':'#7F808E'),fontSize:'17px',transition:'0.25s'}}><Language_wrapper string_in_slovak={'Predmety s Trade Lock'} string_in_english={'Items with Trade Lock'} string_in_russian={'Предметы с торговым замком'} string_in_german={'Artikel mit Trade Lock'} language={language} /></a><ExpandMoreIcon className="itemHeading" style={{color:(howToTradeLocked?'#ffca4f':'#7F808E'),fontSize:'1.4rem',position:'absolute',top:'3px',right:'3px',rotate:howToTradeLockedChevron+'deg',transition:'0.25s'}}/></div>
                    </div>

                    <div className="row mt-3" style={{width:'100%',transition: 'max-height 0.25s ease-in-out, opacity 0.25s ease-in-out',maxHeight: howToTradeLocked ? '750px' : '0',opacity: howToTradeLocked ? 1 : 0,overflow: 'hidden'}}>

                        <div className="col-12 col-sm-6 col-lg-4 px-3">
                            <h5 style={{color:'#dcdcdc',fontSize:'17px'}}><Language_wrapper string_in_slovak={'Čo je Trade Lock?'} string_in_english={'What is Trade Lock?'} string_in_russian={'Что такое Trade Lock?'} string_in_german={'Was ist Trade Lock?'} language={language} /></h5>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Trade Lock je funkcia, ktorá obmedzuje obchodovanie s novo prijatými predmetmi tým, že ich uzamkne na účte na 7 dní po predchádzajúcom obchode. To znamená, že nové položky na TopTrade je možné preniesť do Steam inventára používateľa až po 7 dňoch."'} string_in_english={"Trade Lock is a feature restricting trading of newly received items by locking them to the account for 7 days after previous trade. This means, that new items on TopTrade can only be transferred to a user's Steam inventory after waiting up to 7 days."} string_in_russian={'Trade Lock - это функция, ограничивающая торговлю вновь полученными товарами, блокируя их на счет в течение 7 дней после предыдущей торговли. Это означает, что новые элементы на TopTrade могут быть переведены в запас Steam пользователя только после ожидания до 7 дней.'} string_in_german={'Trade Lock ist eine Funktion, die den Handel mit neu erhaltenen Artikeln einschränkt, indem sie sie für 7 Tage nach dem vorherigen Handel auf das Konto sperren. Dies bedeutet, dass neue Artikel auf TopTrade nur nach einem Warten von bis zu 7 Tagen in den Steam-Inventarium eines Benutzers übertragen werden können.'} language={language} /></p>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4 px-3">
                            <h5 style={{color:'#dcdcdc',fontSize:'17px'}}><Language_wrapper string_in_slovak={'Môžem si kúpiť Trade Locked predmety?'} string_in_english={'Can I buy Trade Locked items?'} string_in_russian={'Могу ли я купить торговые заблокированные предметы?'} string_in_german={'Kann ich geschlossene Artikel kaufen?'} language={language} /></h5>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Áno, na TopTrade je možné nakupovať Trade Locked predmety. Akonáhle si zakúpite Trade Locked predmety, budete ich mať bezpečne uložené v používateľskom batohu TopTrade (Tvoj batoh), kde je možné predmety vybrať po skončení Trade Lock'} string_in_english={"Yes, it is possible to buy Trade Locked items on TopTrade. Once you purchase trade locked items, you will have them safely stored in user's TopTrade backpack (Your Backpack), where the items can be withdrawn after Trade Lock is over."} string_in_russian={'Да, на TopTrade можно купить торгово-заблокированные товары. После того, как вы приобретете торговые блокированные товары, вы будете иметь их безопасно хранить в сумке TopTrade пользователя, где товары могут быть сняты после Trade Lock закончился.'} string_in_german={'Ja, es ist möglich, geschlossene Artikel auf TopTrade zu kaufen. Sobald Sie geschlossene Artikel kaufen, werden sie sicher im TopTrade-Rucksack des Benutzers gelagert, wo die Artikel nach dem Trade Lock zurückgezogen werden können.'} language={language} /></p>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4 px-3">
                            <h5 style={{color:'#dcdcdc',fontSize:'17px'}}><Language_wrapper string_in_slovak={'Ako si môžem vytiahnuť Trade Locked predmety?'} string_in_english={'How can I withdraw Trade Locked items?'} string_in_russian={'Как я могу отозвать торговые блокированные элементы?'} string_in_german={'Wie kann ich Trade Locked Artikel zurückziehen?'} language={language} /></h5>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Po skončení Trade Lock môžete predmety vytiahnuť kliknutím na „Vybrať“ v používateľskom batohu TopTrade (Tvoj batoh), potom vám náš robot pošle obchodnú ponuku, ktorú môžete prijať buď cez Steam alebo cez webový prehliadač.'} string_in_english={"After the Trade Lock is over, you can withdraw the items by clicking 'Withdraw item' in user's TopTrade Backpack, afterwards our bot will send you a Trade Offer, which you can accept either through Steam or web browser."} string_in_russian={'После завершения торгового блока вы можете отозвать товары, нажав на кнопку «Вывести товар» в сумке TopTrade пользователя, после чего наш бот отправит вам торговое предложение, которое вы можете принять либо через Steam, либо через веб-браузер.'} string_in_german={'Nachdem das Trade Lock vorbei ist, können Sie die Artikel zurückziehen, indem Sie in dem TopTrade-Rucksack des Benutzers auf "Withdraw Item" klicken, danach wird Ihnen unser Bot ein Trade-Angebot senden, das Sie entweder über Steam oder Web-Browser akzeptieren können.'} language={language} /></p>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4 px-3">
                            <h5 style={{color:'#dcdcdc',fontSize:'17px'}}><Language_wrapper string_in_slovak={'Koľko času mám na výber predmetov?'} string_in_english={'How much time do I have to withdraw items?'} string_in_russian={'Сколько времени мне нужно для снятия товаров?'} string_in_german={'Wie lange muss ich Artikel zurückziehen?'} language={language} /></h5>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Vaše predmety zostanú v používateľskom batohu TopTrade (Tvoj batoh) 30 dní po skončení Trade Lock. Po uplynutí tejto 30-dňovej lehoty môžu byť predmety vrátené späť do TopTrade a už sa nedajú vybrať.'} string_in_english={"Your items will stay in user's TopTrade backpack (Your Backpack) for 30 days once the Trade Lock is over. After this 30-day period, the items may be brought back to TopTrade and can no longer be withdrawn."} string_in_russian={'Ваши товары останутся в сумке TopTrade пользователя в течение 30 дней после завершения торгового блока. После этого 30-дневного периода товары могут быть возвращены TopTrade и больше не могут быть сняты.'} string_in_german={'Ihre Artikel bleiben im TopTrade-Rucksack des Benutzers für 30 Tage, sobald das Trade-Lock abgelaufen ist. Nach Ablauf dieser 30-tägigen Frist können die Artikel an TopTrade zurückgebracht werden und können nicht mehr zurückgezogen werden.'} language={language} /></p>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4 px-3">
                            <h5 style={{color:'#dcdcdc',fontSize:'17px'}}><Language_wrapper string_in_slovak={'Môžem obchodovať s mojimi Trade Locked predmetmi?'} string_in_english={'Can I trade my Trade Locked items?'} string_in_russian={'Могу ли я обмениваться моими торговыми заблокированными товарами?'} string_in_german={'Kann ich meine Trade Locked Artikel handeln?'} language={language} /></h5>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Nemôžete obchodovať s vašimi Trade Locked predmetmi. Trade Locked prednety nemožno použiť v obchodných ponukách Steam'} string_in_english={'There is no possibility for you to trade any of your Trade Locked items. Items that are Trade Locked cannot be used in Steam Trade Offers.'} string_in_russian={'Вы не можете обмениваться этими товарами. Предметы, заблокированные в торговле, не могут использоваться в торговых предложениях Steam.'} string_in_german={'Es besteht keine Möglichkeit für Sie, eine dieser Artikel zu handeln. Gegenstände, die für den Handel gesperrt sind, können nicht in den Steam-Handelsangeboten verwendet werden.'} language={language} /></p>
                        </div>
                    </div>

                    {/*balance*/}
                    <div className="row" style={{width:'100%',paddingTop:'5px'}}>
                        <div className="col-12 supportHover unSelectText" onClick={() => (setHowToBalance(!howToBalance),setHowToBalanceChevron(howToBalanceChevron+180))} style={{position:'relative',cursor:'pointer'}}><a className="itemHeading" style={{color:(howToBalance?'#ffca4f':'#7F808E'),fontSize:'17px',transition:'0.25s'}}><Language_wrapper string_in_slovak={'Zostatok'} string_in_english={'Balance'} string_in_russian={'Баланс'} string_in_german={'Gleichgewicht'} language={language} /></a><ExpandMoreIcon className="itemHeading" style={{color:(howToBalance?'#ffca4f':'#7F808E'),fontSize:'1.4rem',position:'absolute',top:'3px',right:'3px',rotate:howToBalanceChevron+'deg',transition:'0.25s'}}/></div>
                    </div>

                    <div className="row mt-3" style={{width:'100%',transition: 'max-height 0.25s ease-in-out, opacity 0.25s ease-in-out',maxHeight: howToBalance ? '750px' : '0',opacity: howToBalance ? 1 : 0,overflow: 'hidden'}}>
                        <div className="col-12 col-sm-6 col-lg-4 px-3">
                            <h5 style={{color:'#dcdcdc',fontSize:'17px'}}><Language_wrapper string_in_slovak={'Ako môžem použiť svoj zostatok?'} string_in_english={'How can I use my balance?'} string_in_russian={'Как я могу использовать свой баланс?'} string_in_german={'Wie kann ich mein Gleichgewicht nutzen?'} language={language} /></h5>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'So svojim zostatkom môžete obchodovať na TopTrade. Ak to chcete urobiť, vyberte si požadovaný predmet z TopTrade inventára a kliknite na „Obchodovať“. Ak máte na svojom zostatku dostatok prostriedkov, odpočíta sa správna suma na základe aktuálnej hodnoty predmetu.'} string_in_english={"You are able to trade here with your TopTrade balance. To do this, you choose an item you want from the trading bot and click on 'Trade items'. If you have sufficient funds in your balance, it will deduct the right amount based on the item's current value."} string_in_russian={'Вы можете торговать здесь с вашим балансом TopTrade. Для этого выберите нужный вам товар из торгового бота и нажмите «Торговать элементами». Если у вас есть достаточно средств в вашем остатке, это будет вычитать правильную сумму на основе текущей стоимости пункта.'} string_in_german={'Sie können hier mit Ihrem TopTrade-Guthaben handeln. Um dies zu tun, wählen Sie ein gewünschtes Element aus dem Trading-Bot und klicken Sie auf "Handel Artikel". Wenn Sie ausreichend Mittel in Ihrem Gleichgewicht haben, wird es den richtigen Betrag basierend auf dem aktuellen Wert des Artikels abziehen.'} language={language} /></p>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-3 px-3">
                            <h5 style={{color:'#dcdcdc',fontSize:'17px'}}><Language_wrapper string_in_slovak={'Je možné presunúť môj zostatok alebo predmety na iný účet?'} string_in_english={'Is it possible to move my balance or items to another account?'} string_in_russian={'Можно ли перевести мой остаток или пункты на другой счет?'} string_in_german={'Ist es möglich, mein Guthaben oder Gegenstände auf ein anderes Konto zu verschieben?'} language={language} /></h5>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Neexistuje žiadna možnosť, ktorá vám umožní previesť zostatok alebo predmety na iný účet.'} string_in_english={'There is no option that allows you to transfer your balance or items to another account.'} string_in_russian={'Нет никакой опции, которая позволяет вам перевести ваш остаток или элементы на другой счет.'} string_in_german={'Es gibt keine Option, mit der Sie Ihr Guthaben oder Ihre Gegenstände auf ein anderes Konto übertragen können.'} language={language} /></p>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-3 px-3">
                            <h5 style={{color:'#dcdcdc',fontSize:'17px'}}><Language_wrapper string_in_slovak={'Ako dlho môžem udržať svoj zostatok, keď je môj účet neaktívny?'} string_in_english={'For how long can I keep my balance when my account is inactive?'} string_in_russian={'На сколько времени я могу сохранить свой остаток, когда мой аккаунт неактивен?'} string_in_german={'Wie lange kann ich mein Guthaben halten, wenn mein Konto inaktiv ist?'} language={language} /></h5>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Môžete si svoj zostatok ponechať 45 dní od dátumu poslednej transakcie typu "deposit" alebo "trade". Tento dátum môžete vidieť v transakciách v časti vašeho profilu.'} string_in_english={'You can keep your balance for 45 days from your last "deposit" or "trade" transaction type date. You can see this date in transactions under your profile section.'} string_in_russian={'Вы можете сохранить свой остаток на 45 дней с даты вашей последней транзакции типа "deposit" или "trade". Вы можете увидеть эту дату в транзакциях в разделе вашего профиля.'} string_in_german={'Sie können Ihr Guthaben 45 Tage ab dem Datum Ihrer letzten "deposit" oder "trade" -Transaktion behalten. Sie können dieses Datum in Transaktionen unter Ihrem Profilbereich einsehen.'} language={language} /></p>
                        </div>
                    
                    </div>

                    {/*depositing funds*/}
                    <div className="row" style={{width:'100%',paddingTop:'5px'}}>
                        <div className="col-12 supportHover unSelectText" onClick={() => (setHowToAccountSecurity(!howToAccountSecurity),setHowToAccountSecurityChevron(howToAccountSecurityChevron+180))} style={{position:'relative',cursor:'pointer'}}><a className="itemHeading" style={{color:(howToAccountSecurity?'#ffca4f':'#7F808E'),fontSize:'17px',transition:'0.25s'}}><Language_wrapper string_in_slovak={'Vložiť prostriedky'} string_in_english={'Deposit Funds'} string_in_russian={'Депозитные фонды'} string_in_german={'Einlagensicherungen'} language={language} /></a><ExpandMoreIcon className="itemHeading" style={{color:(howToAccountSecurity?'#ffca4f':'#7F808E'),fontSize:'1.4rem',position:'absolute',top:'3px',right:'3px',rotate:howToAccountSecurityChevron+'deg',transition:'0.25s'}}/></div>
                    </div>

                    <div className="row mt-3" style={{width:'100%',transition: 'max-height 0.25s ease-in-out, opacity 0.25s ease-in-out',maxHeight: howToAccountSecurity ? '750px' : '0',opacity: howToAccountSecurity ? 1 : 0,overflow: 'hidden'}}>
                        <div className="col-12 col-sm-5 col-lg-4 px-3" >
                            <h5 style={{color:'#dcdcdc',fontSize:'17px'}}><Language_wrapper string_in_slovak={'Ako vložiť prostriedky?'} string_in_english={'How to deposit funds?'} string_in_russian={'Как вложить средства?'} string_in_german={'Wie Geld einzuzahlen?'} language={language} /></h5>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Prostriedky môžete vložiť na svoj zostatok kliknutím na tlačidlo vkladu („+“) v hornom menu, potom vyberte spôsob platby a zadajte sumu, ktorú chcete vložiť.'} string_in_english={'You can deposit funds to your balance by clicking on the deposit button ("+") in the top menu, then select a payment method and enter the amount you want to deposit.'} string_in_russian={'Вы можете внести средства в свой остаток, нажав на кнопку депозита ("+") в верхнем меню, затем выберите способ оплаты и введите сумму, которую вы хотите внести.'} string_in_german={'Sie können Geld in Ihr Guthaben einzahlen, indem Sie auf den Einzahlungsknopf ("+") im oberen Menü klicken, dann eine Zahlungsmethode auswählen und den Betrag eingeben, den Sie einzahlen möchten.'} language={language} /></p>
                        </div>

                        <div className="col-12 col-sm-7 col-lg-8 px-3" >
                            <h5 style={{color:'#dcdcdc',fontSize:'17px'}}><Language_wrapper string_in_slovak={'Platia sa nejaké poplatky za vklad prostriedkov?'} string_in_english={'Are there any fees for depositing funds?'} string_in_russian={'Существуют ли какие-либо сборы за внесение средств?'} string_in_german={'Gibt es Gebühren für die Einzahlung von Geldern?'} language={language} /></h5>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Vklad prostriedkov na váš zostatok v nie je spoplatnený spoločnosťou TopTrade. V závislosti od zvoleného spôsobu platby však môže existovať malá provízia, ktorú však bude účtovať poskytovateľ platby, nie spoločnosť TopTrade.'} string_in_english={'Depositing funds to your TopTrade balance does not incur any charges from TopTrade. However, depending on your chosen payment method, there might be a small commission, but this will be charged by the payment provider, not TopTrade.'} string_in_russian={'Депозит средств в ваш баланс TopTrade не влечет за собой каких-либо сборов от Toptrade. Однако, в зависимости от выбранного вами способа оплаты, может быть небольшая комиссия, но она будет взиматься поставщиком платежей, а не TopTrade.'} string_in_german={'Die Einzahlung von Geldern in Ihr TopTrade-Guthaben führt zu keinerlei Gebühren von Toptrade. Abhängig von Ihrer gewählten Zahlungsart kann es jedoch zu einer kleinen Provision kommen, die jedoch vom Zahlungsanbieter und nicht von TopTrade berechnet wird.'} language={language} /></p>
                        </div>

                        <div className="col-12 px-3" >
                            <h5 style={{color:'#dcdcdc',fontSize:'17px'}}><Language_wrapper string_in_slovak={'Môžem požiadať o vrátenie peňazí, ak som omylom vložil prostriedky na svoj účet?'} string_in_english={'Am I able to request a refund if I accidentally deposited funds into my account?'} string_in_russian={'Могу ли я запросить возмещение, если я случайно внесла средства на свой счет?'} string_in_german={'Kann ich eine Rückerstattung beantragen, wenn ich versehentlich Geld auf mein Konto eingezahlt habe?'} language={language} /></h5>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Ak ste nepoužili žiadne prostriedky z konkrétneho vkladu, môžete požiadať o vrátenie peňazí. Stačí nás kontaktovať na adrese support@toptrade.gg a poskytnúť ID transakcie.'} string_in_english={'If you have not used any funds from the specific deposit, you can ask for a refund. Simply contact us at support@toptrade.gg and provide the transaction ID.'} string_in_russian={'Если вы не использовали какие-либо средства из конкретного депозита, вы можете запросить возврат. Просто свяжитесь с нами по адресу support@toptrade.gg и укажите идентификатор транзакции.'} string_in_german={'Wenn Sie keine Mittel aus der spezifischen Einzahlung verwendet haben, können Sie eine Rückerstattung beantragen. Kontaktieren Sie uns einfach unter support@toptrade.gg und geben Sie die Transaktions-ID an.'} language={language} /></p>
                        </div>

                        <div className="col-12 px-3" >
                            <h5 style={{color:'#dcdcdc',fontSize:'17px'}}><Language_wrapper string_in_slovak={'Čo mám robiť, ak je transakcia zrušená, ale moje peniaze boli stále odpočítané?'} string_in_english={'What steps should I take if the transaction is canceled but my money was still deducted?'} string_in_russian={'Какие шаги следует предпринять, если транзакция отменена, но мои деньги по-прежнему вычитаются?'} string_in_german={'Welche Schritte sollte ich ergreifen, wenn die Transaktion storniert wird, aber mein Geld immer noch abgezogen wurde?'} language={language} /></h5>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'V takýchto prípadoch buďte trpezliví a dajte nášmu systému nejaký čas na vloženie peňazí na váš zostatok. Ak však peniaze nie sú späť na vašom zostatku po niekoľkých hodinách, nezabudnite nás kontaktovať na adrese support@toptrade.gg.'} string_in_english={'In those cases, just stay patient and give our system some time to deposit the money to your balance. However, if the money is not back in your balance after a few hours, make sure to reach us at support@toptrade.gg.'} string_in_russian={'В этих случаях, просто оставайтесь терпеливым и дайте нашей системе некоторое время, чтобы положить деньги в ваш баланс. Однако, если деньги не вернулись в ваш баланс через несколько часов, пожалуйста, свяжитесь с нами по адресу support@toptrade.gg'} string_in_german={'In diesen Fällen bleiben Sie geduldig und geben Sie unserem System etwas Zeit, um das Geld in Ihr Gleichgewicht einzuzahlen. Wenn das Geld jedoch nach ein paar Stunden nicht wieder in Ihrem Saldo ist, kontaktieren Sie uns bitte unter support@toptrade.gg'} language={language} /></p>
                        </div>  
                    </div>

                    {/*item status*/}
                    <div className="row" style={{width:'100%',paddingTop:'5px'}}>
                        <div className="col-12 supportHover unSelectText" onClick={() => (setHowToItemStatus(!howToItemStatus),setHowToItemStatusChevron(howToItemStatusChevron+180))} style={{position:'relative',cursor:'pointer'}}><a className="itemHeading" style={{color:(howToItemStatus?'#ffca4f':'#7F808E'),fontSize:'17px',transition:'0.25s'}}><Language_wrapper string_in_slovak={'Status Predmetov'} string_in_english={'Item Status'} string_in_russian={'Item Status'} string_in_german={'Item Status'} language={language} /></a><ExpandMoreIcon className="itemHeading" style={{color:(howToItemStatus?'#ffca4f':'#7F808E'),fontSize:'1.4rem',position:'absolute',top:'3px',right:'3px',rotate:howToItemStatusChevron+'deg',transition:'0.25s'}}/></div>
                    </div>

                    <div className="row mt-3" style={{width:'100%',transition: 'max-height 0.25s ease-in-out, opacity 0.25s ease-in-out',maxHeight: howToItemStatus ? '750px' : '0',opacity: howToItemStatus ? 1 : 0,overflow: 'hidden'}}>
                        <div className="col-12 px-3" style={{display:(howToItemStatus?'block':'none')}}>
                            <h5 style={{color:'#dcdcdc',fontSize:'17px'}}><Language_wrapper string_in_slovak={'Prečo nemôžem obchodovať s niektorými svojimi predmetmi?'} string_in_english={'Why can not I trade some of my items?'} string_in_russian={'Почему я не могу обменять некоторые из своих предметов?'} string_in_german={'Warum kann ich einige meiner Artikel nicht handeln?'} language={language} /></h5>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Niekedy sa môže stať, že dočasne nebudete môcť obchodovať s určitými predmetmi na TopTrade. Súvisí to so stavom položiek a ide o dočasnú situáciu.'} string_in_english={'Sometimes you might temporarily be unable to trade certain items on TopTrade. This is related to the status of the items and is a temporary situation.'} string_in_russian={'Иногда вы можете временно не иметь возможности торговать определенными предметами на TopTrade. Это связано со статусом предметов и является временной ситуацией.'} string_in_german={'Manchmal können Sie bestimmte Artikel vorübergehend nicht auf TopTrade handeln. Dies hängt mit dem Status der Artikel zusammen und ist eine vorübergehende Situation.'} language={language} />
                                <ul>
                                    <li><strong>Not Accepted</strong> <Language_wrapper string_in_slovak={'položky nemožno obchodovať z dôvodov, ako je nízka likvidita, nestabilná cena alebo hodnota pod 0,50 USD'} string_in_english={'items can not be traded due to reasons such as low liquidity, unstable price or being valued under $0.50'} string_in_russian={'предметы не могут быть проданы по таким причинам, как низкая ликвидность, нестабильная цена или стоимость менее 0,50 доллара США.'} string_in_german={'Artikel können aus Gründen wie geringer Liquidität, instabilem Preis oder einem Wert unter 0,50 USD nicht gehandelt werden'} language={language} /></li>
                                    <li><strong>Trade Locked</strong> <Language_wrapper string_in_slovak={'predmety sú dočasne uzamknuté. Tieto predmety sa stanú obchodovateľnými po uplynutí Trade Lock'} string_in_english={'items are usually temporary locked. These items become tradable after the Trade Lock expires'} string_in_russian={'элементы обычно временно заблокированы. Эти предметы станут доступными для торговли после истечения срока действия торговой блокировки.'} string_in_german={'Gegenstände sind in der Regel vorübergehend gesperrt. Diese Gegenstände werden nach Ablauf der Handelssperre handelbar'} language={language} /></li>
                                </ul>
                            </p>
                        </div>

                    </div>

                    {/*problems with trading*/}
                    <div className="row" style={{width:'100%',paddingTop:'5px'}}>
                        <div className="col-12 supportHover unSelectText" onClick={() => (setHowToProblemsWithTrading(!howToProblemsWithTrading),setHowToProblemsWithTradingChevron(howToProblemsWithTradingChevron+180))} style={{position:'relative',cursor:'pointer'}}><a className="itemHeading" style={{color:(howToProblemsWithTrading?'#ffca4f':'#7F808E'),fontSize:'17px',transition:'0.25s'}}><Language_wrapper string_in_slovak={'Problémy s obchodovaním'} string_in_english={'Problems with Trading'} string_in_russian={'Проблемы с торговлей'} string_in_german={'Probleme mit dem Handel'} language={language} /></a><ExpandMoreIcon className="itemHeading" style={{color:(howToProblemsWithTrading?'#ffca4f':'#7F808E'),fontSize:'1.4rem',position:'absolute',top:'3px',right:'3px',rotate:howToProblemsWithTradingChevron+'deg',transition:'0.25s'}}/></div>
                    </div>

                    <div className="row mt-3" style={{width:'100%',transition: 'max-height 0.25s ease-in-out, opacity 0.25s ease-in-out',maxHeight: howToProblemsWithTrading ? '750px' : '0',opacity: howToProblemsWithTrading ? 1 : 0,overflow: 'hidden'}}>
                        <div className="col-12 col-sm-6 col-lg-8 px-3" >
                            <h5 style={{color:'#dcdcdc',fontSize:'17px'}}><Language_wrapper string_in_slovak={'Čo mám robiť, ak dostanem iba niektoré predmety a zvyšné obchody budú odmietnuté?'} string_in_english={'What should I do if I only receive some of the items and the rest of the trades are declined?'} string_in_russian={'Что мне делать, если я получаю только часть товаров, а остальные сделки отклонены?'} string_in_german={'Was soll ich tun, wenn ich nur einige der Artikel bekomme und der Rest der Trades abgelehnt wird?'} language={language} /></h5>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Ak ste nedostali všetky svoje obchodované predmety, môže to byť spôsobené technickým problémom, ktorý sa vyskytol počas obchodu. Ak k tomu dôjde, kontaktujte nás na adrese support@toptrade.gg'} string_in_english={"If you didn't get all your traded items, it could be due to a technical problem that happened during the trade. If this occurs, please contact us at support@toptrade.gg."} string_in_russian={'Если вы не получили все свои торговые товары, это может быть из-за технической проблемы во время торговли. Если это произошло, пожалуйста, свяжитесь с нами по адресу support@toptrade.gg.'} string_in_german={'Wenn Sie nicht alle gehandelten Artikel erhalten haben, könnte dies auf ein technisches Problem während des Handels zurückzuführen sein. Wenn dies geschieht, kontaktieren Sie uns bitte unter support@toptrade.gg'} language={language} /></p>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4 px-3" >
                            <h5 style={{color:'#dcdcdc',fontSize:'17px'}}><Language_wrapper string_in_slovak={'Môžem odmietnuť obchod?'} string_in_english={'Can I decline a trade'} string_in_russian={'Могу ли я отказаться от сделки?'} string_in_german={'Kann ich einen Handel ablehnen?'} language={language} />?</h5>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Máte povolené odmietnuť obchod. Ak chcete odmietnuť obchod zo služby TopTrade, môžete tak urobiť otvorením odoslanej obchodnej ponuky v službe Steam a odtiaľ ponuku odmietnuť.'} string_in_english={'You are allowed to decline a trade. If you want to decline trade from TopTrade you can do so by opening the sent Trade Offer on Steam and decline the offer from there.'} string_in_russian={'Да, вы можете, если вы хотите отказаться от торговли с TopTrade вы можете сделать это, открыв отправленное предложение торговли и отклонить предложение оттуда.'} string_in_german={'Ja, Sie können, wenn Sie den Handel von TopTrade ablehnen möchten, können Sie dies tun, indem Sie das gesendete Angebot öffnen und das Angebot abweisen.'} language={language} /></p>
                        </div>

                    </div>

                    {/*item prices*/}
                    <div className="row" style={{width:'100%',paddingTop:'5px'}}>
                        <div className="col-12 supportHover unSelectText" onClick={() => (setHowToItemPrices(!howToItemPrices),setHowToItemPricesChevron(howToItemPricesChevron+180))} style={{position:'relative',cursor:'pointer'}}><a className="itemHeading" style={{color:(howToItemPrices?'#ffca4f':'#7F808E'),fontSize:'17px',transition:'0.25s'}}><Language_wrapper string_in_slovak={"Ceny predmetov"} string_in_english={'Pricing of Items'} string_in_russian={'Цены на товары'} string_in_german={'Preise der Artikel'} language={language} /></a><ExpandMoreIcon className="itemHeading" style={{color:(howToItemPrices?'#ffca4f':'#7F808E'),fontSize:'1.4rem',position:'absolute',top:'3px',right:'3px',rotate:howToItemPricesChevron+'deg',transition:'0.25s'}}/></div>
                    </div>

                    <div className="row mt-3" style={{width:'100%',transition: 'max-height 0.25s ease-in-out, opacity 0.25s ease-in-out',maxHeight: howToItemPrices ? '750px' : '0',opacity: howToItemPrices ? 1 : 0,overflow: 'hidden'}}>
                        <div className="col-12 col-sm-6 col-lg-8 px-3" >
                            <h5 style={{color:'#dcdcdc',fontSize:'17px'}}><Language_wrapper string_in_slovak={'Prečo sú ceny mojich predmetov odlišné od cien predmetov na Steam Market?'} string_in_english={'Why are my items priced different than the item prices on the Steam Market?'} string_in_russian={'Почему мои товары ценятся ниже, чем товары на рынке Steam?'} string_in_german={'Warum sind meine Artikel günstiger als die auf dem Steam-Markt?'} language={language} /></h5>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Ceny našich predmetov sa určujú automaticky s ohľadom na rôzne faktory. Tieto ceny nie sú fixné a môžu sa meniť, ak sa zmení niektorý z ovplyvňujúcich faktorov.'} string_in_english={'Our item prices are determined automatically, considering various factors. These prices are not fixed and may fluctuate if any of the influencing factors change.'} string_in_russian={'Цены на наши товары определяются автоматически с учетом различных факторов. Эти цены не фиксируются и могут колебаться в случае изменения любого из влияющих факторов.'} string_in_german={'Unsere Artikelpreise werden unter Berücksichtigung verschiedener Faktoren automatisch bestimmt. Diese Preise sind nicht festgelegt und können fluktuieren, wenn sich einer der Einflussfaktoren ändert.'} language={language} /></p>
                        </div>

                        <div className="col-12 col-sm-6 col-lg-4 px-3" >
                            <h5 style={{color:'#dcdcdc',fontSize:'17px'}}><Language_wrapper string_in_slovak={'Existujú nejaké preplatky?'} string_in_english={'Are there any overpays?'} string_in_russian={'Есть ли переплаты?'} string_in_german={'Gibt es Überzahlungen?'} language={language} /></h5>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Neexistujú žiadne preplatky za nálepky alebo float na používateľskom predmete.'} string_in_english={"There are no overpays for stickers or float on any user's item."} string_in_russian={'Для наклейки или плавающего элемента пользователя нет перекрытий.'} string_in_german={'Es gibt keine Overpays für Aufkleber oder schwimmen auf einem Benutzer-Elemente.'} language={language} /></p>
                        </div>

                    </div>
                </div>
                
            {/* footer */}
            <div>
            <div className="row" style={{paddingTop:'25px',marginTop:'410px'}}>
                <div className="col-md-8 col-sm-12 col-lg-7">
                <div style={{backgroundImage:'#393646',height:'300px',width:'100%'}}>
                    <div className="row">
                    <div className="col">
                    <div className='navbarBrand' style={{paddingTop:'5px',paddingLeft:'10px'}}><img className="navbarBrandImage" src={require('../images/toptradegg.png')} style={{filter: 'drop-shadow(4px 4px 4px transparent)',border:'none'}}/></div>
                    </div>
                    </div>
                    <div className="row" style={{paddingTop:'35px',just:'center',fontWeight:'500'}}>
                    <div className="col-3" style={{marginLeft:'10px',marginRight:'10px',width:'20%'}}>
                        <h5 className='defaultCursor' style={{color:'#dcdcdc',fontWeight:'0',fontSize:'17px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'O nás'} string_in_english={"About"} string_in_russian={'О нас'} string_in_german={'Über'} language={language}/></h5>
                        {loggedIn?<Link to='/backpack' style={{color:'#7F808E',textDecoration:'none',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Tvoj batoh'} string_in_english={"Your Backpack"} string_in_russian={'Рюкзак'} string_in_german={'Der Rucksack'} language={language}/><br></br></Link>:null}
                        <Link to='/help' style={{color:'#7F808E',textDecoration:'none',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Podpora'} string_in_english={"Support"} string_in_russian={'Помощь '} string_in_german={'Hilfe'} language={language}/></Link><br></br>
                        {loggedIn?<a onClick={addBalance} style={{color:'#7F808E',textDecoration:'none',cursor:'pointer',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={"Vklad"} string_in_english={"Deposit"} string_in_russian={'Депозит'} string_in_german={'Depot'} language={language}/><br></br></a>:null}
                    </div>
                    {loggedIn?
                    <div className="col-3" style={{marginLeft:'10px',marginRight:'10px'}}>
                    <h5 className='defaultCursor' style={{color:'#dcdcdc',fontWeight:'0',fontSize:'17px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Profil'} string_in_english={"Profile"} string_in_russian={'Профиль'} string_in_german={'Das Profile'} language={language}/></h5>
                    <Link to='/profile' style={{color:'#7F808E',textDecoration:'none',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Profil'} string_in_english={"Profile Details"} string_in_russian={'Личная зона'} string_in_german={'Persönlicher Bereich'} language={language}/></Link><br></br>
                    <Link to='/transactions' style={{color:'#7F808E',textDecoration:'none',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Transakcie'} string_in_english={"Transaction History"} string_in_russian={'История торговли'} string_in_german={'Handelsgeschichte'} language={language}/></Link><br></br>
                    <Link to='/affiliate' style={{color:'#7F808E',textDecoration:'none',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Partnerský program'} string_in_english={"Affiliate Program"} string_in_russian={'Партнерский бонус'} string_in_german={'Partnerbonus'} language={language}/></Link><br></br>
                    </div>
                    :null}
                    <div className="col-3" style={{marginLeft:'10px',marginRight:'10px'}}>
                        <h5 className='defaultCursor' style={{color:'#dcdcdc',fontWeight:'0',fontSize:'17px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Podmienky'} string_in_english={"Terms"} string_in_russian={'Термины'} string_in_german={'Begriffe'} language={language}/></h5>
                        <Link to='/terms-of-use' style={{color:'#7F808E',textDecoration:'none',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Podmienky používania'} string_in_english={"Terms of Use"} string_in_russian={'Термин использования'} string_in_german={'Begriff der Verwendung'} language={language}/></Link><br></br>
                        <Link to='/refund-policy' style={{color:'#7F808E',textDecoration:'none',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Zásady vrátenia peňazí'} string_in_english={"Refund Policy"} string_in_russian={'Политика возврата'} string_in_german={'Rückerstattungspolitik'} language={language}/></Link><br></br>     
                        <Link to='/privacy-policy' style={{color:'#7F808E',textDecoration:'none',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Zásady ochrany osobných údajov'} string_in_english={"Privacy Policy"} string_in_russian={'Политика конфиденциальности'} string_in_german={'Datenschutzpolitik'} language={language}/></Link><br></br>                          
                    </div>
                    </div>
                </div>
                </div>
                <div className="col-md-4 col-sm-12 col-lg-5 mb-3">
                <center>
                        <div id='socials'>
                        <a style={{paddingLeft:'15px',paddingRight:'15px'}} href='https://steamcommunity.com/groups/toptradegg' target="_blank"><svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="#dcdcdc" className="bi bi-steam mt-3" viewBox="0 0 16 16"> <path d="M.329 10.333A8.01 8.01 0 0 0 7.99 16C12.414 16 16 12.418 16 8s-3.586-8-8.009-8A8.006 8.006 0 0 0 0 7.468l.003.006 4.304 1.769A2.198 2.198 0 0 1 5.62 8.88l1.96-2.844-.001-.04a3.046 3.046 0 0 1 3.042-3.043 3.046 3.046 0 0 1 3.042 3.043 3.047 3.047 0 0 1-3.111 3.044l-2.804 2a2.223 2.223 0 0 1-3.075 2.11 2.217 2.217 0 0 1-1.312-1.568L.33 10.333Z"/> <path d="M4.868 12.683a1.715 1.715 0 0 0 1.318-3.165 1.705 1.705 0 0 0-1.263-.02l1.023.424a1.261 1.261 0 1 1-.97 2.33l-.99-.41a1.7 1.7 0 0 0 .882.84Zm3.726-6.687a2.03 2.03 0 0 0 2.027 2.029 2.03 2.03 0 0 0 2.027-2.029 2.03 2.03 0 0 0-2.027-2.027 2.03 2.03 0 0 0-2.027 2.027Zm2.03-1.527a1.524 1.524 0 1 1-.002 3.048 1.524 1.524 0 0 1 .002-3.048Z"/> </svg></a>
                        <a href='https://www.instagram.com/toptradegg/' target="_blank" style={{paddingLeft:'15px',paddingRight:'15px'}}><svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="#dcdcdc" className="bi bi-instagram mt-3" viewBox="0 0 16 16"> <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/></svg></a>
                        <a href='https://twitter.com/toptradegg' target='_blank' style={{paddingLeft:'15px',paddingRight:'15px'}}><svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="#dcdcdc" className="bi bi-twitter mt-3" viewBox="0 0 16 16"> <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/> </svg></a>
                        <a href='https://www.youtube.com/channel/UCcy2sAUdAkke2V9JJukNqgg' target="_blank" style={{paddingLeft:'15px',paddingRight:'15px'}}><svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="#dcdcdc" className="bi bi-youtube mt-3" viewBox="0 0 16 16"> <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z"/> </svg></a>
                        <a href='https://discord.com/invite/jBXDUfS5wn' target='_blank' style={{paddingLeft:'15px',paddingRight:'15px'}}><svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="#dcdcdc" className="bi bi-discord mt-3" viewBox="0 0 16 16"> <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"/> </svg></a>
                        <a href='https://www.tiktok.com/@toptradegg' target='_blank' style={{paddingLeft:'15px',paddingRight:'15px',position:'relative',top:'6px'}}><svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="#dcdcdc" class="bi bi-tiktok" viewBox="0 0 16 16"> <path d="M9 0h1.98c.144.715.54 1.617 1.235 2.512C12.895 3.389 13.797 4 15 4v2c-1.753 0-3.07-.814-4-1.829V11a5 5 0 1 1-5-5v2a3 3 0 1 0 3 3V0Z" fill="#dcdcdc"></path> </svg></a>
                        </div>
                    </center>
                </div>
            </div>
            <div className="col-12">
          <p className='selectCursor selectText' style={{color:'#7F808E',fontSize:'small',margin:'0px',fontWeight:'500'}}>Powered by Steam™, TopTrade.gg is not affiliated with or endorsed by Valve Corporation. Payments may be provided by external companies.<a className='supportPage' style={{textDecoration:'none',color:'#7F808E'}}><></></a></p> 
          <p className='selectCursor selectText' style={{color:'#7F808E',fontSize:'small',fontWeight:'500'}}>© TopTrade.gg 2023-2024, All rights reserved.</p>
            </div>
            </div>
            </div>

            
        </div>
    )
} 