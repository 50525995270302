import React from "react";
import '../Css/Main_app.css';

export default function Steam_login_button({link,setUserBalance,userID,setUserVisibility,set_userID,setUserName,setUserAvatar,setLoggedIn,loggedIn,set_user_items,setCreatedAt, setEmail, setTradeLink,getuserrefferals,addPopup,logOut}){

    const [userFound,setUserFound] = React.useState(false)

    async function FetchPlayerProfile(){
        const url = window.location.href
        if (url.includes('user_id')){
            try {
                if (url.split('=')[1] === 'undefined'){
                    return
                } 
                const queryParams = new URLSearchParams(window.location.search);
                const ID = queryParams.get('user_id');
                const auth_session_token = queryParams.get('auth_token');
                localStorage.setItem('auth_session_token',auth_session_token)
                localStorage.setItem('user_id',ID) // change this to add userid to sessionStorage
                set_userID(ID)
                const response = await fetch(`${link}/api/getplayerprofile`,{method:'POST',headers:{'Content-Type':'application/json'},body:JSON.stringify({ID,auth_session_token})})
                const data = await response.json()
                setUserName(data[0])
                setUserAvatar(data[1])
                setUserVisibility(parseInt(data[2]))
                setUserBalance(parseFloat(data[3]))
                setCreatedAt(data[4])
                setEmail(data[5])
                setTradeLink(data[6])
                setLoggedIn(true)

                if (parseInt(data[2]) !== 3){
                    addPopup('Please set your account to public')
                    logOut()
                }
                /* set localstorage */
                localStorage.setItem('user_name',data[0])
                localStorage.setItem('user_avatar',data[1])
                localStorage.setItem('user_visibility',data[2])
                localStorage.setItem('user_created_at',data[4])

                const queryParam = new URLSearchParams(window.location.search);
                const refferal = queryParam.get('r');

                /* remove the user_id from the url */
                if (refferal !== null && refferal !== 'None'){
                    var obj = {Title: 'Redirect',Url: '/?r='+refferal};
                    window.history.replaceState(obj, obj.Title, obj.Url);
                } else {
                    var obj = {Title: 'Redirect',Url: '/'};
                    window.history.replaceState(obj, obj.Title, obj.Url);
                }
                
                if (false){
                    /* return the array to display from user-specific database */
                } else {
                    const responseFromInventory = await fetch(`${link}/api/getplayeritems`,{method:'POST',headers:{'Content-Type':'application/json'},body:JSON.stringify({ID,auth_session_token})})
                    const inventoryData = await responseFromInventory.json()
                    set_user_items(inventoryData)
                }
            } catch (error) {
                console.log(error)
            }
        }
    }

    const [changeVisibility,setChangeVisibility] = React.useState('block')

    React.useEffect(()=>{
        let url = window.location.href
        if (url.includes('user_id') && url.includes('auth_token')){
            FetchPlayerProfile()
            setChangeVisibility('none')
        }
    },[userFound])

    async function Redirect() { 
        try {
            const auth_token = localStorage.getItem('auth_session_token')
            const queryParams = new URLSearchParams(window.location.search);
            const refferal = queryParams.get('r');
            const response = await fetch(`${link}/api/auth`,{method:'POST',headers:{'Content-Type':'application/json'},body:JSON.stringify({auth_token,refferal})})
            const url  = await response.text()
            window.location.href = url
            setUserFound(true)
        } catch (error) {
            console.log(error)
        }
    }

    function log_in_out(){
        if (loggedIn){
            setLoggedIn(false)
        } else {
            Redirect()
        }
    }

    return(
        <a style={{textDecoration:'none',display:changeVisibility}} onClick={log_in_out}>
            <div className="steambutton">
                <center>
                    <a className="steam-icon" style={{textDecoration:'none',color:'black'}}><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-steam" viewBox="0 0 16 16"> <path d="M.329 10.333A8.01 8.01 0 0 0 7.99 16C12.414 16 16 12.418 16 8s-3.586-8-8.009-8A8.006 8.006 0 0 0 0 7.468l.003.006 4.304 1.769A2.198 2.198 0 0 1 5.62 8.88l1.96-2.844-.001-.04a3.046 3.046 0 0 1 3.042-3.043 3.046 3.046 0 0 1 3.042 3.043 3.047 3.047 0 0 1-3.111 3.044l-2.804 2a2.223 2.223 0 0 1-3.075 2.11 2.217 2.217 0 0 1-1.312-1.568L.33 10.333Z"/> <path d="M4.868 12.683a1.715 1.715 0 0 0 1.318-3.165 1.705 1.705 0 0 0-1.263-.02l1.023.424a1.261 1.261 0 1 1-.97 2.33l-.99-.41a1.7 1.7 0 0 0 .882.84Zm3.726-6.687a2.03 2.03 0 0 0 2.027 2.029 2.03 2.03 0 0 0 2.027-2.029 2.03 2.03 0 0 0-2.027-2.027 2.03 2.03 0 0 0-2.027 2.027Zm2.03-1.527a1.524 1.524 0 1 1-.002 3.048 1.524 1.524 0 0 1 .002-3.048Z"/> </svg></a>
                    <a className="sign-in" style={{textDecoration:'none',color:'black'}}>Sign in with Steam</a>
                </center>
            </div>
        </a>
    );
}