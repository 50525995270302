import React from "react";
import Menu from "../Components/Menu";
import Language_wrapper from "../Components/Language_wrapper";
import { useEffect, useState } from "react";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CustomAlert from "../Components/CustomAlert";

export default function Privacy_policy({language,changeLanguage,userAvatar,setUserAvatar,setUserVisibility,currency,setCurrency,loggedIn,setLoggedIn,addBalance,userProfileOptionsState,setUserProfileOptionsState,userProfileOptions,logOut,link,userBalance,setUserBalance,userID,set_userID,userName,setUserName,set_user_items,setCreatedAt,setEmail,setTradeLink,getuserrefferals,overlay,setOverlay,addPopup,fetchUserUsingPromoCode,promoCodeUsed,tradeLink}) {

    const [click_to_top, set_click_to_top] = useState(false); // State for the "Click to top" button

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: Add smooth scrolling animation
          }); // Scroll to the top of the page on component mount
    }, [click_to_top]);


    return(
        <div>
            <CustomAlert isOpen={overlay} onClose={() =>  setOverlay(false)} addBalance={addBalance} setOverlay={setOverlay} link={link} addPopup={addPopup} fetchUserUsingPromoCode={fetchUserUsingPromoCode} promoCodeUsed={promoCodeUsed} logOut={logOut}/>

            <Menu language={language} changeLanguage={changeLanguage} currency={currency} setCurrency={setCurrency} loggedIn={loggedIn} userBalance={userBalance} addBalance={addBalance} setUserProfileOptionsState={setUserProfileOptionsState} userProfileOptions={userProfileOptions} logOut={logOut} userAvatar={userAvatar} userName={userName} userProfileOptionsState={userProfileOptionsState} userID={userID} set_userID={set_userID} setUserAvatar={setUserAvatar} setUserBalance={setUserBalance} setUserVisibility={setUserVisibility} setLoggedIn={setLoggedIn} setUserName={setUserName} set_user_items={set_user_items} link={link} setCreatedAt={setCreatedAt} setEmail={setEmail} setTradeLink={setTradeLink} getuserrefferals={getuserrefferals} fetchUserUsingPromoCode={fetchUserUsingPromoCode} addPopup={addPopup} promoCodeUsed={promoCodeUsed} tradeLink={tradeLink}/>

            <div className="mt-5 container selectText" style={{padding:'25px',fontWeight:'500'}}>
            
                <h2 className="mt-3" style={{color:'#dcdcdc',fontSize:'17px'}}>
                Policy
                </h2>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                You consent to these Terms of Use and Refund Policy, which together form the legally enforceable agreement between you and TopTrade.gg, by opening a TopTrade.gg account and utilizing TopTrade.gg services.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                To enter into the agreement, you must be of legal age.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                By using the service, you certify that you are of legal age and qualified to enter into the agreement on your own in accordance with the laws of your local jurisdiction.
                </p>

                <h2 className="mt-5" style={{color:'#dcdcdc',fontSize:'17px'}}>
                Privacy
                </h2>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                We respect your right to privacy. Our Privacy Policy explains how your personal information is gathered, utilized, and shared while you use the service. Read them carefully, please.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                You attest that you have read and comprehended the Privacy Policy by using the service and accessing the platform.
                </p>

                <h2 className="mt-5" style={{color:'#dcdcdc',fontSize:'17px'}}>
                Account registration
                </h2>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                You must meet the following requirements in order to sign up for a TopTrade.gg user account and use the service: have a valid Steam account, be legal age;
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                After signing into the TopTrade.gg platform using your Steam account you read and agree these Terms of Use and Refund Policy; and be familiar with the Privacy Policy.
                </p>


                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                Your individual TopTrade.gg account will be automatically created when you log in, and your unique TopTrade.gg ID will be given to you.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                Free registration is offered.
                </p>

                <h2 className="mt-5" style={{color:'#dcdcdc',fontSize:'17px'}}>
                Your responsibility
                </h2>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                Your TopTrade.gg account, your Steam account, and all actions taken under these accounts are only your responsibility. This includes monitoring and protecting these accounts' login information, keeping them private, and limiting who has access to them.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                You are in charge of making sure that any use of the service under your account complies with these Terms of Use and any relevant laws or rules.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                You consent to keeping your account private and only using it for personal purposes.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                Due to your inability to adhere to the aforementioned standards, we are not responsible for any loss or harm that results.
                </p>


                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                Blocking of accounts. Your account could be blocked by us for the reasons listed in our policies.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                Cancellation of accounts. You can submit us a request to delete your Account by emailing us at support@toptrade.gg
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                This agreement will end when your Account is deleted, and you won't be able to use the Service again until you register again.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                We will block your account if there are any issues about your transactions, however we will keep it blocked for 60 days following the final purchase.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                Please get in touch with our customer care if you decide to change your mind about deleting your account while the account is blocked.
                </p>

                <h2 className="mt-5" style={{color:'#dcdcdc',fontSize:'17px'}}>
                What TopTrade.gg offers
                </h2>


                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                TopTrade.gg has no ties to any online gambling site.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                TopTrade.gg does not function as a currency exchange and does not offer any crypto-fiat, fiat-crypto, or other kinds of money exchange services.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                Through the platform offered by TopTrade.gg, users can voluntarily exchange digital items used in the Counter-Strike 2 game.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                Skin is a virtual in-game item that users can access inside the game. Its texture in-game item.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                {/* This line seems to have been left blank. */}
                </p>

                <h2 className="mt-5" style={{color:'#dcdcdc',fontSize:'17px'}}>
                Free to use
                </h2>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                The platform is a piece of software.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                We therefore give you a non-exclusive, global license to use the platform, its features, and the services only in accordance with the limitations and conditions outlined in these Terms of use.
                </p>


                <h2 className="mt-5" style={{color:'#dcdcdc',fontSize:'17px'}}>
                Must haves for all users
                </h2>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                Eligible for utilizing the platform.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                Possess Platform authorization and accept all terms and rules;
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                Have an active Steam Guard, keep your Steam inventory open,  and have a working trade connection.
                </p>

                <h2 className="mt-5" style={{color:'#dcdcdc',fontSize:'17px'}}>
                It is prohibited to
                </h2>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                Use programs or other devices to circumvent laws and conceal your identity and whereabouts; Use someone else's identification documents and payment methods;
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                Take activities that could damage the platform's and the Service's functionality, security, fairness, and integrity;
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                Use the platform's flaws to your advantage by monopolizing a market or in any other way manipulating pricing using several accounts.
                </p>

                <h2 className="mt-5" style={{color:'#dcdcdc',fontSize:'17px'}}>
                You acknowledge that
                </h2>


                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                You have the necessary and appropriate expertise and expertise to trade, sell, and/or buy virtual items, as well as the experience and/or knowledge to deal with them. You accept full responsibility for any decisions made in relation to such items within the platform.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                You fully comprehend the nature and framework of virtual items.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                You are also aware of all the benefits, risks, and any restrictions associated with virtual items, including their purchase and use.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                You acknowledge and agree that all skin transfers are final and exempt from returns or compensation; that you are the legal owner of all virtual goods linked to your Steam ID; and that you are solely responsible for reading and comprehending all terms and conditions of any purchase, sale, or trade made on the platform.
                </p>

                <h2 className="mt-5" style={{color:'#dcdcdc',fontSize:'17px'}}>
                Trade
                </h2>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                You can trade using TopTrade.gg bots.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                Only using your TopTrade.gg balance can you purchase a skin.
                </p>


                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                If the virtual item you traded cost more than the virtual item you got in exchange, the difference would be saved on your TopTrade.gg balance.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                There is no assurance, though, that any skins will be offered at that price.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                You can use your TopTrade.gg balance to make up the difference if the virtual item you want to exchange is worth less than what you want to buy.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                In-platform money known as TopTrade.gg balance is used to conduct transactions in buying skins from TopTrade.gg bots.
                </p>

                <h2 className="mt-5" style={{color:'#dcdcdc',fontSize:'17px'}}>
                TopTrade.gg balance
                </h2>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                TopTrade.gg balance is a digital good that is neither fiat nor a cryptocurrency; it has no value outside of the platform; it can only be used in buying skins from TopTrade.gg bots; it cannot be transferred to another platform or account; and it does not earn interest.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                You receive a bonus from TopTrade.gg for buying your TopTrade.gg balance.
                </p>

                <h2 className="mt-5" style={{color:'#dcdcdc',fontSize:'17px'}}>
                Commissions
                </h2>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                For each unique exchange and/or purchase of a virtual good, TopTrade.gg commission is determined separately and ranges from 1% to 9% of the declared value of the relevant virtual good.
                </p>

                <h2 className="mt-5" style={{color:'#dcdcdc',fontSize:'17px'}}>
                Financial policy
                </h2>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                You must provide us with one or more Payment Methods in order to purchase TopTrade.gg balance.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                Payment Method refers to a current, legitimate, and accepted payment method that may occasionally be updated. Any of the platform's payment options are acceptable.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                Users can get Payment Methods from TopTrade.gg at its own option. On the website's payment page, a list of the accepted payment methods is provided.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                Payment methods have their own terms and regulations, such as AML/KYC policies, and they have the right to accept or reject any transaction in accordance with those regulations.
                </p>


                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                Prior to completing any transaction, the User should review and ensure that they understand all applicable terms and policies of the relevant Payment Method.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                TopTrade.gg disclaims liability for any payment delays and/or actions taken by the relevant payment service provider.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                These Terms and Conditions are separate from the terms and conditions between the Payment Method and the Users who use the payment services, which are governed by separate agreements.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                Your payment will be converted into the chosen currency at the time of the payment if the currency of your payment account differs from the nominated currency.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                Your bank's conversion rate will be applied. The conversion rates decided upon by payment institutions are not under the control of TopTrade.gg.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                You certify that all bank cards and other payment accounts you use to make payments are legally yours.
                </p>


                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                When we take action against a user supposed in violation of these terms of service or other applicable terms, policies, and regulations, TopTrade.gg shall not be liable to that user for any damage or loss, whether direct, indirect, punitive, actual, consequential, incidental, special, exemplary, or otherwise, including loss of revenue, profits, TopTrade.gg balance, or skins.
                </p>

                <h2 className="mt-5" style={{color:'#dcdcdc',fontSize:'17px'}}>
                <Language_wrapper string_in_english={"Change in policy"} string_in_russian={'ИЗМЕНЕНИЕ ПОЛИТИКИ'} string_in_german={'ÄNDERUNG DER POLITIK'} language={language}/>
                </h2>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                We keep the right to occasionally add new features or functionalities, cancel or stop providing the platform, extensions, services, and their contents, as well as modify this agreement without prior notice.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                We might do this for a variety of reasons, such as modifications to the law or new legal requirements, the addition of new features, adjustments to business procedures, or any other factor.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                You should frequently check the platform for the most recent version of this agreement because the most recent version will always be posted there.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                We will give you reasonable notice of the changes via email or the platform if they include significant changes that affect your rights or obligations.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                If you continue to use the service after the changes take effect, you accept the updated agreement.
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                We are providing our support email here in case you have anything to say to us: support@toptrade.gg
                </p>

                <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>
                As soon as we can, a member of our support staff will email you back.
                </p>


                <ExpandLessIcon className="mt-5 mb-5" onClick={() => set_click_to_top(!click_to_top)} style={{float:'right',color:'#7F808E',fontSize:'2.3rem',cursor:'pointer'}}/>
            </div>
        </div>
    )
}