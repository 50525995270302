import React from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import RemoveShoppingCartIcon from '@mui/icons-material/RemoveShoppingCart';
import '../Css/Item_offer.css';

export default function Bot_item({userID,overlay,index,currency,user_offer_value,set_user_offer_value,set_user_items_trade,user_items_trade,item,user_items,set_user_items}) {

    const item_price = item[9]

    function remove(){
        user_items.unshift(item)
        set_user_items_trade(user_items_trade.filter((element) => element !== item))

        if (user_offer_value - item_price <= 0){
            set_user_offer_value(0)
        } else {
            set_user_offer_value(user_offer_value - item_price)
        }
    }

    var stattrak = false
    var souvenir = false
    if (item[4].includes('StatTrak')) {
        var stattrak = true
    } else if (item[4].includes('Souvenir')) { 
        var souvenir = true
    }

    function cardId(){  
        return 'userCardU'+index+index
    }
    
    function adjustWear(float){ 
        if (parseFloat(float) < 0.07){
            return 'FN' +' / ' + float
        } else if (parseFloat(float) < 0.15 && parseFloat(float) >= 0.07){ 
            return 'MW'+' / ' + float
        } else if (parseFloat(float) < 0.38 && parseFloat(float) >= 0.15){
            return 'FT'+' / ' + float
        } else if (parseFloat(float) < 0.45 && parseFloat(float) >= 0.38){
            return 'WW'+' / ' + float
        } else if (parseFloat(float) >= 0.45){
            return 'BS'+' / ' + float
        } else {
            return float
        }
    }

    function getDaysLeft(dateString) {
        // Parse the given date string into a Date object
        const targetDate = new Date(dateString);
      
        // Get the current date
        const currentDate = new Date();
      
        // Calculate the difference between the two dates in milliseconds
        const timeDifference = targetDate.getTime() - currentDate.getTime();
      
        // Convert the milliseconds difference to days
        const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      
        return daysLeft;
      }

      function checkSticker(item){
        if (item.length == 0 || item['marketHashName'] == null){
            return ''
        } else {
            return 'https://community.akamai.steamstatic.com/economy/image/'+item['image']
        }
    }

    function hidePopUp() {
        const cardElement = document.getElementById(cardId());
        if (cardElement !== null) {
            cardElement.style.backgroundColor = '#24252E';
        }
    
        const imageElement = document.getElementById('popUpUImageUO' + index);
        if (imageElement !== null) {
            imageElement.style.position = 'relative';
            imageElement.style.transform = 'rotate(+1deg) scale(1.0)';
            imageElement.style.transition = 'transform 0.3s';
        }
    
        const clickIconElement = document.getElementById('popUpUClickIcon' + index);
        if (clickIconElement !== null) {
            clickIconElement.style.display = 'none';
        }
    }
    
    const numberOfStickers=(item[12].length == 0?0:1) + (item[13].length == 0?0:1) + (item[14].length == 0?0:1) + (item[15].length == 0?0:1) + (item[16].length == 0?0:1)
    const CardstickerNumber = [1,2,3,4,5].slice(0, numberOfStickers)

    return (
        <div className="col-3 gx-1 mb-1 hovereff-u">
            <Card className="userCard" id={cardId()} onMouseLeave={hidePopUp}>
            <Card.Text  style={{position:'absolute'}}></Card.Text>
                <center className="next_to_image_filler" ><Card.Img className="userCardImage" id={'popUpUImageUO'+ index} variant="top" src={"https://steamcommunity-a.akamaihd.net/economy/image/"+item[2]} loading="lazy"/></center>
                {checkSticker(item[12]) != ''?<img className={'Cardsticker'+CardstickerNumber.shift()} src={checkSticker(item[12])}></img>:<img className='CardstickerHide' src={checkSticker(item[12])}></img>}
                {checkSticker(item[13]) != ''?<img className={'Cardsticker'+CardstickerNumber.shift()} src={checkSticker(item[13])}></img>:<img className='CardstickerHide' src={checkSticker(item[13])}></img>}
                {checkSticker(item[14]) != ''?<img className={'Cardsticker'+CardstickerNumber.shift()} src={checkSticker(item[14])}></img>:<img className='CardstickerHide' src={checkSticker(item[14])}></img>}
                {checkSticker(item[15]) != ''?<img className={'Cardsticker'+CardstickerNumber.shift()} src={checkSticker(item[15])}></img>:<img className='CardstickerHide' src={checkSticker(item[15])}></img>}
                {checkSticker(item[16]) != ''?<img className={'Cardsticker'+CardstickerNumber.shift()} src={checkSticker(item[16])}></img>:<img className='CardstickerHide' src={checkSticker(item[16])}></img>}
                <Card.Body className="userCardBody" style={{height:'0px'}}>
                    <Card.Text  className="userCardText" style={{color:'#7F808E'}}>
                    {stattrak?<div className="last-letter-color">S {adjustWear(Math.round(parseFloat(item[11]) * 10000) / 10000)}</div>:(souvenir?<div className="last-letter-color-souvenir">S {adjustWear(Math.round(parseFloat(item[11]) * 10000) / 10000)}</div>:<div>{adjustWear(Math.round(parseFloat(item[11]) * 10000) / 10000)}</div>)}
                    </Card.Text>
                    <Card.Title  className="userCardTitle" style={{color:'#dcdcdc',fontWeight:'bold'}}>{currency == '$ USD'?<div>$ {item_price.toFixed(2)}</div>:<div>€ {(item_price*0.92).toFixed(2)}</div>}</Card.Title>
                    <center>
                        {overlay?<Button className="remove"><RemoveShoppingCartIcon className="cart" style={{height:'15px',position:'relative',top:'-8px',color:'#F96868'}}/></Button>:<Button className="remove" onClick={remove}><RemoveShoppingCartIcon className="cart" style={{height:'15px',position:'relative',top:'-8px',color:'#F96868'}}/></Button>}
                    </center>
                </Card.Body>
            </Card>
        </div>
    );
}