import React from "react";
import Menu from "../Components/Menu";
import { useEffect, useState } from "react";
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CustomAlert from "../Components/CustomAlert";

export default function Refund_policy({language,changeLanguage,userAvatar,setUserAvatar,setUserVisibility,currency,setCurrency,loggedIn,setLoggedIn,addBalance,userProfileOptionsState,setUserProfileOptionsState,userProfileOptions,logOut,link,userBalance,setUserBalance,userID,set_userID,userName,setUserName,set_user_items,setCreatedAt,setEmail,setTradeLink,getuserrefferals,overlay,setOverlay,addPopup,fetchUserUsingPromoCode,promoCodeUsed,tradeLink}) {

    const [click_to_top, set_click_to_top] = useState(false); // State for the "Click to top" button

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: Add smooth scrolling animation
          }); // Scroll to the top of the page on component mount
    }, [click_to_top]);


    return(
        <div>
            <CustomAlert isOpen={overlay} onClose={() =>  setOverlay(false)} addBalance={addBalance} setOverlay={setOverlay} link={link} addPopup={addPopup} fetchUserUsingPromoCode={fetchUserUsingPromoCode} promoCodeUsed={promoCodeUsed} logOut={logOut}/>

            <Menu language={language} changeLanguage={changeLanguage} currency={currency} setCurrency={setCurrency} loggedIn={loggedIn} userBalance={userBalance} addBalance={addBalance} setUserProfileOptionsState={setUserProfileOptionsState} userProfileOptions={userProfileOptions} logOut={logOut} userAvatar={userAvatar} userName={userName} userProfileOptionsState={userProfileOptionsState} userID={userID} set_userID={set_userID} setUserAvatar={setUserAvatar} setUserBalance={setUserBalance} setUserVisibility={setUserVisibility} setLoggedIn={setLoggedIn} setUserName={setUserName} set_user_items={set_user_items} link={link} setCreatedAt={setCreatedAt} setEmail={setEmail} setTradeLink={setTradeLink} getuserrefferals={getuserrefferals} addPopup={addPopup} promoCodeUsed={promoCodeUsed} tradeLink={tradeLink}/>

            <div className="mt-5 container selectText" style={{padding:'25px',fontWeight:'500'}} fetchUserUsingPromoCode={fetchUserUsingPromoCode}>
            

            <h2 className="mt-3" style={{color:'#dcdcdc',fontSize:'17px'}}>Please read this refund policy before making any purchase or exchange on TopTrade.gg.</h2>

            <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>By making a purchase or an exchange, you certify that you have read, understood, and agree with this Refund Policy.</p>

            <p style={{color:'#7F808E',fontSize:'15px'}}>This Refund Policy covers the following purchases:</p>

            <p style={{color:'#dcdcdc',fontSize:'15px'}}>1. TopTrade.gg balance.</p>

            <p style={{color:'#dcdcdc',fontSize:'15px'}}>2. Any skin exchange on the website.</p>

            <p style={{color:'#dcdcdc',fontSize:'15px'}}>All transactions with skins (exchange, purchase) are final and can not be reversed.</p>

            <p style={{color:'#7F808E',fontSize:'15px'}}>By agreeing to this refund policy and making any exchange or purchase, you strictly lose your right of withdrawal and waive any rights in connection therewith.</p>

            <h2 className="mt-5" style={{color:'#dcdcdc',fontSize:'17px'}}>You may request for a refund with the following reasons:</h2>

            <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>Virtual item or service was not delivered.</p>

            <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>Please keep in mind that we will start processing your purchase once your payment is approved. Usually, it takes seconds to confirm your payment, but it may take longer depending on your Payment Method.</p>

            <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>Any delay in payment processing by a payment service provider does not constitute a non-delivery and does not give rise to a refund.</p>

            <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>If you have not received the ordered virtual item or service or received it but not in full, please contact us on our email support@toptrade.gg . Once we confirm the non-delivery, we will proceed with the refund.</p>

            <p style={{color:'#7F808E',fontSize:'15px'}}>TopTrade.gg balance within 14 days from the transaction date provided that you haven’t used.</p>

            <p style={{color:'#7F808E',fontSize:'15px'}}>The balance and non-crypto currency was used as the Payment Method, please if you want refund contact us on our email support@toptrade.gg</p>

            <p style={{color:'#7F808E',fontSize:'15px'}}>If you purchase balance and do not have Steam Guard or mobile authenticator enabled for a sufficient duration to trade without a trade hold, you are NOT eligible for a refund.</p>

            <h2 className="mt-3" style={{color:'#dcdcdc',fontSize:'17px'}}>When can I expect a refund ?</h2>

            <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>By default, purchases will be refunded via the Payment Method used to make the purchase.</p>

            <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>A refund takes about 30 days but in any way, not more than 60 days.</p>

            <p className="mt-4" style={{color:'#7F808E',fontSize:'15px'}}>There is no possibility for you to get refunds in cryptocurrency.</p>

            <ExpandLessIcon className="mt-5 mb-5" onClick={() => set_click_to_top(!click_to_top)} style={{float:'right',color:'#7F808E',fontSize:'2.3rem',cursor:'pointer'}}/>
        </div>
        </div>
    )
}