import React from "react";

export default function Language_wrapper({language, string_in_slovak, string_in_english, string_in_russian, string_in_german}){
    if (language === 'English') {
        return (string_in_english)
    } else if (language === 'Русский') {
        return (string_in_russian)
    } else if (language === 'Deutsch') {
        return (string_in_german)
    } else if (language === 'Slovak'){
        return (string_in_slovak)
    } else {
        return (string_in_english)
    }
};
