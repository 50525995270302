import React, { useEffect } from 'react';
import Modal from 'react-modal';
import '../Css/Main_app.css';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

Modal.setAppElement('#root');

const customAlertStyles = {
  overlay: {
    backgroundColor: 'rgba(	22, 22, 24, 0.9)', // Change this color as needed
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    paddingTop: '10px', // Adjust as needed for spacing from the top
    zIndex: 5,
  },
  content: {
    overflow: 'visible',
    position: 'relative',
    left: '-35%',
    top: '35%',
    border: 'none',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    padding: '10px',
    boxShadow: '0 0 4px 10px rgba(12, 12, 13, 0.1)',
    width: '30%', // Adjust as needed for width
    backgroundColor: '#24252D',
    color: '#dcdcdc',
  },
};


const RefferalCodeAlert = ({ isOpen, promoCodeUsed, setOpenReferralCodeAlert, fetchUserUsingPromoCode, addPopup, link, logOut}) => {

    const [promoCode,setPromoCode] = React.useState(promoCodeUsed)

    useEffect(() => {
        setPromoCode(promoCodeUsed)
        setDisplayMessage('')
    },[isOpen])

    const [displayMessage,setDisplayMessage] = React.useState('')

    async function checkPromoCode(){
        if (promoCode.length < 3 || promoCode.length > 12){
          setDisplayMessage('Invalid promo code')

          Shake(document.getElementById('input_shake'))
          return
        }

        if (localStorage.getItem('user_id') !== ''){
          const response = await fetch(`${link}/api/claimPromoCode`,{method:'POST',headers:{'Content-Type':'application/json'},body:JSON.stringify({ID:localStorage.getItem('user_id'),auth_session_token:localStorage.getItem('auth_session_token'),code:promoCode})});
          const data  = await response.json()
          
          if (response.status == 200 && data == 'success'){
            addPopup('Promo code claimed successfully')
            fetchUserUsingPromoCode()
            setOpenReferralCodeAlert(false)

          } else if (response.status == 200 && data == 'invalid code'){
            addPopup('Invalid promo code')
            setDisplayMessage('Invalid promo code')  
            Shake(document.getElementById('input_shake'))

          } else if (response.status == 200 && data == 'verification error'){
            addPopup('Verification error, try to re-login')
            setDisplayMessage('Verification error, try to re-login') 
            Shake(document.getElementById('input_shake'))

          } else if (response.status == 200 && data == 'error'){
            addPopup('If the problem persists, contact support')
            addPopup('There was an error claiming this promocode, try again later')
            setDisplayMessage('There was an error claiming this promocode, try again later') 
            Shake(document.getElementById('input_shake'))

          } else if (response.status == 429){
            addPopup('Too many requests, wait a minute and try again')
            setDisplayMessage('Too many requests, wait a minute and try again')
            Shake(document.getElementById('input_shake'))
          } else if (data === 'logout'){
            logOut();
          }
        }
    }

    function validatePromoCode(code){
        if (code.length <= 12){
          setPromoCode(code.toLowerCase())
        }
        if (code == promoCodeUsed){
          setDisplayMessage('')
        }
    }

    function Shake(element) {
      // Apply the "shake" class to the element
      element.classList.add('shake');
    
      // After 0.25 seconds, remove the "shake" class
      setTimeout(() => {
        element.classList.remove('shake');
      }, 250); // 250 milliseconds = 0.25 seconds
    }

  return (
    <Modal className='refferalWindow_adopted selectCursor selectText' closeTimeoutMS={250} isOpen={isOpen} onRequestClose={() => (setOpenReferralCodeAlert(false))} style={customAlertStyles}>
        <ClearOutlinedIcon className='x_button' style={{float:'right',color:'#7F808E'}} onClick={() => setOpenReferralCodeAlert(false)}/>
        <div style={{padding:'15px'}}>
            <div style={{width:'85%'}}>
              <h5 style={{margin:'0px',fontWeight:'500',fontSize:'17px'}}>Claim <a style={{color:'#89C74B'}}>Free $5</a> and a <a style={{color:'#89C74B'}}>+5%</ a> deposit bonus</h5>
              <p className='supportPage' style={{color:'#7F808E',marginTop:'5px',fontSize:'15px',fontWeight:'500',marginRight:'-70px'}}>Enter the code of your favorite creator and start trading your items. You will also get the chance to earn up to $5 on the first trade you make.Use code <>"toptrade" </>if you don't have any.</p>
            </div>
            
            <input id='input_shake' className={`profile_input_fields`} value={promoCode} placeholder={promoCodeUsed != '' && promoCodeUsed != null && promoCode != ''?'':'Enter promo code...'} onChange={(e) => (validatePromoCode(e.target.value))} style={{borderRadius:'4px',position:'relative',backgroundColor:'#2B2C36',color:'#dcdcdc',width:'100%',transition:'color 0.25s',padding:'6px',fontWeight:'500',fontSize:'17px',border:(displayMessage != 'Promo code claimed successfully' && displayMessage != ''?'0.5px #F96868 solid':'')}}></input>

            <div style={{position:'relative'}}>
              <div style={{position:'absolute',fontSize:'0.85rem'}}>{promoCodeUsed != '' && promoCodeUsed != null && displayMessage == ''?'':(displayMessage == 'Promo code claimed successfully'?'':<a style={{color:'#F96868'}}>{displayMessage}</a>)}</div> 
            </div>

            <button disabled={promoCodeUsed == promoCode} style={{fontSize:'15px',fontWeight:'500',borderRadius:'4px',padding:'5px 25px',float:'right',marginTop:'10px',marginBottom:'14px',border:'none',backgroundColor:'#89C74B',opacity:(promoCodeUsed == promoCode?'0.4':'1'),transition:'opacity 0.25s'}} onClick={checkPromoCode}>Claim</button>
        </div>
    </Modal>
  );
};

export default RefferalCodeAlert;