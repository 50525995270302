import React, {useRef,useEffect} from "react";
import Menu from "../Components/Menu";
import {Link} from 'react-router-dom';
import Language_wrapper from '../Components/Language_wrapper';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';import AddLinkIcon from '@mui/icons-material/AddLink';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import User_backpack_item from "../Components/User_backpack_item";
import Dropdown from 'react-bootstrap/Dropdown';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CustomAlert from "../Components/CustomAlert";
import '../Css/Main_app.css';
import SearchIcon from '@mui/icons-material/Search';

export default function Backpack({getuserrefferals,language,changeLanguage,userAvatar,setUserAvatar,setUserVisibility,currency,setCurrency,loggedIn,setLoggedIn,addBalance,overlay,userProfileOptionsState,setUserProfileOptionsState,userProfileOptions,logOut,link,userBalance,setUserBalance,userID,set_userID,userName,setUserName,set_user_items,createdAt,setCreatedAt,setEmail,setTradeLink,email,tradeLink,backpackitems,setOverlay,addPopup,fetchUserUsingPromoCode,promoCodeUsed,renderPopups,setBackpackItems}) {

    const [refreshDegrees, setRefreshDegrees] = React.useState(90);

    const [dropdownSort, setDropdownSort] = React.useState('Price: Max');

    const [user_backpack_inventory_limit, set_user_backpack_inventory_limit] = React.useState(25);

    function changeDropdownSort(string){
        setDropdownSort(string);
    }

    const scrollableElementRef = useRef(null);

    useEffect(() => {
        function handleScroll() {
          const element = scrollableElementRef.current;
          if (element) {
            const { scrollTop, scrollHeight, clientHeight } = element;
            const scrollOffset = 1;
            if (scrollHeight - scrollTop - clientHeight <= scrollOffset) {
                set_user_backpack_inventory_limit(prevAmount => prevAmount + 25);
            }
          }
        }
    
        const scrollableElement = scrollableElementRef.current;
        if (scrollableElement) {
          scrollableElement.addEventListener('scroll', handleScroll);
        }
      }, []);

    const [dropdownIcon, setDropdownIcon] = React.useState(0);

    function changeDropdownIcon(){
        if (dropdownIcon == 0){
            setDropdownIcon(180);
        } else {
            setDropdownIcon(0);
        }
    }

    function dispplayDropdownSort(){
        if (dropdownSort === 'Price: Max'){
            return <div><svg className='dropdown-icons' viewBox="0 0 20 20" fill='#dcdcdc' xmlns="http://www.w3.org/2000/svg" aria-label="filter up"><path d="M10 14.75v-.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5zM7 10.25v-.5a.5.5 0 01.5-.5h8a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5zM4 5.75v-.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5z"></path></svg><Language_wrapper string_in_slovak={'Cena: Max'} string_in_english={"Price: Max"} string_in_russian={'цена: макс'} string_in_german={'Preis: Max'} language={language}/><KeyboardArrowDownRoundedIcon style={{rotate:`${dropdownIcon}deg`,transition:'0.2s'}}/></div>
          } else if (dropdownSort === 'Price: Min'){
            return <div><svg className='dropdown-icons' fill='#dcdcdc' viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"  aria-label="filter down"><path d="M4 14.25v.5a.5.5 0 00.5.5h11a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5h-11a.5.5 0 00-.5.5zM7 9.75v.5a.5.5 0 00.5.5h8a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5h-8a.5.5 0 00-.5.5zM10 5.25v.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5z"></path></svg><Language_wrapper string_in_slovak={'Cena: Min'} string_in_english={"Price: Min"} string_in_russian={'цена: мин'} string_in_german={'Preis: Min'} language={language}/><KeyboardArrowDownRoundedIcon style={{rotate:`${dropdownIcon}deg`,transition:'0.2s'}}/></div>
          } else if (dropdownSort === 'Float: Max'){
            return <div><svg className='dropdown-icons' viewBox="0 0 20 20" fill='#dcdcdc' xmlns="http://www.w3.org/2000/svg" aria-label="filter up"><path d="M10 14.75v-.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5zM7 10.25v-.5a.5.5 0 01.5-.5h8a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5zM4 5.75v-.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5z"></path></svg><Language_wrapper string_in_slovak={'Float: Max'} string_in_english={"Float: Max"} string_in_russian={'оружия: макс'} string_in_german={'Tragen: Max'} language={language}/><KeyboardArrowDownRoundedIcon style={{rotate:`${dropdownIcon}deg`,transition:'0.2s'}}/></div>
          } else if (dropdownSort === 'Float: Min'){
            return <div><svg className='dropdown-icons' fill='#dcdcdc' viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"  aria-label="filter down"><path d="M4 14.25v.5a.5.5 0 00.5.5h11a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5h-11a.5.5 0 00-.5.5zM7 9.75v.5a.5.5 0 00.5.5h8a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5h-8a.5.5 0 00-.5.5zM10 5.25v.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5z"></path></svg><Language_wrapper string_in_slovak={'Float: Min'} string_in_english={"Float: Min"} string_in_russian={'оружия: мин'} string_in_german={'Tragen: Min'} language={language}/><KeyboardArrowDownRoundedIcon style={{rotate:`${dropdownIcon}deg`,transition:'0.2s'}}/></div>
          }
    }

    const [refreshingBackpack, setRefreshingBackpack] = React.useState(false);
    
    async function refreshBackpack(){
        setRefreshingBackpack(true);
        setRefreshDegrees(refreshDegrees + 360);

        if (localStorage.getItem('user_id') !== null){
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({ID:localStorage.getItem('ID'),auth_session_token:localStorage.getItem('auth_session_token')})
            };
            const response = await fetch(`${link}/api/getplayerbackpackitems`, requestOptions);
            const data = await response.json();
            if (data != []){
                setBackpackItems(data);
            }
        }
        setRefreshingBackpack(false);
        return;
    }

    const [backpackSearch, setBackpackSearch] = React.useState('');

    const [withrawItems, setWithdrawItems] = React.useState([]);

    function sortedBackpackitems(){
        const user_backpack_inventory = backpackitems.map((item, index) => (<User_backpack_item link={link} addPopup={addPopup} language={language} userID={userID} overlay={overlay} currency={currency} key={index} index={index} item={item} refreshingBackpack={refreshingBackpack} withrawItems={withrawItems} setWithdrawItems={setWithdrawItems}/>
        ))
        if (dropdownSort === 'Price: Min'){
        user_backpack_inventory.sort((a, b) => {
            const priceA = a.props.item[9];
            const priceB = b.props.item[9];
        
            // If item[9] is null, move it to the end
            if (priceA === null) {
            return 1; // a has null price (should come last)
            } else if (priceB === null) {
            return -1; // b has null price (should come last)
            }
        
            // Compare based on item[9] (price) in ascending order
            return parseFloat(priceA) - parseFloat(priceB);
        });
        } else if (dropdownSort === 'Price: Max'){
        user_backpack_inventory.sort((a,b) => (a.props.item[9] > b.props.item[9]) ? -1 : ((b.props.item[9] > a.props.item[9]) ? 1 : 0))
        } else if (dropdownSort === 'Float: Min'){
        user_backpack_inventory.sort((a, b) => {
            const floatA = parseFloat(a.props.item[11]);
            const floatB = parseFloat(b.props.item[11]);
            const priceA = a.props.item[9];
            const priceB = b.props.item[9];
        
            // If item[9] is null in either item, move it to the end
            if (priceA === null && priceB !== null) {
            return 1; // a has null price (should come last)
            } else if (priceB === null && priceA !== null) {
            return -1; // b has null price (should come last)
            }
        
            // Compare based on item[11] (float) in ascending order
            return floatA - floatB;
        });
        } else if (dropdownSort === 'Float: Max'){
        user_backpack_inventory.sort((a, b) => {
            const floatA = parseFloat(a.props.item[11]);
            const floatB = parseFloat(b.props.item[11]);
            const priceA = a.props.item[9];
            const priceB = b.props.item[9];
        
            // If item[9] is null in either item, move it to the end
            if (priceA === null && priceB !== null) {
            return 1; // a has null price (should come last)
            } else if (priceB === null && priceA !== null) {
            return -1; // b has null price (should come last)
            }
        
            // Compare based on item[11] (float) in ascending order
            return floatB - floatA;
        });
        }
    
        return user_backpack_inventory.filter((item) => {
        const itemName = item.props.item[4].toLowerCase().replace(/\s/g, '');
        const searchQuery = backpackSearch.toLowerCase().replace(/\s/g, '');
    
        if (backpackSearch !== '') {
            const escapedSearchQuery = searchQuery.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
            const fuzzySearchPattern = new RegExp(escapedSearchQuery.split('').join('.*'));
            return fuzzySearchPattern.test(itemName);
        } else {
            return true;
        }
        }).slice(0, user_backpack_inventory_limit);
    }

    function handleInputChange(event){
        setBackpackSearch(event.target.value);
    }

    async function withdrawBackpackitem(){
        if (localStorage.getItem('user_id') !== null){
            addPopup('Trade offer is being prepared, you will have 3 minutes to accept it')
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({userID:localStorage.getItem('user_id'),auth_session_token:localStorage.getItem('auth_session_token'),assetIDs:withrawItems})
            };
            const response = await fetch(`${link}/api/withdrawBackpackItem`, requestOptions);
            const data = await response.json();
            if (response.status == 429){
                addPopup('You can only withdraw once per hour, please try again later')
                return;
            } else if (response == 'Bot is too busy, try again later'){
                addPopup('Bot is too busy, try again later, if this issue persists, contact support')
            } else if (data === 'logout'){
                logOut();
            } else {
                addPopup(data)  
                if (data == 'Error creating trade offer, try again later'){
                    addPopup("If this issue persists, please contact support")
                }
            }
        } 
    }

    return (
        <div>
            <div>
                {renderPopups}
            </div>
            <CustomAlert isOpen={overlay} onClose={() =>  setOverlay(false)} addBalance={addBalance} setOverlay={setOverlay} link={link} addPopup={addPopup} fetchUserUsingPromoCode={fetchUserUsingPromoCode} promoCodeUsed={promoCodeUsed} logOut={logOut}/>

            <Menu language={language} changeLanguage={changeLanguage} currency={currency} setCurrency={setCurrency} loggedIn={loggedIn} userBalance={userBalance} addBalance={addBalance} setUserProfileOptionsState={setUserProfileOptionsState} userProfileOptions={userProfileOptions} logOut={logOut} userAvatar={userAvatar} userName={userName} userProfileOptionsState={userProfileOptionsState} userID={userID} set_userID={set_userID} setUserAvatar={setUserAvatar} setUserBalance={setUserBalance} setUserVisibility={setUserVisibility} setLoggedIn={setLoggedIn} setUserName={setUserName} set_user_items={set_user_items} link={link} setCreatedAt={setCreatedAt} setEmail={setEmail} setTradeLink={setTradeLink} getuserrefferals={getuserrefferals} fetchUserUsingPromoCode={fetchUserUsingPromoCode} addPopup={addPopup} promoCodeUsed={promoCodeUsed} tradeLink={tradeLink}/>

            <div className="mt-4 profileLeft" style={{backgroundColor:'#24252E',borderRadius:'8px'}}>
                <div className="on_profile_padding">
                    <Link to='/profile' style={{textDecoration:'none'}}><div className='userProfileOptions_item_on_profile mt-2'><AccountCircleIcon style={{fontSize:'17px',marginRight:'3px'}} className="icon"/> <Language_wrapper string_in_slovak={'Profil'} string_in_english={"Profile Details"} string_in_russian={'детали профиля'} string_in_german={'Profildetails'} language={language}/></div></Link>
                    <Link to='/backpack' style={{textDecoration:'none'}}><div className='userProfileOptions_item_on_profile mt-2' style={{color:'#dcdcdc',backgroundColor:'#383a45', borderRadius:'4px'}}><Inventory2Icon style={{fontSize:'17px',marginRight:'3px',color:'#ffca4f',marginTop:'1px'}} className="icon"/> <Language_wrapper string_in_slovak={'Tvoj Batoh'} string_in_english={"Your Backpack"} string_in_russian={'Ваш рюкзак'} string_in_german={'Dein Rucksack'} language={language}/></div></Link>
                    <Link to='/transactions' style={{textDecoration:'none'}}><div className='userProfileOptions_item_on_profile mt-2'><ReceiptLongIcon style={{fontSize:'17px',marginRight:'3px'}} className="icon"/> <Language_wrapper string_in_slovak={'Transakcie'} string_in_english={"Transaction History"} string_in_russian={'История транзакций'} string_in_german={'Verlauf der Transaktionen'} language={language}/></div></Link>
                    <Link to='/affiliate' style={{textDecoration:'none'}}><div className='userProfileOptions_item_on_profile mt-2'><AddLinkIcon style={{fontSize:'17px',marginRight:'3px'}} className="icon"/> <Language_wrapper string_in_slovak={'Partnerský program'} string_in_english={"Affiliate Program"} string_in_russian={'Партнерская программа'} string_in_german={'Partnerprogramm'} language={language}/></div></Link>
                    <div className='userProfileOptions_item_on_profile logout' onClick={() => (logOut(),window.location.href=(link == 'http://127.0.0.1:5000'?'http://127.0.0.1:3000':'https://toptrade.gg/'))}><ArrowOutwardIcon style={{fontSize:'17px',marginRight:'3px',position:'relative',top:'-2px'}}/> Sign Out</div>
                </div>
            </div>
            <div className="mt-4 profileRight on_profile_padding_right_box" style={{backgroundColor:'#24252E',borderRadius:'8px',paddingBottom:'15px'}}>
                <div className="Profile_details selectCursor selectText"><Inventory2Icon style={{color:'#ffca4f',top:'-2px',position:'relative',fontSize:'20px',left:'1px',marginLeft:'0px'}}/> <strong style={{color:'#dcdcdc',fontSize:'17px',fontWeight:'500',position:'relative',top:'-1px',marginLeft:'2px'}}><Language_wrapper string_in_slovak={'Tvoj batoh'} string_in_english={"Your Backpack"} string_in_russian={'Ваш рюкзак'} string_in_german={'Dein Rucksack'} language={language}/></strong>
                <button onClick={withdrawBackpackitem} className="withdraw_button_backpack" style={{fontSize:'15px',borderRadius:'4px',backgroundColor:'#ffca4f',color:'black',border:'none',float:'right',padding:'5px 15px',marginRight:'0px',marginTop:'0px',transition:'opacity 0.2s',opacity:(withrawItems.length != 0?'1':'0')}}>Withdraw</button>
                </div>

                <div className="mt-3">
                    <div style={{backgroundColor:'#1A1C23',borderRadius:'4px',padding:'5px',height:'600px',marginTop:'-5px'}}>
                        <div className="row">
                            <div className="col-12">
                                
                                <div style={{position:'relative'}}><input className='nosubmit-bot mx-1' id="backpack_search" type='text' style={{float:'left',border:'none',borderColor:'transparent',width:'73.8%',color:'#dcdcdc',fontWeight:'500'}} placeholder="Search..." value={backpackSearch} onChange={handleInputChange}></input><SearchIcon id='backpack_search-icon' style={{position:'absolute',fontSize:'1.8rem'}}/></div>

                                <button className='refresh-button' onClick={refreshBackpack}><svg style={{float:'right',rotate:`${refreshDegrees}deg`,transition:'rotate 0.3s'}} className='mx-1 refresh' height="22" viewBox="0 0 21 21" width="22" strokeWidth='1.5px' xmlns="http://www.w3.org/2000/svg"><g fill="none" fillRule="evenodd" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" transform="translate(2 2)"><path d="m4.5 1.5c-2.4138473 1.37729434-4 4.02194088-4 7 0 4.418278 3.581722 8 8 8s8-3.581722 8-8-3.581722-8-8-8"/><path d="m4.5 5.5v-4h-4"/></g></svg>
                                </button>

                                <Dropdown style={{float:'right',marginRight:'5px'}} onClick={changeDropdownIcon}>
                                    <Dropdown.Toggle className='btn-filter' id="dropdown-basic">
                                    {dispplayDropdownSort()}
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => (changeDropdownSort("Price: Max"))}><svg className='dropdown-icons' viewBox="0 0 20 20" fill='#dcdcdc' xmlns="http://www.w3.org/2000/svg" aria-label="filter up"><path d="M10 14.75v-.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5zM7 10.25v-.5a.5.5 0 01.5-.5h8a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5zM4 5.75v-.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5z"></path></svg><Language_wrapper string_in_slovak={'Cena: Max'} string_in_english={"Price: Max"} string_in_russian={'цена: макс'} string_in_german={'Preis: Max'} language={language}/></Dropdown.Item>
                                    <Dropdown.Item onClick={() => (changeDropdownSort("Price: Min"))}><svg className='dropdown-icons' fill='#dcdcdc' viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"  aria-label="filter down"><path d="M4 14.25v.5a.5.5 0 00.5.5h11a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5h-11a.5.5 0 00-.5.5zM7 9.75v.5a.5.5 0 00.5.5h8a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5h-8a.5.5 0 00-.5.5zM10 5.25v.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5z"></path></svg><Language_wrapper string_in_slovak={'Cena: Min'} string_in_english={"Price: Min"} string_in_russian={'цена: мин'} string_in_german={'Preis: Min'} language={language}/></Dropdown.Item>
                                    <Dropdown.Item onClick={() => (changeDropdownSort("Float: Max"))}><svg className='dropdown-icons' fill='#dcdcdc' viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"  aria-label="filter up"><path d="M10 14.75v-.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5zM7 10.25v-.5a.5.5 0 01.5-.5h8a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5zM4 5.75v-.5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v.5a.5.5 0 01-.5.5h-11a.5.5 0 01-.5-.5z"></path></svg><Language_wrapper string_in_slovak={'Float: Max'} string_in_english={"Float: Max"} string_in_russian={'оружия: макс'} string_in_german={'Tragen: Max'} language={language}/></Dropdown.Item>
                                    <Dropdown.Item onClick={() => (changeDropdownSort("Float: Min"))}><svg className='dropdown-icons' fill='#dcdcdc' viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"  aria-label="filter down"><path d="M4 14.25v.5a.5.5 0 00.5.5h11a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5h-11a.5.5 0 00-.5.5zM7 9.75v.5a.5.5 0 00.5.5h8a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5h-8a.5.5 0 00-.5.5zM10 5.25v.5a.5.5 0 00.5.5h5a.5.5 0 00.5-.5v-.5a.5.5 0 00-.5-.5h-5a.5.5 0 00-.5.5z"></path></svg><Language_wrapper string_in_slovak={'Float: Min'} string_in_english={"Float: Min"} string_in_russian={'оружия: мин'} string_in_german={'Tragen: Min'} language={language}/></Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>

                            <div className="col-12 selectCursor selectText">
                                <div className="row mt-3 heightOfTheBackpack" ref={scrollableElementRef} style={{width:'100%',overflowY:'auto',overflowX:'hidden'}}>{(backpackitems.length == 0?<center className="mb-3" style={{color:'#7f808e',marginTop:'208px',fontSize:'15px',fontWeight:'500'}}><ReportProblemIcon style={{color:'#ffca4f',marginBottom:'5px',borderRadius:'35%'}}/><br></br><Language_wrapper string_in_slovak={'Momentálne je tvoj batoh prázdny.'} string_in_english={"Currently, there are no items in Your Backpack."} string_in_russian={'В вашем рюкзаке нет предметов.'} string_in_german={'Es befinden sich keine Gegenstände in Ihrem Rucksack.'} language={language}/><br></br><Language_wrapper string_in_slovak={'Zakúpené Trade Locked predmety sa budú nachádzať tu.'} string_in_english={"They will be stored here when you make a purchase on a Trade Locked item."} string_in_russian={'Они появятся, когда вы совершите покупку предмета, заблокированного для обмена.'} string_in_german={'Sie werden angezeigt, wenn Sie einen Kauf für einen handelsgesperrten Artikel tätigen.'} language={language}/></center>:sortedBackpackitems())}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
} 