import React, { useEffect } from 'react';
import Modal from 'react-modal';
import '../Css/Main_app.css';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import SwapVerticalCircleIcon from '@mui/icons-material/SwapVerticalCircle';
import LockIcon from '@mui/icons-material/Lock';import ConfettiExplosion from 'react-confetti-explosion';


Modal.setAppElement('#root');

const customAlertStyles = {
  overlay: {
    backgroundColor: 'rgba(	22, 22, 24, 0.8)', // Change this color as needed
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    paddingTop: '10px', // Adjust as needed for spacing from the top
    zIndex: 5,
  },
  content: {
    overflow: 'visible',
    position: 'relative',
    left: '-32.5%',
    top:'30%',
    border: 'none',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    paddingLeft: '0px',
    paddingRight: '0px',
    paddingTop: '0px',
    boxShadow: '0 0 4px 10px rgba(12, 12, 13, 0.1)',
    width: '35%', // Adjust as needed for width
    backgroundColor: '#24252D',
    color: '#dcdcdc',
  },
};



export default function Trade_window({ isOpen, onClose, promoCodeUsed, addPopup, fetchUserUsingPromoCode, link, user_items_trade,bot_items_trade,currentOfferValue,currentOfferValueBot,setOpenTrade, tradeOfferStatus, set_trade_accepted, trade_accepted}){

  function getDaysLeft(dateString) {
    // Parse the given date string into a Date object
    const targetDate = new Date(dateString);
  
    // Get the current date
    const currentDate = new Date();
  
    // Calculate the difference between the two dates in milliseconds
    const timeDifference = targetDate.getTime() - currentDate.getTime();
  
    // Convert the milliseconds difference to days
    const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  
    return daysLeft;
  }

  useEffect(() => {
    if(!isOpen){
      setTimeout(() => set_trade_accepted(false), 250);
    }
  }, [isOpen])

  return (
    (trade_accepted == false && (user_items_trade.length !== 0 | bot_items_trade.length !== 0))?
    <Modal closeTimeoutMS={250} className='trade_offer_window' isOpen={isOpen} onRequestClose={onClose} style={customAlertStyles}>
        <div style={{padding:'15px',display:(trade_accepted?'none':'block')}}>
            <ClearOutlinedIcon className='x_button' style={{float:'right',color:'#7F808E'}} onClick={() => setOpenTrade(false)}/>
            <div style={{position:'relative',paddingLeft:'25px',width:'90%',margin:'0px',fontSize:'17px'}}><SwapVerticalCircleIcon style={{color:'#ffca4f',position:'absolute',marginTop:'2px',marginLeft:'-25px',fontSize:'22px',fontWeight:'500'}}/>Trade Process</div>

            <div style={{padding:'5px 10px 10px 10px'}}>
              {/* <div className='mt-3'>
                  {promoCodeUsed == ''?<a className='defaultCursor' style={{textDecoration:'none',color:'#dcdcdc',fontSize:'1rem'}}>Promo Code <a style={{color:'#7F808E'}}>(Gives</a><a style={{color:'#ffca4f'}}> 1% </a><a style={{color:'#7F808E'}}>of trade value to your refferal)</a></a>:<a className='defaultCursor' style={{textDecoration:'none',color:'#dcdcdc',fontSize:'1rem'}}>Promo Code <a style={{color:'#7F808E'}}>(</a><a style={{color:'#ffca4f'}}>Claimed</a><a style={{color:'#7F808E'}}>)</a></a>}

                  <div style={{backgroundColor:'#33353F',height:'auto', borderRadius:'4px',position:'relative'}}><input className='grey-placeholder mt-1 promocodeInput' placeholder={promoCodeUsed == ''?'Enter Promo Code':promoCodeUsed} value={promoCode} onChange={(e) => setPromoCode((e.target.value).toLowerCase())} style={{border:'solid 0px',padding:'5px',borderRadius:'3px',color:'#dcdcdc',backgroundColor:'#33353F',float:'left',fontSize:'1rem',position:'relative',width:'50%',marginRight:'5px'}}></input><button onClick={checkPromoCode} className=' mt-1' style={{border:'solid 1px #181A1F',backgroundColor:'#ffca4f',color:'black',borderRadius:'4px',fontSize:'1rem',float:'left',padding:'4px',paddingLeft:'7px',paddingRight:'7px',position:'relative'}}>Claim</button></div>
              </div> */}
              <div className="selectCursor selectText" style={{marginTop:'25px'}}><a style={{color:'#ffca4f',fontSize:'15px',fontWeight:'500'}}>Reminder: </a><a style={{color:"#7F808E",fontSize:'15px',fontWeight:'500'}}>If you trade for Trade Locked items, you will be able to view them in Your Backpack and withdraw them once they are tradable.</a></div>

              {/* <div className='mt-3 trade_offer_summary' style={{width:'100%'}}>
                  <div onMouseOver={() => setSending_details('block')} onMouseLeave={() => setSending_details('none')} style={{borderRadius:'4px',backgroundColor:'#181A1F',width:'55px',height:'55px',position:'relative',float:'left',cursor:'pointer'}}><center style={{position:'absolute',top:'25%',left:'27%',fontSize:'1.2rem'}}>+{user_items_trade.length != 0?user_items_trade.length:1}</center>
                  <div id='recevingdetails' style={{display:sending_details,top:'110%',position:'absolute',backgroundColor:'#16181F',opacity:'0.85',left:'-145px',width:'350px',padding:'5px',borderRadius:'4px'}}>{user_items_trade.length != 0?(user_items_trade.map((item) => <div><div style={{float:'left'}}>{item[4] + ' (' + item[6] + ')'}</div><div style={{float:'right'}}>$ {item[9]}</div></div>)):<div><div><div style={{float:'left'}}>Balance</div><div style={{float:'right'}}>$ {currentOfferValueBot}</div></div></div>}</div>
                  </div>

                  <SwapHorizIcon className='mx-2' style={{float:'left',marginTop:'15px',fontSize:'1.6rem'}}/>

                  <div onMouseOver={() => setReceive_details('block')} onMouseLeave={() => setReceive_details('none')} style={{borderRadius:'4px',backgroundColor:'#181A1F',width:'55px',height:'55px',position:'relative',float:'left',cursor:'pointer'}}><center style={{position:'absolute',top:'25%',left:'27%',fontSize:'1.2rem'}}>+{bot_items_trade.length != 0?bot_items_trade.length:1}</center>
                  <div id='recevingdetails' style={{display:receive_details,top:'110%',position:'absolute',backgroundColor:'#16181F',opacity:'0.85',left:'-145px',width:'350px',padding:'5px',borderRadius:'4px'}}>{bot_items_trade.length != 0?(bot_items_trade.map((item) => <div><div style={{float:'left'}}>{item[4] + ' (' + item[6] + ')'}</div><div style={{float:'right'}}>$ {item[9]}</div></div>)):<div><div><div style={{float:'left'}}>Balance</div><div style={{float:'right'}}>$ {currentOfferValue}</div></div></div>}</div>
                  </div>
              </div>
               */}
              
              <div className='trade_summary_adapted' style={{ width: '100%', backgroundColor: '#33353F', borderRadius: '8px', position: 'relative', height: '200px', marginTop: '33px' }}>

                <div id='user_items_window' style={{ width: '50%', float: 'left', paddingTop: '5px', paddingLeft: '10px', paddingBottom:'10px', borderBottom: '1px solid #41434B' }}>
                  <a style={{ color: '#dcdcdc',fontSize:'15px',fontWeight:'500'}}>Your Items</a>
                  <p style={{ fontSize: '12px', color: 'transparent'}}>Registered 8, Jun 2023</p>

                  {user_items_trade.length != 0?user_items_trade.map((item,index) => (index < 3?<div className='trade_items_adapted' style={{ backgroundColor: '#24252E', borderRadius: '4px', height: '54px', width: '54px', float: 'left', margin:'2px' }}><img className='trade_items_adapted' alt='..' src={'https://steamcommunity-a.akamaihd.net/economy/image/'+item[2]} style={{ padding: '2px', marginTop:'2px', height: '50px', width: '54px', filter: 'drop-shadow(-3px 4px 1.5px transparent)' }}></img></div>:(index == user_items_trade.length-1?<div className='trade_items_adapted' style={{ backgroundColor: '#24252E', borderRadius: '4px', height: '54px', width: '54px', float: 'left', margin:'2px',position:'relative' }}><center style={{position:'absolute',top:'25%',left:'30%'}}>+{user_items_trade.length-3}</center></div>:''))):<div className='trade_items_adapted' style={{ visibility:'hidden', backgroundColor: '#24252E', borderRadius: '4px', height: '54px', width: '54px', float: 'left', margin:'2px',position:'relative' }}><center style={{position:'absolute',top:'25%',left:'30%'}}>+0</center></div>}

                
                </div>

                <div id='bot_items_window' style={{ width: '50%', float: 'right', borderLeft: '1px solid #41434B', borderBottom: '1px solid #41434B', paddingTop: '5px', paddingLeft: '10px', paddingBottom:'6.5px' }}>
                  <a style={{ color:'#dcdcdc',fontSize:'15px',fontWeight:'500'}}>TopTrade Bot #1</a>
                  <p style={{ fontSize: '12px', color: '#7F808E',position:'relative',marginTop:'-4px',marginBottom:'23.5px',fontWeight:'500'}}>Registered 8, Jun 2023</p>
                                  
                  {bot_items_trade.length != 0?bot_items_trade.map((item,index) => (index < 3?(item[8] == '0' || getDaysLeft(item[8]) <= 0?<div className='trade_items_adapted' style={{ backgroundColor: '#24252E', borderRadius: '4px', height: '54px', width: '54px', float: 'left', margin:'2px' }}><img className='trade_items_adapted' alt='..' src={'https://steamcommunity-a.akamaihd.net/economy/image/'+item[2]} style={{ padding: '2px', marginTop:'2px', height: '50px', width: '54px', filter: 'drop-shadow(-3px 4px 1.5px transparent)' }}></img></div>:<div className='trade_items_adapted' style={{ backgroundColor: '#24252E', borderRadius: '4px', height: '54px', width: '54px', float: 'left', margin:'2px', position:'relative' }}><img className='trade_items_adapted' alt='..' src={'https://steamcommunity-a.akamaihd.net/economy/image/'+item[2]} style={{ padding: '2px', marginTop:'2px', height: '50px', width: '54px', filter: 'drop-shadow(-3px 4px 1.5px transparent)', opacity:'0.4' }}></img><LockIcon style={{color:'#7F808E',position:'absolute',top:'28%',left:'28%',opacity:'0.8'}}/></div>):(index == bot_items_trade.length-1?<div className='trade_items_adapted' style={{ backgroundColor: '#24252E', borderRadius: '4px', height: '54px', width: '54px', float: 'left', margin:'2px',position:'relative' }}><center style={{position:'absolute',top:'25%',left:'30%'}}>+{bot_items_trade.length-3}</center></div>:''))):<div className='trade_items_adapted' style={{ visibility:'hidden', backgroundColor: '#24252E', borderRadius: '4px', height: '54px', width: '54px', float: 'left', margin:'2px',position:'relative' }}><center style={{position:'absolute',top:'25%',left:'30%'}}>+0</center></div>}
                </div>

                <p style={{ fontSize: '15px',fontWeight:'500', color: (tradeOfferStatus == 'Trade offer was declined' | tradeOfferStatus == 'Offer timed out' | tradeOfferStatus == 'Set your Steam Trade Link on your Profile Page'?'#BF5858':(tradeOfferStatus == 'Trade offer accepted'?'#89C74B':'#7F808E')),position:'absolute',bottom:'0px',marginLeft:'22px'}}>{tradeOfferStatus == 'Trade offer was declined' | tradeOfferStatus == 'Offer timed out'?'Trade failed...':tradeOfferStatus}</p>

              </div>
            </div>
          
        </div>

       
    </Modal>
    :
    <Modal closeTimeoutMS={250} className='trade_offer_window_accepted' isOpen={isOpen} onRequestClose={onClose} style={customAlertStyles}>
        <div style={{padding:'20px',display:(trade_accepted?'block':'none')}}>
          <img src={require('../images/karambit_tt.png')} style={{position:'absolute',rotate:'-45deg',height:'155px',left:'-75px',top:'-65px'}}></img>
          <img src={require('../images/deagle_ps.webp')} style={{position:'absolute',rotate:'45deg',height:'130px',right:'-55px',bottom:'-45px'}}></img>
          
          <h5 style={{margin:'0px',marginTop:'10px',fontSize:'17px'}}><center>Thank you for trading on</center></h5>
          <center><img src={require('../images/toptradegg.png')} style={{height:'45px'}}></img></center>


          <center className='mt-5'><button onClick={() => (setOpenTrade(false))} className='mt-1 mx-1 yellow-hover-button' style={{border:'none',backgroundColor:'#ffca4f',borderRadius:'4px',fontSize:'17px',padding:'5px 25px',fontWeight:'500'}}>Continue trading</button>
          
          <button onClick={() => window.open('https://www.trustpilot.com/review/toptrade.gg', '_blank')} className='mt-1 mx-1 trustpilot-hover-button' style={{border:'none',backgroundColor:'#00b67a',borderRadius:'4px',fontSize:'17px',padding:'5px 25px',fontWeight:'500'}}>Leave us a Review!</button>
          </center>
          
          <center style={{position:'relative',top:'-25px'}}><ConfettiExplosion zIndex={5000} width={1500} duration={3000} colors={['#ffca4f','#dcdcdc','#33353F']} particleCount={200}/></center>
        </div>
    </Modal>
  );
};