import React from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import '../Css/Item.css';
import LockIcon from '@mui/icons-material/Lock';
import PopupWindow from './PopupWindow';
import WarningIcon from '@mui/icons-material/Warning';

export default function User_item({uInvClickAbove,checkPosition,closeOtherCards,userID,currency,overlay,language,user_offer_value,set_user_offer_value,index,set_user_items_trade,user_items_trade,item,user_items,set_user_items,refreshingUserInv,noItems,addPopup}) {

    const item_price = item[9]

    function display(){
        user_items.splice(index,1)
        set_user_items_trade(user_items_trade => [...user_items_trade,item])
        set_user_offer_value(user_offer_value + item_price)
        hidePopUp()
    }

    var stattrak = false
    var souvenir = false
    if (item[4].includes('StatTrak')) {
        var stattrak = true
    } else if (item[4].includes('Souvenir')) {
        var souvenir = true
    }

    function showPopUp() {
        const name = 'popUpU'+index;        
        if (document.getElementById(name).style.display == 'none'){
            document.getElementById(name).style.display = 'block';
            document.getElementById(cardId()).style.backgroundColor = '#3F414D';
            document.getElementById(buttonID()).style.backgroundColor = '#ffca4f';
            document.getElementById(cartID()).style.color = '#302E3A';
    
            document.getElementById('popUpUImage' + index).style.position = 'relative';
            document.getElementById('popUpUImage' + index).style.transform = 'rotate(-1deg) scale(1.025)';
            document.getElementById('popUpUImage' + index).style.transition = 'transform 0.3s';
            document.getElementById('popUpUClickIcon' + index).style.display = 'block';
        } else {
            hidePopUp()
        }
    }

    function hidePopUp() {
        const name = 'popUpU' + index;
        const popupElement = document.getElementById(name); 

        if (popupElement !== null) {
            popupElement.style.display = 'none';
        }
    
        const cardElement = document.getElementById(cardId());
        if (cardElement !== null) {
            cardElement.style.backgroundColor = '#24252E';
        }
    
        const buttonElement = document.getElementById(buttonID());
        if (buttonElement !== null) {
            buttonElement.style.backgroundColor = '#333540';
        }
    
        const cartElement = document.getElementById(cartID());
        if (cartElement !== null) {
            cartElement.style.color = '#7F808E';
        }
    
        const imageElement = document.getElementById('popUpUImage' + index);
        if (imageElement !== null) {
            imageElement.style.position = 'relative';
            imageElement.style.transform = 'rotate(+1deg) scale(1.0)';
            imageElement.style.transition = 'transform 0.3s';
        }
    
        const clickIconElement = document.getElementById('popUpUClickIcon' + index);
        if (clickIconElement !== null) {
            clickIconElement.style.display = 'none';
        }
    }

    function cardId(){
        return 'userCardU'+index
    }

    function buttonID(){
        return 'userCardButtonU'+index
    }

    function cartID(){
        return 'userCardCartU'+index
    }

    function adjustWear(float){ 
        if (parseFloat(float) < 0.07){
            return 'FN' +' / ' + float
        } else if (parseFloat(float) < 0.15 && parseFloat(float) >= 0.07){ 
            return 'MW'+' / ' + float
        } else if (parseFloat(float) < 0.38 && parseFloat(float) >= 0.15){
            return 'FT'+' / ' + float
        } else if (parseFloat(float) < 0.45 && parseFloat(float) >= 0.38){
            return 'WW'+' / ' + float
        } else if (parseFloat(float) >= 0.45){
            return 'BS'+' / ' + float
        } else {
            return float
        }
    }
    function checkSticker(item){
        if (item != [] && item != undefined){
            if (item.length == 0 || refreshingUserInv == true || item['marketHashName'] == null){
                return ''
            } else {
                return 'https://community.akamai.steamstatic.com/economy/image/'+item['image']
            }
        } else {
            return ''
        }  
    }

    const handleRightClick = (event) => {
        event.preventDefault();
    };

    const handleLeftClick = (event) => {
    event.preventDefault();
    checkPosition(event);
    };

    const numberOfStickers=(item[12].length == 0?0:1) + (item[13].length == 0?0:1) + (item[14].length == 0?0:1) + (item[15].length == 0?0:1) + (item[16].length == 0?0:1)
    const CardstickerNumber = [1,2,3,4,5].slice(0, numberOfStickers)

    const displayDisabledInfo = () => {
        const disabledInfo = document.getElementById(index+'disabled_info');
        if (disabledInfo.style.display == 'none' && disabledInfo){
            disabledInfo.style.display = 'block';
        }
    }

    const displayDisabledInfoLeave = () => {
        const disabledInfo = document.getElementById(index+'disabled_info');
        if (disabledInfo.style.display == 'block' && disabledInfo){
            disabledInfo.style.display = 'none';
        }
    }

    function adjustPopUp(){
        if (document.getElementById('popUpU'+index) !== null){
            // if hovering over different card, close other popups
            if (document.getElementById('popUpU'+index).style.display == 'none'){
                closeOtherCards('user')
            }
        }
    }

    console.log(item)

    const user_ref = React.useRef(null);
    return (
        <div className="col-3 gx-1 mb-1 hovereff-u">
            {item[9] != null?

        <div>
            <Card style={{visibility:(item[0] =='' && item[9] == 0?"hidden":'')}} onMouseOver={adjustPopUp} onContextMenu={handleRightClick} onClick={handleLeftClick} className={"userCard"} id={cardId()} onMouseLeave={hidePopUp}>     

            {refreshingUserInv == false
            ?
            <Card.Text onClick={showPopUp} style={{position:'absolute'}}>{parseInt(item[7]) == 0?<a className="lockedItems" style={{textDecoration:'none',color:'#7F808E',fontSize:'12px',top:'0px',left:'4px',opacity:'0.4'}}><LockIcon style={{width:'13',height:'13',marginBottom:'3px',position:'relative',marginRight:'3px',fontWeight:'400',marginLeft:'1px'}}/>Trade Locked</a>:<></>}</Card.Text>
            :
            <div className="lockedItems loading" style={{width:'25%',height:'10%',backgroundColor:'#3C3F4A',color:'transparent',position:'absolute',left:'5%',top:'5%'}}></div>
            }

            

            {refreshingUserInv == false
            ?
            <center className="next_to_image_filler" onClick={showPopUp}>{parseInt(item[7]) == 0?<Card.Img id={'popUpUImage'+index} style={{opacity:'0.4'}} className="userCardImage" variant="top" src={"https://steamcommunity-a.akamaihd.net/economy/image/"+item[2]} loading="lazy"/>:<Card.Img id={'popUpUImage'+index} className="userCardImage" variant="top" src={"https://steamcommunity-a.akamaihd.net/economy/image/"+item[2]} loading="lazy"/>}</center>
            :
            <div className="next_to_image_filler"><div className="userCardImage" variant="top" loading="lazy"/></div>
            }

            {checkSticker(item[12]) != ''?<img style={{opacity:(item[9] == null | parseInt(item[7]) == 0?'0.4':'1')}} className={'Cardsticker'+CardstickerNumber.shift()} src={checkSticker(item[12])}></img>:<img className='CardstickerHide' src={checkSticker(item[12])}></img>}
            {checkSticker(item[13]) != ''?<img style={{opacity:(item[9] == null | parseInt(item[7]) == 0?'0.4':'1')}} className={'Cardsticker'+CardstickerNumber.shift()} src={checkSticker(item[13])}></img>:<img className='CardstickerHide' src={checkSticker(item[13])}></img>}
            {checkSticker(item[14]) != ''?<img style={{opacity:(item[9] == null | parseInt(item[7]) == 0?'0.4':'1')}} className={'Cardsticker'+CardstickerNumber.shift()} src={checkSticker(item[14])}></img>:<img className='CardstickerHide' src={checkSticker(item[14])}></img>}
            {checkSticker(item[15]) != ''?<img style={{opacity:(item[9] == null | parseInt(item[7]) == 0?'0.4':'1')}} className={'Cardsticker'+CardstickerNumber.shift()} src={checkSticker(item[15])}></img>:<img className='CardstickerHide' src={checkSticker(item[15])}></img>}
            {checkSticker(item[16]) != ''?<img style={{opacity:(item[9] == null | parseInt(item[7]) == 0?'0.4':'1')}} className={'Cardsticker'+CardstickerNumber.shift()} src={checkSticker(item[16])}></img>:<img className='CardstickerHide' src={checkSticker(item[16])}></img>}

            
            <Card.Body className="userCardBody">
                
                {refreshingUserInv == false
                ?
                <Card.Text onClick={showPopUp} className="userCardText" style={{color:'#7F808E',opacity:parseInt(item[7]) == 0?'0.4':'1'}}>
                {stattrak?<div className="last-letter-color">S {adjustWear(Math.round(parseFloat(item[11]) * 10000) / 10000)}</div>:(souvenir?<div className="last-letter-color-souvenir">S {adjustWear(Math.round(parseFloat(item[11]) * 10000) / 10000)}</div>:<div>{adjustWear(Math.round(parseFloat(item[11]) * 10000) / 10000)}</div>)}
                </Card.Text>
                :
                <div><div className="userCardText loading" style={{height:'40%',width:'75%',backgroundColor:'#3C3F4A',color:'transparent'}}>loading</div><br></br></div>
                }

                {refreshingUserInv == false
                ?
                <Card.Title onClick={showPopUp} className="userCardTitle" style={{color:'#dcdcdc',position:'relative',fontWeight:'bold',opacity:parseInt(item[7]) == 0?'0.4':'1'}}>{currency == '$ USD'?<div>$ {item_price.toFixed(2)}</div>:<div>€ {(item_price*0.92).toFixed(2)}</div>}<img id={'popUpUClickIcon' + index} className="right-click-icon-user" src={require('../images/r-click.png')}></img></Card.Title>
                :
                <div className="userCardTitle loading" style={{width:'100%',backgroundColor:'#3C3F4A',color:'transparent',height:'30%'}}>loading</div>
                }
                
                {refreshingUserInv == false
                ?
                <center>
                    {parseInt(item[7]) == 0?<Button className="userCardButton" id={buttonID()} style={{opacity:'0.4'}}>{<a id={cartID()} className="cart" style={{color:'#7F808E',height:'15px',position:'relative',top:'-7px',textDecoration:'none'}}><LockIcon style={{fontSize:"16px"}}/></a>}</Button>:(overlay?<Button id={buttonID()} className="userCardButton" ><ShoppingCartIcon  id={cartID()} className="cart" style={{color:'#7F808E',height:'15px',position:'relative',top:'-7px'}}/></Button>:<Button id={buttonID()} className="userCardButton" onClick={display} ><ShoppingCartIcon id={cartID()} className="cart" style={{color:'#7F808E',height:'15px',position:'relative',top:'-8px'}}/></Button>)}
                </center>
                :
                <div></div>}
            </Card.Body>
        </Card>
        <PopupWindow uInvClickAbove={uInvClickAbove} addPopup={addPopup} hidePopUp={hidePopUp} showPopUp={showPopUp} language={language} userID={userID} overlay={overlay} index={index} item={item} calledFrom={'user'}/>
        </div>
        
        :
        
        (refreshingUserInv == false?
        <Card id={cardId()} className="userCardDisabled defaultCursor" style={{position:'relative'}} onMouseOver={adjustPopUp} onMouseLeave={hidePopUp}>
            <Card.Text className="defaultCursor" style={{position:'absolute'}}>{parseInt(item[7]) == 0?<a className="lockedItems" style={{textDecoration:'none',color:'#7F808E',fontSize:'0.85rem',top:'1px',left:'4px',opacity:'0.75'}}><LockIcon style={{width:'13',height:'13',marginBottom:'3px',position:'relative',marginRight:'3px',fontWeight:'400',marginLeft:'1px'}}/>Trade Locked</a>:<></>}</Card.Text>
            <center><Card.Img style={{opacity:'0.4'}} className="userCardImage defaultCursor" variant="top" src={"https://steamcommunity-a.akamaihd.net/economy/image/"+item[2]} loading="lazy"/></center>
            <Card.Body className="userCardBody defaultCursor">
                <Card.Text className="userCardText defaultCursor" style={{color:'#7F808E',opacity:'0.4'}}>
                {stattrak?<div className="last-letter-color">S {adjustWear(Math.round(parseFloat(item[11]) * 10000) / 10000)}</div>:<div>{adjustWear(Math.round(parseFloat(item[11]) * 10000) / 10000)}</div>}
                </Card.Text>
                    <Card.Title onClick={showPopUp} className="userCardTitle" style={{color:'#dcdcdc',position:'relative',fontWeight:'bold',opacity:'0.4',cursor:'default'}}>Not Accepted</Card.Title>

                    <Button id={buttonID()} style={{position:'relative',opacity:'0.4'}} className="userCardButton userCardButtonDisabled defaultCursor">{<a id={cartID()} className="cart" style={{color:'#7F808E',height:'15px',position:'relative',top:'-6px',textDecoration:'none'}}><WarningIcon className="disabled_item_icon" style={{fontSize:"1.1rem",position:'relative',top:'-2px'}} onMouseOver={displayDisabledInfo} onMouseLeave={displayDisabledInfoLeave}/></a>}</Button>
            </Card.Body>
            <div className="disabled_item" id={index+'disabled_info'} style={{display:'none',zIndex:'1',position:'absolute',color:'#dcdcdc'}}><center>This item can not be traded due to reasons such as: low liquidity, unstable price or being valued under $ 0.5</center></div>
        </Card>
        :
        ''
        )
        }
        </div>
    );
}