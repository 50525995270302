import React from "react";
import CompareArrowsIcon from '@mui/icons-material/CompareArrows';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import '../Css/Main_app.css';
import InfoIcon from '@mui/icons-material/Info';

export default function Transaction_block({item,index,userAvatar,addPopup}){

    function displayTradeContent(div_id,state){
        const element = document.getElementById(div_id);
        if (element && state == 'open') {
            element.style.display = 'block';
        } else if (element && state == 'close') {
            element.style.display = 'none';
        }
    }
   
    if (item[1] == "ref-withdraw"){
        return(
            <div>
                <div className="row mt-2" style={{backgroundColor:'#333540',borderRadius:'3px'}}>
                    <div className="col-6 col-sm-3 col-md-6 col-lg-2 defaultCursor" style={{color:'#dcdcdc',padding:'15px',fontSize:'1rem',position:'relative'}}><InfoIcon onMouseOver={() => (displayTradeContent(index+'id','open'))} onClick={() => (navigator.clipboard.writeText(item[0]),addPopup('Transaction ID coppied to clipboard!'))} onMouseLeave={() => displayTradeContent(index+'id','close')} style={{height:'1rem',color:'#7F808E',cursor:'pointer',position:'absolute',left:'-0px',top:'20px'}}/><div style={{marginLeft:'10px'}}>Withdraw</div><div className="transaction-id" id={index+'id'} style={{display:'none',width:'auto',position:'absolute',backgroundColor:'#181A1F',top:'-28px',opacity:'0.9',borderRadius:'3px',padding:'10px 7px',zIndex:'2',color:'#7F808E',left:'0px'}}>ID: {item[0]}</div></div>
                    <div className="col-6 col-sm-3 col-md-6 col-lg-4 defaultCursor" style={{marginTop:'5px',paddingLeft:'6px'}}><img style={{height:'2.7rem',width:'2.7rem',borderRadius:'3px',filter: 'drop-shadow(4px 4px 4px transparent)'}} src={userAvatar}></img> <ArrowRightAltIcon style={{rotate:'180deg',color:'#7F808E'}}/> <a style={{color:'#dcdcdc',fontSize:'1rem',marginTop:'5px'}}>$ {item[2]} - Refferal</a></div>
                    <div className="col-6 col-sm-3 col-md-6 col-lg-3 defaultCursor" style={{color:'#dcdcdc',padding:'15px',fontSize:'1rem'}}>{item[3]}</div>
                    <div className="col-6 col-sm-3 col-md-6 col-lg-3 defaultCursor" style={{color:(item[4] == 'success'?'#81BD1B':'#F96868'),padding:'15px'}}><center>{(item[4] == 'success'?'Accepted':'Declined')}</center></div>
                </div>
            </div>
        )
    } else if (item[1] == "trade"){
        return (
            <div>
                <div className="row mt-2" style={{backgroundColor:'#333540',borderRadius:'3px'}}>
                    <div className="col-6 col-sm-3 col-md-6 col-lg-2 defaultCursor" style={{color:'#dcdcdc',padding:'15px',fontSize:'1rem',position:'relative'}}><InfoIcon onMouseOver={() => (displayTradeContent(index+'id','open'))} onClick={() => (navigator.clipboard.writeText(item[0]),addPopup('Transaction ID coppied to clipboard!'))} onMouseLeave={() => displayTradeContent(index+'id','close')} style={{height:'1rem',color:'#7F808E',cursor:'pointer',position:'absolute',left:'-0px',top:'20px'}}/><div style={{marginLeft:'10px'}}>Trade</div><div className="transaction-id" id={index+'id'} style={{display:'none',width:'auto',position:'absolute',backgroundColor:'#181A1F',top:'-28px',opacity:'0.9',borderRadius:'3px',padding:'10px 7px',zIndex:'2',color:'#7F808E',left:'0px'}}>ID: {item[0]}</div></div>

                    <div className="col-6 col-sm-3 col-md-6 col-lg-4" style={{padding:'5px'}}>
                        
                    <div onMouseLeave={() => displayTradeContent(index+'sent','close')} onMouseOver={() => displayTradeContent(index+'sent','open')} style={{height:'2.7rem',width:'2.7rem',borderRadius:'3px',backgroundColor:'#24252E',float:'left',marginRight:'5px',cursor:'pointer'}}><center style={{color:'#dcdcdc',position:'relative',top:'0.5rem'}}>+{item[2][0].length}</center><div id={index+'sent'} className="transaction_trade_details" style={{display:'none',position:'relative',backgroundColor:'#181A1F',opacity:'0.9',borderRadius:'3px',padding:'10px 7px',top:'25px',zIndex:'2'}}>{item[2][0].map((item) => (item.length == 2?<div><a style={{color:'#7F808E',margin:'3px'}}>{item[0]}<a style={{color:'#dcdcdc',float:'right'}}> $ {item[1]}</a></a><br></br></div>:<div><a style={{color:'#dcdcdc',margin:'3px'}}>$ {item[0]} - Balance</a></div>))}</div></div>
                    

                    <CompareArrowsIcon style={{rotate:'180deg',color:'#7F808E',float:'left',marginTop:'10px'}}/> 
                    
                    <div onMouseLeave={() => displayTradeContent(index+'received','close')} onMouseOver={() => displayTradeContent(index+'received','open')} style={{height:'2.7rem',width:'2.7rem',borderRadius:'3px',backgroundColor:'#24252E',float:'left',marginLeft:'5px',cursor:'pointer'}}><center style={{color:'#dcdcdc',position:'relative',top:'0.5rem'}}>+{item[2][1].length}</center><div id={index+'received'} className="transaction_trade_details" style={{display:'none',position:'relative',backgroundColor:'#181A1F',opacity:'0.9',borderRadius:'3px',padding:'10px 7px',top:'25px',zIndex:'2'}}>{item[2][1].map((item) => (item.length == 2?<div><a style={{color:'#7F808E',margin:'3px'}}>{item[0]}<a style={{color:'#dcdcdc',float:'right'}}> $ {item[1]}</a></a><br></br></div>:<div><a style={{color:'#dcdcdc',margin:'3px'}}>$ {item[0]} - Balance</a></div>))}</div></div>
                    
                    </div>
                    
                    
                    <div className="col-6 col-sm-3 col-md-6 col-lg-3 defaultCursor" style={{color:'#dcdcdc',padding:'15px',fontSize:'1rem'}}>{item[3]}</div>
                    <div className="col-6 col-sm-3 col-md-6 col-lg-3 defaultCursor" style={{color:(item[4] == 'success'?'#81BD1B':'#F96868'),padding:'15px'}}><center>{(item[4] == 'success'?'Accepted':'Declined')}</center></div>
                </div>
            </div>
        )
    } else if (item[1] == "deposit"){
        return(
            <div>
                <div className="row mt-2" style={{backgroundColor:'#333540',borderRadius:'3px'}}>
                    <div className="col-6 col-sm-3 col-md-6 col-lg-2 defaultCursor" style={{color:'#dcdcdc',padding:'15px',fontSize:'1rem',position:'relative'}}><InfoIcon onMouseOver={() => (displayTradeContent(index+'id','open'))} onClick={() => (navigator.clipboard.writeText(item[0]),addPopup('Transaction ID coppied to clipboard!'))} onMouseLeave={() => displayTradeContent(index+'id','close')} style={{height:'1rem',color:'#7F808E',cursor:'pointer',position:'absolute',left:'-0px',top:'20px'}}/><div style={{marginLeft:'10px'}}>Deposit</div><div className="transaction-id" id={index+'id'} style={{display:'none',width:'auto',position:'absolute',backgroundColor:'#181A1F',top:'-28px',opacity:'0.9',borderRadius:'3px',padding:'10px 7px',zIndex:'2',color:'#7F808E',left:'0px'}}>ID: {item[0]}</div></div>
                    <div className="col-6 col-sm-3 col-md-6 col-lg-4 defaultCursor" style={{padding:'15px'}}><a style={{color:'#dcdcdc',fontSize:'1rem'}}>$ {item[2].split('-')[0]}</a><a style={{color:'#7F808E',fontSize:'1rem'}}> - {item[2].split('-')[1]}</a></div>
                    <div className="col-6 col-sm-3 col-md-6 col-lg-3 defaultCursor" style={{color:'#dcdcdc',padding:'15px',fontSize:'1rem'}}>{item[3]}</div>
                    <div className="col-6 col-sm-3 col-md-6 col-lg-3 defaultCursor" style={{color:(item[4] == 'success'?'#81BD1B':'#F96868'),padding:'15px'}}><center>{(item[4] == 'success'?'Accepted':'Declined')}</center></div>
                </div>
            </div>
        )
    } else if (item[1] == 'skin-withdraw'){
        return (
            <div>
                <div className="row mt-2" style={{backgroundColor:'#333540',borderRadius:'3px'}}>
                    <div className="col-6 col-sm-3 col-md-6 col-lg-2 defaultCursor" style={{color:'#dcdcdc',padding:'15px',fontSize:'1rem',position:'relative'}}><InfoIcon onMouseOver={() => (displayTradeContent(index+'id','open'))} onClick={() => (navigator.clipboard.writeText(item[0]),addPopup('Transaction ID coppied to clipboard!'))} onMouseLeave={() => displayTradeContent(index+'id','close')} style={{height:'1rem',color:'#7F808E',cursor:'pointer',position:'absolute',left:'-0px',top:'20px'}}/><div style={{marginLeft:'10px'}}>Withdraw</div><div className="transaction-id" id={index+'id'} style={{display:'none',width:'auto',position:'absolute',backgroundColor:'#181A1F',top:'-28px',opacity:'0.9',borderRadius:'3px',padding:'10px 7px',zIndex:'2',color:'#7F808E',left:'0px'}}>ID: {item[0]}</div></div>
                    <div className="col-6 col-sm-3 col-md-6 col-lg-4 defaultCursor" style={{marginTop:'5px',paddingLeft:'6px'}}><img style={{height:'2.7rem',width:'2.7rem',borderRadius:'3px',filter: 'drop-shadow(4px 4px 4px transparent)',float:'left',marginRight:'5px'}} src={userAvatar}></img> 
                    
                    <ArrowRightAltIcon style={{rotate:'180deg',color:'#7F808E',float:'left',marginTop:'10px'}}/>
                    
                     <div onMouseLeave={() => displayTradeContent(index+'sent','close')} onMouseOver={() => displayTradeContent(index+'sent','open')} style={{height:'2.7rem',width:'2.7rem',borderRadius:'3px',backgroundColor:'#24252E',float:'left',marginRight:'5px',cursor:'pointer',marginLeft:'5px'}}><center style={{color:'#dcdcdc',position:'relative',top:'0.5rem'}}>+{item[2].length}</center><div id={index+'sent'} className="transaction_trade_details" style={{display:'none',position:'relative',backgroundColor:'#181A1F',opacity:'0.9',borderRadius:'3px',padding:'10px 7px',top:'25px',zIndex:'2'}}>{item[2].map((item) => (<div><a style={{color:'#7F808E',margin:'3px'}}>{item[0]}<a style={{color:'#dcdcdc',float:'right'}}> $ {item[1]}</a></a><br></br></div>))}</div></div></div>
                    <div className="col-6 col-sm-3 col-md-6 col-lg-3 defaultCursor" style={{color:'#dcdcdc',padding:'15px',fontSize:'1rem'}}>{item[3]}</div>
                    <div className="col-6 col-sm-3 col-md-6 col-lg-3 defaultCursor" style={{color:(item[4] == 'success'?'#81BD1B':'#F96868'),padding:'15px'}}><center>{(item[4] == 'success'?'Accepted':'Declined')}</center></div>
                </div>
            </div>
        )
    } else if (item[1] == 'email-verification'){
        return(
            <div>
                <div className="row mt-2" style={{backgroundColor:'#333540',borderRadius:'3px'}}>
                    <div className="col-6 col-sm-3 col-md-6 col-lg-2 defaultCursor" style={{color:'#dcdcdc',padding:'15px',fontSize:'1rem',position:'relative'}}><InfoIcon onMouseOver={() => (displayTradeContent(index+'id','open'))} onClick={() => (navigator.clipboard.writeText(item[0]),addPopup('Transaction ID coppied to clipboard!'))} onMouseLeave={() => displayTradeContent(index+'id','close')} style={{height:'1rem',color:'#7F808E',cursor:'pointer',position:'absolute',left:'-0px',top:'20px'}}/><div style={{marginLeft:'10px'}}>Verification</div><div className="transaction-id" id={index+'id'} style={{display:'none',width:'auto',position:'absolute',backgroundColor:'#181A1F',top:'-28px',opacity:'0.9',borderRadius:'3px',padding:'10px 7px',zIndex:'2',color:'#7F808E',left:'0px'}}>ID: {item[0]}</div></div>
                    <div className="col-6 col-sm-3 col-md-6 col-lg-4 defaultCursor" style={{padding:'15px'}}><a className="email_transaction" style={{color:'#dcdcdc',fontSize:'1rem',textDecoration:'none'}}>{item[2]}</a></div>
                    <div className="col-6 col-sm-3 col-md-6 col-lg-3 defaultCursor" style={{color:'#dcdcdc',padding:'15px',fontSize:'1rem'}}>{item[3]}</div>
                    <div className="col-6 col-sm-3 col-md-6 col-lg-3 defaultCursor" style={{color:(item[4] == 'success'?'#81BD1B':'#F96868'),padding:'15px'}}><center>{(item[4] == 'success'?'Accepted':'Declined')}</center></div>
                </div>
            </div>
        )
    }
}