import React from 'react';
import '../Css/Main_app.css';
import CallMadeIcon from '@mui/icons-material/CallMade';
import LabelIcon from '@mui/icons-material/Label';
import Language_wrapper from './Language_wrapper';
import LockIcon from '@mui/icons-material/Lock';
import BoltIcon from '@mui/icons-material/Bolt';

export default function PopupWindow({showPopUp,bInvClickAbove,userID,language,index,item,calledFrom,overlay,addPopup,uInvClickAbove}) {
    var color = '#'

    if (parseString(item[5]) == 'Consumer Grade') {
        var color = '#D0CFCD'
    } else if (parseString(item[5]) == 'Industrial Grade') {
        var color = '#AEDDFF'
    } else if (parseString(item[5]) == 'Mil-Spec') {
        var color = '#55B8FF'
    } else if (parseString(item[5]) == 'Restricted') {
        var color = '#D377FF'
    } else if (parseString(item[5]) == 'Classified') {
        var color = '#FF68F0'
    } else if (parseString(item[5]) == 'Covert') {
        var color = '#FF7878'
    } else if (parseString(item[5]) == 'Contraband') {
        var color = '#FFC978'
    } else if (parseString(item[5]) == 'Extraordinary') {
        var color = '#FFC978'
    }

    function parseString(inputString) {
        const wordsToFind = ['Consumer', 'Contraband', 'Industrial', 'Mil-Spec', 'Restricted', 'Classified', 'Covert','Extraordinary'];
      
        const foundWords = [];
        
        try{
            wordsToFind.forEach(word => {
                const regex = new RegExp('\\b' + word + '\\b', 'i'); // 'i' flag for case-insensitive matching
                if (inputString.match(regex)) {
                  foundWords.push(word);
                }
            });
        } catch (err) {
            foundWords.push('NaN');
        }
       
      
        return foundWords;
    }

    function removeSpecificWordsFromString(inputString) {
        const wordsToRemove = ['Consumer Grade', 'Exclude', 'Industrial Grade', 'Mil-Spec Grade', 'Restricted', 'Classified', 'Covert', 'Extraordinary'];
      
        wordsToRemove.forEach(word => {
          const regex = new RegExp('\\b' + word + '\\b', 'gi'); // 'gi' flags for global and case-insensitive matching
          inputString = inputString.replace(regex, '');
        });
      
        // Trim any extra spaces that might have been created due to replacements
        inputString = inputString.trim();
      
        return inputString;
      }
    
    function id(){
        if (calledFrom == 'user') {
            return 'popUpU'+index
        } else if (calledFrom == 'user-O') {
            return 'popUpU'+index+index
        } else if (calledFrom == 'bot') {
            return 'popUp'+index
        } else if (calledFrom == 'bot-O') {
            return 'popUp'+index+index
        }
    }

    const float = parseFloat(item[11]).toFixed(8)

    function float_arrow(){
        if (float > 0.5){
            return ((float-0.5)*100)+'%'
        } else if (float < 0.5){
            return -((0.5-float)*100)+'%'
        } else {
            return '0%'
        }
    }

    function steamLink_bot(){
        return 'https://steamcommunity.com/profiles/76561199511923413/inventory#730_2_'+item[1]
    }

    function steamLink_user(){
        return 'https://steamcommunity.com/profiles/'+userID+'/inventory#730_2_'+item[1]
    }

      function checkSticker(item){
        if (item != [] && item != undefined){
            if (item.length == 0 || item['marketHashName'] == null){
                return ''
            } else {
                return 'https://community.akamai.steamstatic.com/economy/image/'+item['image']
            }
        } else {
            return ''
        }
      }

      function floatAddedPrice(item){
        // if (float > 0 && float <= 0.07 && float / 0.025 < 1) {
        //     return (item[9] * 0.02).toFixed(2);
        //   } else if (float > 0.07 && float <= 0.15 && float / 0.085 < 1) {
        //     return (item[9] * 0.025).toFixed(2);
        //   }else if (float > 0.15 && float <= 0.38 && float / 0.18 < 1) {
        //     return (item[9] * 0.025).toFixed(2);
        //   } else if (float > 0.38 && float <= 0.45 && float / 0.39 < 1) {
        //     return (item[9] * 0.02).toFixed(2);
        //   } else if (float > 0.45 && float <= 1 && float / 0.48 < 1) {
        //     return (item[9] * 0.015).toFixed(2);
        //   } else{
        //     return (0).toFixed(2);
        //   }

        return (0).toFixed(2);
      }

      function setExteriorByFloat(item){
        if (parseFloat(item[11]) < 0.07){
            return 'Factory New'
        } else if (parseFloat(item[11]) < 0.15 && parseFloat(item[11]) > 0.07){
            return 'Minimal Wear'
        } else if (parseFloat(item[11]) < 0.38 && parseFloat(item[11]) > 0.15){
            return 'Field-Tested'
        } else if (parseFloat(item[11]) < 0.45 && parseFloat(item[11]) > 0.38){
            return 'Well-Worn'
        } else if (parseFloat(item[11]) < 1 && parseFloat(item[11]) > 0.45){
            return 'Battle-Scarred'
        }
    }

    function getDaysLeft(dateString) {
        // Parse the given date string into a Date object
        const targetDate = new Date(dateString);
      
        // Get the current date
        const currentDate = new Date();
      
        // Calculate the difference between the two dates in milliseconds
        const timeDifference = targetDate.getTime() - currentDate.getTime();
      
        // Convert the milliseconds difference to days
        const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      
        return daysLeft;
    }

    return (
        calledFrom.includes('bot')?
        (overlay == false)?
        <div className="popUp" style={{display:'none'}} id={id()} onMouseEnter={showPopUp}>
        <div className={bInvClickAbove?'popup-transparent-padding':'popup-transparent-padding-2'}>
        <div className="popup-content">
        <div style={{display:'block',width:'100%'}}>
            <h6 className='defaultCursor' style={{color:'#7F808E',margin:'0px',fontSize:'small',width:'100%'}}>{item[4].split('|')[0]}</h6>
        </div>
        <div style={{ display: 'flex', width:'100%' }}>
            <h5 className='weaponName defaultCursor' style={{ color: '#f0f0f0', float: 'left'}}>
                {item[4].split('|')[1] == null?'Vanilla':item[4].split('|')[1]}
            </h5>               
        </div>
        <div style={{ display: 'flex', width:'100%' }}>
        {getDaysLeft(item[8]) <= 0 ? (
                ''
            ) : (
                <h6 className='defaultCursor' style={{ color: '#DBBD51', fontSize: 'x-small', backgroundColor:'#A19184',height:'50%',borderRadius:'3px',marginBottom:'0px'}}>
                <LockIcon className='lockIcon'/>Trade Lock
                </h6>
            )}            
        </div>
        
            {/* Popup content goes here */}
            <div className='row mt-1' style={{width:'100%'}}>
                <div className="col-12 popup_col px-2">
                    <h6 className='itemDescriptionRightCol defaultCursor'><Language_wrapper string_in_slovak={'Kvalita'} string_in_english={'Exterior'} string_in_russian={'Внешний вид'} string_in_german={'Außen'} language={language}/> </h6><p className='itemDescriptionLeftCol defaultCursor' style={{color:' #dcdcdc'}}> {setExteriorByFloat(item)}</p>
                </div>
                {/* <div className="col-12 popup_col px-2">
                    <h6 className='itemDescriptionRightCol defaultCursor'><Language_wrapper string_in_english={'Type'} string_in_russian={'Тип'} string_in_german={'Typ'} language={language}/> </h6><p className='itemDescriptionLeftCol defaultCursor' style={{color:' #dcdcdc'}}> {removeSpecificWordsFromString(item[5])}</p>
                </div> */}
                <div className="col-12 popup_col px-2">
                    <h6 className='itemDescriptionRightCol defaultCursor'><Language_wrapper string_in_slovak={'Rarita'} string_in_english={'Rarity'} string_in_russian={'редкость'} string_in_german={'Seltenheit'} language={language}/> </h6><p className='itemDescriptionLeftCol defaultCursor' style={{color:color}}> {parseString(item[5])[0]}</p>
                </div>
                <div className="col-12 popup_col px-2">
                    <h6 className='itemDescriptionRightCol defaultCursor' ><Language_wrapper string_in_slovak={'Vzor'} string_in_english={'Pattern'} string_in_russian={'Шаблон'} string_in_german={'Muster'} language={language}/> </h6><p className='itemDescriptionLeftCol defaultCursor' style={{color:' #dcdcdc'}}> {item[10]}</p>
                </div>
            </div>

            <div className='pt-2' style={{display:'inline-block',width:'100%'}}>
                <a href={item[3]} className='display_links' style={{float:'left',textDecoration:'none'}}><Language_wrapper string_in_slovak={'V Hre'} string_in_english={'In Game'} string_in_russian={'В игре'} string_in_german={'Im Spiel'} language={language}/><CallMadeIcon style={{fontSize:'15px'}}/></a>
                <a target='_blank' href={steamLink_bot()} className='display_links' style={{float:'right',textDecoration:'none'}}><Language_wrapper string_in_slovak={'V Inventári'} string_in_english={'View in Inventory'} string_in_russian={'В инвентаре'} string_in_german={'Im Inventar'} language={language}/><CallMadeIcon style={{fontSize:'15px'}}/></a>
            </div>
            <center>
                <div style={{backgroundColor:'#484A56',height:'70px',borderRadius:'4px'}}>
                    <div className='float-line' style={{height:'5px',width:'90%',position:'relative',top:'12.5px'}}><LabelIcon style={{position:'relative',top:'-17.5px',left:(float_arrow()),rotate:'90deg',fontSize:'medium',color:'#F4EEE0'}}/></div>

                <div className='row mt-1' style={{width:'100%'}}>
                    <div className="col-12 popup_col px-2"  style={{width:'100%',position:'relative',top:'15px'}}>
                        <h6 className='itemDescriptionRightCol defaultCursor' style={{color:'#dcdcdc',float:'left'}}>Float </h6><p className='itemDescriptionLeftCol defaultCursor' style={{color:' #dcdcdc',marginBottom:'0px'}}> {float}</p>
                    </div>
                    <div className="col-12 popup_col px-2"  style={{width:'100%',position:'relative',top:'15px'}}>
                        <h6 className='defaultCursor floatBonus' style={{color:'#dcdcdc',float:'left',color:'darkgrey'}}><Language_wrapper string_in_slovak={'Float Bonus'} string_in_english={'Float bonus'} string_in_russian={'Float бонус'}   string_in_german={'Float-Bonus'} language={language}/> </h6>{floatAddedPrice(item) == 0.00?<p className='floatBonus defaultCursor' style={{color:'#deb50b',fontSize:'15px',float:'right'}}>+ $ {floatAddedPrice(item)}</p>:<p className='floatBonus defaultCursor' style={{color:'#A5DDA7',fontSize:'15px',float:'right'}}>+ $ {floatAddedPrice(item)}</p>}            
                    </div>
                </div>
                </div>    
                
                <div className='mt-2' style={{width:'100%', display:'grid', paddingLeft:'30px', gridTemplateColumns:'repeat(5, 1fr)', justifyContent:'center'}}>
                    {checkSticker(item[12]) == ''?<div style={{position:'relative'}}>
                    <div className={`sticker`} style={{cursor:'default'}}></div>
                    <div className='behindSticker'><BoltIcon className='sticker-icon'/></div>
                    </div>
                    :
                    <div style={{position:'relative'}} onClick={() => (navigator.clipboard.writeText(item[12]['marketHashName']),addPopup('Sticker name coppied to clipboard!'))}>
                    <img id='bot_sticker1' className={`sticker`} src={checkSticker(item[12])}></img>
                    <div id='bot_sticker1_desc'>{item[12] ?item[12]['marketHashName'].replace(/Sticker \| /, ''):''} - {item[12]['wear'] == null?'100%':Math.round(100-item[12]['wear']*100)+'%'} <a style={{color:'#A5DDA7'}}>{(Math.round(item[12]['price']*100,2)/1000000).toFixed(2) == 0.00 ?'':'+ $'+(Math.round(item[12]['price']*100*5.5,2)/1000000).toFixed(2)}</a></div><div className='behindSticker'></div>
                    </div>}

                    {checkSticker(item[13]) == ''?<div style={{position:'relative'}}>
                    <div className={`sticker`} style={{cursor:'default'}}></div>
                    <div className='behindSticker'><BoltIcon className='sticker-icon'/></div>
                    </div>
                    :
                    <div style={{position:'relative'}} onClick={() => (navigator.clipboard.writeText(item[13]['marketHashName']),addPopup('Sticker name coppied to clipboard!'))}>
                    <img id='bot_sticker2' className={`sticker`} src={checkSticker(item[13])}></img>
                    <div id='bot_sticker2_desc'>{item[13]['marketHashName'].replace(/Sticker \| /, '')} - {item[13]['wear'] == null?'100%':Math.round(100-item[13]['wear']*100)+'%'} <a style={{color:'#A5DDA7'}}>{(Math.round(item[13]['price']*100,2)/1000000).toFixed(2) == 0.00 ?'':'+ $'+(Math.round(item[13]['price']*100*5.5,2)/1000000).toFixed(2)}</a></div><div className='behindSticker'></div>
                    </div>}

                    {checkSticker(item[14]) == ''?<div style={{position:'relative'}}>
                    <div className={`sticker`} style={{cursor:'default'}}></div>
                    <div className='behindSticker'><BoltIcon className='sticker-icon'/></div>
                    </div>
                    :
                    <div style={{position:'relative'}} onClick={() => (navigator.clipboard.writeText(item[14]['marketHashName']),addPopup('Sticker name coppied to clipboard!'))}>
                    <img id='bot_sticker3' className={`sticker`} src={checkSticker(item[14])}></img>
                    <div id='bot_sticker3_desc'>{item[14]['marketHashName'].replace(/Sticker \| /, '')} - {item[14]['wear'] == null?'100%':Math.round(100-item[14]['wear']*100)+'%'} <a style={{color:'#A5DDA7'}}>{(Math.round(item[14]['price']*100,2)/1000000).toFixed(2) == 0.00 ?'':'+ $'+(Math.round(item[14]['price']*100*5.5,2)/1000000).toFixed(2)}</a></div><div className='behindSticker'></div>
                    </div>}

                    {checkSticker(item[15]) == ''?<div style={{position:'relative'}}>
                    <div className={`sticker`} style={{cursor:'default'}}></div>
                    <div className='behindSticker'><BoltIcon className='sticker-icon'/></div>
                    </div>
                    :
                    <div style={{position:'relative'}} onClick={() => (navigator.clipboard.writeText(item[15]['marketHashName']),addPopup('Sticker name coppied to clipboard!'))}>
                    <img id='bot_sticker4' className={`sticker`} src={checkSticker(item[15])}></img>
                    <div id='bot_sticker4_desc'>{item[15]['marketHashName'].replace(/Sticker \| /, '')} - {item[15]['wear'] == null?'100%':Math.round(100-item[15]['wear']*100)+'%'} <a style={{color:'#A5DDA7'}}>{(Math.round(item[15]['price']*100,2)/1000000).toFixed(2) == 0.00 ?'':'+ $'+(Math.round(item[15]['price']*100*5.5,2)/1000000).toFixed(2)}</a></div><div className='behindSticker'></div>
                    </div>}

                    <div className='fifthSticker'>
                    {checkSticker(item[16]) == ''?<div style={{position:'relative'}}>
                    <div className={`sticker`} style={{cursor:'default'}}></div>
                    <div className='behindSticker'><BoltIcon className='sticker-icon'/></div>
                    </div>
                    :
                    <div style={{position:'relative',padding:'0px'}} onClick={() => (navigator.clipboard.writeText(item[16]['marketHashName']),addPopup('Sticker name coppied to clipboard!'))}>
                    <img id='bot_sticker5' className={`sticker`} src={checkSticker(item[16])}></img>
                    <div id='bot_sticker5_desc'>{item[16]['marketHashName'].replace(/Sticker \| /, '')} - {item[16]['wear'] == null?'100%':Math.round(100-item[16]['wear']*100)+'%'} <a style={{color:'#A5DDA7'}}>{(Math.round(item[16]['price']*100,2)/1000000).toFixed(2) == 0.00 ?'':'+ $'+(Math.round(item[16]['price']*100*5.5,2)/1000000).toFixed(2)}</a></div><div className='behindSticker'></div>
                    </div>}
                    </div>
                </div>
            </center>
        </div>
        </div></div>
        :<div className="popUp" style={{display:'none'}} id={id()}></div>
        :
        (overlay == false)?
        <div className="popUp" style={{display:'none'}} id={id()} onMouseEnter={showPopUp}>
            <div className={uInvClickAbove?'popup-transparent-padding-user':'popup-transparent-padding-user-2'}>
                <div className="popup-content-user">
                    <div style={{display:'block',width:'100%'}}>
                        <h6 className='defaultCursor' style={{color:' #7F808E',margin:'0px',fontSize:'small',float:'left',width:'100%'}}>{item[4].split('|')[0]}</h6>
                    </div>

                    <div style={{ display: 'flex', width:'100%' }}>
                        <h5 className='weaponName defaultCursor' style={{ color: '#f0f0f0', float: 'left'}}>
                            {item[4].split('|')[1] == null?'Vanilla':item[4].split('|')[1]}
                        </h5>               
                    </div>
                    <div style={{ display: 'flex', width:'100%' }}>
                    {item[7] !== '0' ? (
                            ''
                        ) : (
                            <h6 className='defaultCursor' style={{ color: '#DBBD51', fontSize: 'x-small', backgroundColor:'#A19184',height:'50%',borderRadius:'3px',marginBottom:'0px'}}>
                            <LockIcon className='lockIcon'/>Trade Lock
                            </h6>
                        )}            
                    </div>
                    {/* Popup content goes here */}
                    <div className='row mt-1' style={{width:'100%'}}>
                        <div className="col-12 popup_col px-2">
                            <h6 className='itemDescriptionRightCol defaultCursor'><Language_wrapper string_in_slovak={'Kvalita'} string_in_english={'Exterior'} string_in_russian={'Внешний вид'} string_in_german={'Außen'} language={language}/> </h6><p className='itemDescriptionLeftCol defaultCursor' style={{color:' #dcdcdc'}}> {setExteriorByFloat(item)}</p>
                        </div>
                        {/* <div className="col-12 popup_col px-2">
                            <h6 className='itemDescriptionRightCol defaultCursor'><Language_wrapper string_in_english={'Type'} string_in_russian={'Тип'} string_in_german={'Typ'} language={language}/> </h6><p className='itemDescriptionLeftCol defaultCursor' style={{color:' #dcdcdc'}}> {removeSpecificWordsFromString(item[5])}</p>
                        </div> */}
                        <div className="col-12 popup_col px-2">
                            <h6 className='itemDescriptionRightCol defaultCursor'><Language_wrapper string_in_slovak={'Rarita'} string_in_english={'Rarity'} string_in_russian={'редкость'} string_in_german={'Seltenheit'} language={language}/> </h6><p className='itemDescriptionLeftCol defaultCursor' style={{color:color}}> {parseString(item[5])[0]}</p>
                        </div>
                        <div className="col-12 popup_col px-2">
                            <h6 className='itemDescriptionRightCol defaultCursor' ><Language_wrapper string_in_slovak={'Vzor'} string_in_english={'Pattern'} string_in_russian={'Шаблон'} string_in_german={'Muster'} language={language}/> </h6><p className='itemDescriptionLeftCol defaultCursor' style={{color:' #dcdcdc'}}> {item[10]}</p>
                        </div>
                    </div>

                    <div className='pt-2' style={{display:'inline-block',width:'100%'}}>
                        <a href={item[3]} className='display_links' style={{float:'left',textDecoration:'none'}}><Language_wrapper string_in_slovak={'V hre'} string_in_english={'In Game'} string_in_russian={'В игре'} string_in_german={'Im Spiel'} language={language}/><CallMadeIcon style={{fontSize:'15px'}}/></a>
                        <a target='_blank' href={steamLink_user()} className='display_links' style={{float:'right',textDecoration:'none'}}><Language_wrapper string_in_slovak={'V inventári'} string_in_english={'View in Inventory'} string_in_russian={'В инвентаре'} string_in_german={'Im Inventar'} language={language}/><CallMadeIcon style={{fontSize:'15px'}}/></a>
                    </div>
                    <center>
                        <div style={{backgroundColor:'#484a56',height:'50px',borderRadius:'4px'}}>
                            <div className='float-line' style={{height:'5px',width:'90%',position:'relative',top:'12.5px'}}><LabelIcon style={{position:'relative',top:'-17.5px',left:(float_arrow()),rotate:'90deg',fontSize:'medium',color:'#F4EEE0'}}/></div>
                            <div style={{width:'90%',position:'relative',top:'15px'}}>
                                <h6 className='itemDescriptionRightCol defaultCursor'style={{color:'#dcdcdc'}}>Float </h6><p className='itemDescriptionLeftCol defaultCursor' style={{color:' #dcdcdc'}}> {float}</p>
                            </div>
                        </div>
                        <div className='mt-2' style={{width:'100%', display:'grid', paddingLeft:'30px', gridTemplateColumns:'repeat(5, 1fr)', justifyContent:'center'}}>
                            {checkSticker(item[12]) == ''?<div style={{position:'relative'}}>
                            <div className={`sticker`} style={{cursor:'default'}}></div>
                            <div className='behindSticker'><BoltIcon className='sticker-icon'/></div>
                            </div>
                            :
                            <div style={{position:'relative'}} onClick={() => (navigator.clipboard.writeText(item[12]['marketHashName']),addPopup('Sticker name coppied to clipboard!'))}>
                            <img id='bot_sticker1' className={`sticker`} src={checkSticker(item[12])}></img>
                            <div id='bot_sticker1_desc'>{item[12]['marketHashName'].replace(/Sticker \| /, '')} - {item[12]['wear'] == null?'100%':Math.round(100-item[12]['wear']*100)+'%'} {(Math.round(item[12]['price']*100,2)/10000).toFixed(2) == 0.00?'':' - '} <a style={{color:'#ffca4f'}}>{(Math.round(item[12]['price']*100,2)/10000).toFixed(2) == 0.00?'':'$ '+(Math.round(item[12]['price']*100,2)/10000).toFixed(2)}</a></div><div className='behindSticker'></div>
                            </div>}

                            {checkSticker(item[13]) == ''?<div style={{position:'relative'}}>
                            <div className={`sticker`} style={{cursor:'default'}}></div>
                            <div className='behindSticker'><BoltIcon className='sticker-icon'/></div>
                            </div>
                            :
                            <div style={{position:'relative'}} onClick={() => (navigator.clipboard.writeText(item[13]['marketHashName']),addPopup('Sticker name coppied to clipboard!'))}>
                            <img id='bot_sticker2' className={`sticker`} src={checkSticker(item[13])}></img>
                            <div id='bot_sticker2_desc'>{item[13]['marketHashName'].replace(/Sticker \| /, '')} - {item[13]['wear'] == null?'100%':Math.round(100-item[13]['wear']*100)+'%'} {(Math.round(item[13]['price']*100,2)/10000).toFixed(2) == 0.00?'':' - '} <a style={{color:'#ffca4f'}}>{(Math.round(item[13]['price']*100,2)/10000).toFixed(2) == 0.00?'':'$ '+(Math.round(item[13]['price']*100,2)/10000).toFixed(2)}</a></div><div className='behindSticker'></div>
                            </div>}

                            {checkSticker(item[14]) == ''?<div style={{position:'relative'}}>
                            <div className={`sticker`} style={{cursor:'default'}}></div>
                            <div className='behindSticker'><BoltIcon className='sticker-icon'/></div>
                            </div>
                            :
                            <div style={{position:'relative'}} onClick={() => (navigator.clipboard.writeText(item[14]['marketHashName']),addPopup('Sticker name coppied to clipboard!'))}>
                            <img id='bot_sticker3' className={`sticker`} src={checkSticker(item[14])}></img>
                            <div id='bot_sticker3_desc'>{item[14]['marketHashName'].replace(/Sticker \| /, '')} - {item[14]['wear'] == null?'100%':Math.round(100-item[14]['wear']*100)+'%'} {(Math.round(item[14]['price']*100,2)/10000).toFixed(2) == 0.00?'':' - '} <a style={{color:'#ffca4f'}} >{(Math.round(item[14]['price']*100,2)/10000).toFixed(2) == 0.00?'':'$ '+(Math.round(item[14]['price']*100,2)/10000).toFixed(2)}</a></div><div className='behindSticker'></div>
                            </div>}

                            {checkSticker(item[15]) == ''?<div style={{position:'relative'}}>
                            <div className={`sticker`} style={{cursor:'default'}}></div>
                            <div className='behindSticker'><BoltIcon className='sticker-icon'/></div>
                            </div>
                            :
                            <div style={{position:'relative'}} onClick={() => (navigator.clipboard.writeText(item[15]['marketHashName']),addPopup('Sticker name coppied to clipboard!'))}>
                            <img id='bot_sticker4' className={`sticker`} src={checkSticker(item[15])}></img>
                            <div id='bot_sticker4_desc'>{item[15]['marketHashName'].replace(/Sticker \| /, '')} - {item[15]['wear'] == null?'100%':Math.round(100-item[15]['wear']*100)+'%'} {(Math.round(item[15]['price']*100,2)/10000).toFixed(2) == 0.00?'':' - '} {(Math.round(item[15]['price']*100,2)/10000).toFixed(2) == 0.00?'':<a style={{color:'#ffca4f'}}>{'$ '+(Math.round(item[15]['price']*100,2)/10000).toFixed(2)}</a>}</div><div className='behindSticker'></div>
                            </div>}

                            <div className='fifthSticker'>
                            {checkSticker(item[16]) == ''?<div style={{position:'relative'}}>
                            <div className={`sticker`} style={{cursor:'default'}}></div>
                            <div className='behindSticker'><BoltIcon className='sticker-icon'/></div>
                            </div>
                            :
                            <div style={{position:'relative'}} onClick={() => (navigator.clipboard.writeText(item[16]['marketHashName']),addPopup('Sticker name coppied to clipboard!'))}>
                            <img id='bot_sticker4' className={`sticker`} src={checkSticker(item[16])}></img>
                            <div id='bot_sticker4_desc'>{item[16]['marketHashName'].replace(/Sticker \| /, '')} - {item[16]['wear'] == null?'100%':Math.round(100-item[16]['wear']*100)+'%'} {(Math.round(item[16]['price']*100,2)/10000).toFixed(2) == 0.00?'':' - '} {(Math.round(item[16]['price']*100,2)/10000).toFixed(2) == 0.00?'':<a style={{color:'#FFD269'}}>{'$ '+(Math.round(item[16]['price']*100,2)/10000).toFixed(2)}</a>}</div><div className='behindSticker'></div>
                            </div>}
                            </div>
                        </div>
                    </center>
                </div>
            </div>
        </div>:<div className="popUp" style={{display:'none'}} id={id()}></div>
        
    );
};