import React from "react";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import DoneIcon from '@mui/icons-material/Done';
import '../Css/Item.css';
import LockIcon from '@mui/icons-material/Lock';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';

export default function User_backpack_item({userID,currency,overlay,language,index,link,item,refreshingBackpack,addPopup,withrawItems, setWithdrawItems}) {

    const item_price = (item[9] != null?item[9]:0)

    var stattrak = false
    if (item[4].includes('StatTrak')) {
        var stattrak = true
    }

    function adjustWear(float){ 
        if (parseFloat(float) < 0.07){
            return 'FN' +' / ' + float
        } else if (parseFloat(float) < 0.15 && parseFloat(float) >= 0.07){ 
            return 'MW'+' / ' + float
        } else if (parseFloat(float) < 0.38 && parseFloat(float) >= 0.15){
            return 'FT'+' / ' + float
        } else if (parseFloat(float) < 0.45 && parseFloat(float) >= 0.38){
            return 'WW'+' / ' + float
        } else if (parseFloat(float) >= 0.45){
            return 'BS'+' / ' + float
        } else {
            return float
        }
    }

    function checkSticker(item){
        if (item.length == 0 || refreshingBackpack == true || item['marketHashName'] == null){
            return ''
        } else {
            return 'https://community.akamai.steamstatic.com/economy/image/'+item['image']
        }
    }

    function parseDateString(input) {
        // Use regular expression to extract date and time components
        const regex = /^(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})$/;
        const matches = input.match(regex);
    
        if (!matches) {
            // Invalid input format, return the original input
            return input;
        }
    
        const [, year, month, day, hour, minute] = matches;
    
        // Convert month and day to numbers and remove leading zeros
        const monthNumber = parseInt(month, 10);
        const dayNumber = parseInt(day, 10);
    
        // Remove leading zero from hour if it exists
        const formattedHour = hour.replace(/^0/, '');
    
        // Create the output string
        const output = `${monthNumber}.${dayNumber}.${year}, ${formattedHour}:${minute}`;
        return output;
    }

    
    function getDaysLeft(dateString) {
        // Parse the given date string into a Date object
        const targetDate = new Date(dateString);
      
        // Get the current date
        const currentDate = new Date();
      
        // Calculate the difference between the two dates in milliseconds
        const timeDifference = targetDate.getTime() - currentDate.getTime();
      
        // Convert the milliseconds difference to days
        const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
      
        return daysLeft;
    }

    const [oldIcon, setOldIcon] = React.useState('block')
    const [newIcon, setNewIcon] = React.useState('none')

    function selectWithdrawableItems(){
        if (getDaysLeft(item[8]) <= 0){
            if (oldIcon == 'block'){
                setOldIcon('none')
                setNewIcon('block')
                setWithdrawItems(prevItems => [...prevItems, item[1]]);            
            } else if (oldIcon == 'none'){
                setOldIcon('block')
                setNewIcon('none')
                setWithdrawItems(prevItems => prevItems.filter(existingItem => existingItem !== item[1]));
            }
        }
    }

    const numberOfStickers=(item[12].length == 0?0:1) + (item[13].length == 0?0:1) + (item[14].length == 0?0:1) + (item[15].length == 0?0:1) + (item[16].length == 0?0:1)
    const CardstickerNumber = [1,2,3,4,5].slice(0, numberOfStickers)

    return (
        <div className="col-3 gx-1 mb-1 hovereff-b">
            <div>
                <Card className="backpackCard" style={{position:'relative',backgroundColor:'#24252E'}}>
                {newIcon == 'block'?<DoneIcon style={{position:'absolute',left:'5px',zIndex:'5',top:'5px',color:'#ffca4f',fontSize:'1.3rem'}}/>:''}
                {refreshingBackpack == false
                ?
                <Card.Text style={{position:'absolute'}}>{getDaysLeft(item[8]) > 0?<a className="lockedItems" style={{textDecoration:'none',color:'#7F808E',opacity:'0.9',top:'2px',left:'4px'}}><LockIcon style={{width:'16',height:'16',top:'-2px',position:'relative'}}/>{getDaysLeft(item[8])} Days<div className="exactTime">{parseDateString(item[8])}</div></a>:<></>}
                </Card.Text>
                :
                <div className="lockedItems defaultCursor" style={{width:'25%',height:'10%',backgroundColor:'#3C3F4A',color:'transparent',position:'absolute',left:'5%',top:'5%'}}></div>
                }

                

                {refreshingBackpack == false
                ?
                <center className="next_to_image_filler defaultCursor">{parseInt(item[7]) == 0?<Card.Img id={'popUpUImage'+index} style={{opacity:'0.8'}} className="userCardImage defaultCursor" variant="top" src={"https://steamcommunity-a.akamaihd.net/economy/image/"+item[2]} loading="lazy"/>:<Card.Img id={'popUpUImage'+index} className="userCardImage defaultCursor" variant="top" src={"https://steamcommunity-a.akamaihd.net/economy/image/"+item[2]} loading="lazy"/>}</center>
                :
                <div className="next_to_image_filler defaultCursor"><div className="userCardImage defaultCursor" variant="top" loading="lazy"/></div>
                }

                {checkSticker(item[12]) != ''?<img className={'Cardsticker'+CardstickerNumber.shift()} src={checkSticker(item[12])}></img>:<img className='CardstickerHide' src={checkSticker(item[12])}></img>}
                {checkSticker(item[13]) != ''?<img className={'Cardsticker'+CardstickerNumber.shift()} src={checkSticker(item[13])}></img>:<img className='CardstickerHide' src={checkSticker(item[13])}></img>}
                {checkSticker(item[14]) != ''?<img className={'Cardsticker'+CardstickerNumber.shift()} src={checkSticker(item[14])}></img>:<img className='CardstickerHide' src={checkSticker(item[14])}></img>}
                {checkSticker(item[15]) != ''?<img className={'Cardsticker'+CardstickerNumber.shift()} src={checkSticker(item[15])}></img>:<img className='CardstickerHide' src={checkSticker(item[15])}></img>}
                {checkSticker(item[16]) != ''?<img className={'Cardsticker'+CardstickerNumber.shift()} src={checkSticker(item[16])}></img>:<img className='CardstickerHide' src={checkSticker(item[16])}></img>}
                <Card.Body className="userCardBody">
                    
                    {refreshingBackpack == false
                    ?
                    <Card.Text className="userCardText defaultCursor" style={{color:'#7F808E'}}>
                    {stattrak?<div className="last-letter-color defaultCursor">S {adjustWear(Math.round(parseFloat(item[11]) * 10000) / 10000)}</div>:<div>{adjustWear(Math.round(parseFloat(item[11]) * 10000) / 10000)}</div>}
                    </Card.Text>
                    :
                    <div><div className="userCardText defaultCursor" style={{height:'50%',width:'75%',backgroundColor:'#3C3F4A',color:'transparent'}}>loading</div><br></br></div>
                    }

                    {refreshingBackpack == false
                    ?
                    <Card.Title className="userCardTitle defaultCursor" style={{color:'#dcdcdc',position:'relative',fontWeight:'bold'}}>{currency == '$ USD'?<div>$ {item_price.toFixed(2)}</div>:<div>€ {(item_price*0.92).toFixed(2)}</div>}</Card.Title>
                    :
                    <div className="userCardTitle defaultCursor" style={{width:'100%',backgroundColor:'#3C3F4A',color:'transparent'}}>loading</div>
                    }
                    
                    {refreshingBackpack == false
                    ?
                    <center>
                        {getDaysLeft(item[8]) > 0?<Button className="userBackpackCardButton" disabled style={{padding:'0px'}}><div className="tradelocked_text" style={{color:'#7F808E',position:'relative',top:'-6px',float:'left'}}>Trade Locked</div></Button>:(overlay?<Button className="userBackpackCardButton" ><AddCircleOutlineIcon className="cart" style={{color:'#7F808E',height:'18px',position:'relative',top:'-3px',display:(oldIcon),margin:'auto'}}/></Button>:<Button onClick={() => selectWithdrawableItems()} className="userBackpackCardButton" style={{backgroundColor:(newIcon == 'block'?'#8D5054':'')}} ><AddCircleOutlineIcon id='add_icon_backpack' className="cart" style={{color:'#7F808E',height:'18px',position:'relative',top:'-3px',display:(oldIcon),margin:'auto'}}/><DoDisturbAltIcon className="cart" id='remove_icon_backpack' style={{color:'#F96868',height:'18px',position:'relative',top:'-3px',display:(newIcon),margin:'auto'}}/></Button>)}
                    </center>
                    :
                    <div></div>}
                </Card.Body>
            </Card>
            </div>
        </div>
    );
}