import React from "react";
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AddLinkIcon from '@mui/icons-material/AddLink';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Row, Col } from 'react-bootstrap';import Language_wrapper from "./Language_wrapper";
import { useRef, useEffect, useState } from "react";
import Steam_login_button from "./Steam_login_button";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import RefferalCodeAlert from "./RefferalCodeAlert";
import SupportIcon from '@mui/icons-material/Support';
import DiscountIcon from '@mui/icons-material/Discount';
import PaymentsIcon from '@mui/icons-material/Payments';

export default function Menu({language,changeLanguage,currency,setCurrency,loggedIn,userBalance,addBalance,setUserProfileOptionsState,userProfileOptions,logOut,userAvatar,userName,userProfileOptionsState,userID,set_userID,setUserAvatar,setUserBalance,setUserVisibility,setLoggedIn,setUserName,set_user_items,link,setCreatedAt,setTradeLink,setEmail,fetchUserUsingPromoCode, promoCodeUsed, addPopup, tradeLink}) {

    if (link == '//toptrade.gg') {
        var homepage = 'https://toptrade.gg/'
    } else {
        var homepage = 'http://127.0.0.1:3000/'
    }

    /*handles profile options*/
    const excludedDivRef = useRef(null);
    useEffect(() => {
        function handleClickOutside(event) {
        if (excludedDivRef.current && !excludedDivRef.current.contains(event.target)) {
            setUserProfileOptionsState('none')
        }
        }

        document.addEventListener('click', handleClickOutside);
        return () => {
        document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const tradeSkinsRef = useRef(null);
    const helpRef = useRef(null);

    const [prevRef, setPrevRef] = useState(sessionStorage.getItem('prevRef') == null ? 'tradeSkins' : sessionStorage.getItem('prevRef'));
  
    useEffect(() => {
        const active_element_border = document.getElementById('borderTopDiv');

        const updateBorderTop = (ref) => {

            try{
                if (ref && prevRef == 'tradeSkins' && ref.current.getBoundingClientRect() != null && tradeSkinsRef.current.getBoundingClientRect() != null) {
                    const rect1 = tradeSkinsRef.current.getBoundingClientRect();
                    active_element_border.style.left = `${rect1.left}px`;
                    active_element_border.style.width = `${rect1.width}px`;
                    const rect = ref.current.getBoundingClientRect();
                    active_element_border.style.left = `${rect.left}px`;
                    active_element_border.style.width = `${rect.width}px`;
                } else if (ref && prevRef == 'help' && ref.current.getBoundingClientRect() != null && helpRef.current.getBoundingClientRect() != null) {
                    const rect1 = helpRef.current.getBoundingClientRect();
                    active_element_border.style.left = `${rect1.left}px`;
                    active_element_border.style.width = `${rect1.width}px`;
                    const rect = ref.current.getBoundingClientRect();
                    active_element_border.style.left = `${rect.left}px`;
                    active_element_border.style.width = `${rect.width}px`;
                } else if (ref && prevRef == 'profile' && ref.current.getBoundingClientRect() != null && excludedDivRef.current.getBoundingClientRect() != null) {
                    const rect1 = excludedDivRef.current.getBoundingClientRect();
                    active_element_border.style.left = `${rect1.left}px`;
                    active_element_border.style.width = `${rect1.width}px`;
                    const rect = ref.current.getBoundingClientRect();
                    active_element_border.style.left = `${rect.left}px`;
                    active_element_border.style.width = `${rect.width}px`;
                }
            } catch (e) {
                console.log(e)
            }
        }; 

        if (window.location.href === homepage || window.location.href.includes('/?r=')) {
            updateBorderTop(tradeSkinsRef);
            sessionStorage.setItem('prevRef', 'tradeSkins');
        } else if (window.location.href.includes('/help')) {
            updateBorderTop(helpRef);
            sessionStorage.setItem('prevRef', 'help');
        } else if (window.location.href.includes('/profile')) {
            updateBorderTop(excludedDivRef);
            sessionStorage.setItem('prevRef', 'profile');
        } else if (window.location.href.includes('/affiliate')) {
            updateBorderTop(excludedDivRef);
            sessionStorage.setItem('prevRef', 'profile');
        } else if (window.location.href.includes('/backpack')) {
            updateBorderTop(excludedDivRef);
            sessionStorage.setItem('prevRef', 'profile');
        } else if (window.location.href.includes('/transactions')) {
            updateBorderTop(excludedDivRef);
            sessionStorage.setItem('prevRef', 'profile');
        }  else if (window.location.href.includes('/terms-of-use')) {
            updateBorderTop(helpRef);
            sessionStorage.setItem('prevRef', 'help');
        }  else if (window.location.href.includes('/privacy-policy')) {
            updateBorderTop(helpRef);
            sessionStorage.setItem('prevRef', 'help');
        }  else if (window.location.href.includes('/refund-policy')) {
            updateBorderTop(helpRef);
            sessionStorage.setItem('prevRef', 'help');
        }


    }, [window.location.href,language]);

    function selectLanguageImage() {
        if (language == 'English') {
            return require('../images/english.png')
        } else if (language == 'Русский') {
            return require('../images/russian.png')
        } else if (language == 'Deutsch') {
            return require('../images/german.png')
        } else if (language == 'Slovak'){
            return require('../images/slovak.png')
        }
    }

    const [languageIcon, setLanguageIcon] = useState(0);
    const [currencyIcon, setCurrencyIcon] = useState(0);  

    const languageDropdownRef = useRef(null);
  
    const dropdownRef = useRef(null);
  
    const handleDropdownClick = () => {
      setCurrencyIcon(currencyIcon + 180);
    };

    const handleDropdownClickLanguage = () => {
        setLanguageIcon(languageIcon + 180);
      };
  
    const handleCurrencyChange = (newCurrency) => {
      setCurrency(newCurrency);
      setCurrencyIcon(0);
    };

    const changeLanguageSetting = (newLanguage) => {
        changeLanguage(newLanguage);
        setLanguageIcon(0);
    };
  
    const handleDocumentClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setCurrencyIcon(0); // Reset icon when clicking outside
      }

      if (languageDropdownRef.current && !languageDropdownRef.current.contains(event.target)) {
        setLanguageIcon(0);
      }
    };
  
    useEffect(() => {
      document.addEventListener('click', handleDocumentClick);
  
      return () => {
        document.removeEventListener('click', handleDocumentClick);
      };
    }, []);

    const [openReferralCodeAlert,setOpenReferralCodeAlert] = useState(false)

    return (
    <Navbar className='px-3' style={{width:'100%',backgroundColor:'#16181F'}}>

    <RefferalCodeAlert link={link} isOpen={openReferralCodeAlert} setOpenReferralCodeAlert={setOpenReferralCodeAlert} addPopup={addPopup} promoCodeUsed={promoCodeUsed} fetchUserUsingPromoCode={fetchUserUsingPromoCode} logOut={logOut}/>

        <Row style={{width:'100%'}}>
            <Col xs={12} md={3} lg={2} className="brand">
                <Navbar.Brand className='navbarBrand'><strong><Link to="/" style={{ textDecoration: 'none', color: '#FFD700' }}>
                    <img className="navbarBrandImage" src={require('../images/toptradegg.png')} style={{filter: 'drop-shadow(4px 4px 4px transparent)',border:'none'}}></img>
                </Link></strong></Navbar.Brand>
            </Col>
            <Col xs={12} sm={6} md={4} lg={5} className="tradeSkins-and-help">
                <Nav className='navbar-nav-custom'>
                    <Nav.Item className="nav-link2" ref={tradeSkinsRef} style={{color:'white',fontSize:'17px',cursor:'pointer',fontWeight:'500'}}><Link to='/' className='trade-skins' style={{textDecoration:'none',color: window.location.href == 'https://toptrade.gg/' || window.location.href.includes('/?r=')?'#dcdcdc':'#7F808E',fontSize:'15px',marginRight:'1px'}}><SwapVertIcon style={{color:(window.location.href == 'https://toptrade.gg/' || window.location.href.includes('/?r=')?'#ffca4f':'#7F808E'),fontSize:'18px',marginTop:'-2px',marginRight:'2px',fontWeight:'500'}}/>Trade</Link></Nav.Item>

                    <Nav.Item className="nav-link" ref={helpRef} style={{float:'right',fontSize:'15px',cursor:'pointer',fontWeight:'500'}}><Link to='/help' style={{textDecoration:'none',color: window.location.href.includes('/help')?'#dcdcdc':'#7F808E',fontSize:'15px'}}><SupportIcon style={{color:(window.location.href.includes('/help')?'#ffca4f':'#7F808E'),fontSize:'16px',marginRight:'5px',marginTop:'-2px',fontWeight:'500'}}/><Language_wrapper string_in_slovak={'Pomoc'} string_in_english={"Help"} string_in_russian={'Помощь'} string_in_german={'Hilfe'} language={language}/></Link></Nav.Item>

                    {loggedIn?<Nav.Item className="nav-link" onClick={() => setOpenReferralCodeAlert(true)} style={{float:'right',fontSize:'15px',cursor:'pointer',fontWeight:'500'}}><Link style={{textDecoration:'none',color: '#89C74B',fontSize:'15px',fontWeight:'500'}}><PaymentsIcon style={{color:'#89C74B',fontSize:'16px',marginRight:'5px',marginTop:'-2px'}}/>Free $5</Link></Nav.Item>:null}
                </Nav>
            </Col>
            <Col xs={12} sm={6} md={5} lg={5} className="text-right">
                <Nav className='navbar-nav-custom right_items_menu' style={{float:'right'}}>

                <NavDropdown ref={dropdownRef} title={<div onClick={handleDropdownClick} style={{ display: 'flex', alignItems: 'center', color:'#dcdcdc', marginTop:'1px',fontWeight:'500'}}>{currency}<KeyboardArrowDownRoundedIcon style={{ marginLeft:'3px',rotate:`${currencyIcon}deg`,transition:'0.2s',marginTop:'1px',fontSize:'1.4rem',color:'rgb(127, 128, 142)'}} /></div>} id="currency">
                    <NavDropdown.Item onClick={() => (handleCurrencyChange('$ USD'))} >$ USD</NavDropdown.Item> 
                    <NavDropdown.Item onClick={() => (handleCurrencyChange('€ EUR'))} >€ EUR</NavDropdown.Item>
                </NavDropdown>
                
                <NavDropdown ref={languageDropdownRef} title={<div onClick={handleDropdownClickLanguage} style={{ display: 'flex', alignItems: 'center', marginTop:'1px' }}><img src={selectLanguageImage()} className="LanguageButton_option_image" alt="Language" /><KeyboardArrowDownRoundedIcon style={{ marginLeft:'3px',rotate:`${languageIcon}deg`,transition:'0.2s',marginTop:'1px',fontSize:'1.4rem',color:'rgb(127, 128, 142)'}} /></div>} id="basic-nav-dropdown">
                    <NavDropdown.Item onClick={() => (changeLanguageSetting('English'))} ><img v src={require('../images/english.png')} class="LanguageButton_option_image"></img> English</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => (changeLanguageSetting('Slovak'))} ><img src={require('../images/slovak.png')} class="LanguageButton_option_image"></img> Slovensky</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => (changeLanguageSetting('Deutsch'))} ><img src={require('../images/german.png')} class="LanguageButton_option_image"></img> Deutsch</NavDropdown.Item>
                    <NavDropdown.Item onClick={() => (changeLanguageSetting('Русский'))} > <img src={require('../images/russian.png')} class="LanguageButton_option_image"></img> Русский</NavDropdown.Item>
                </NavDropdown>
                {loggedIn?<Nav.Link href='' className='user-balance' style={{float:'left',fontSize:'17px',marginRight:'5px',color:'#dcdcdc',fontWeight:'500'}}>{currency == '$ USD'
                ?
                <div className='addBalanceNavbar' style={{backgroundColor:'#333540',padding:'4px',height:'106%',borderRadius:'4px'}}><div className="balance" style={{backgroundColor:'#333540',float:'left',paddingRight:'5px',position:'relative',top:'-1px'}}>$ {parseFloat(userBalance).toFixed(2)}</div><div className='plus_icon' onClick={addBalance} style={{float:'left',position:'relative',backgroundColor:'#ffca4f',color:'black',borderRadius:'4px'}}><center><strong>+</strong></center></div></div>
                :
                <div className='addBalanceNavbar' style={{backgroundColor:'#333540',padding:'4px',height:'111%',borderRadius:'4px'}}><div className="balance" style={{backgroundColor:'#333540',float:'left',paddingRight:'5px',position:'relative',top:'-1px'}}>€ {parseFloat(userBalance*0.92).toFixed(2)}</div><div className='plus_icon' onClick={addBalance} style={{float:'left',position:'relative',backgroundColor:'#ffca4f',color:'black',borderRadius:'4px'}}><center><strong>+</strong></center></div></div>}</Nav.Link>:null}

                {loggedIn
                ?
                <div ref={excludedDivRef} onClick={userProfileOptions} style={{display:'flex',position:'relative'}}><img style={{borderRadius:'4px'}} className='userAvatar' src={userAvatar}></img>
                <div className='userProfileOptions' style={{zIndex:'5',transition: 'max-height 0.2s ease-in-out, opacity 0.2s ease-in-out',maxHeight: userProfileOptionsState == 'block' ? '360px' : '0',overflow: 'hidden',borderRadius:'8px'}}>

                  <div className='signedIn' style={{color:'rgb(127, 128, 142)',padding:'0.8rem 1.2rem 0.8rem 1.2rem',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Prihlásený ako'} string_in_english={"Signed as"} string_in_russian={'Вы вошли как'} string_in_german={'Eingeloggt als'} language={language}/><div className='usernameFont'>{userName}</div></div>
                  <div className='breakupLine'></div>
                  <Link to='/profile' style={{textDecoration:'none'}}>
                    
                    {tradeLink != 'https://example.com/trade' ? <div className='userProfileOptions_item mt-2'><AccountCircleIcon style={{fontSize:'16px',marginRight:'3px',top:'-2px',position:'relative'}} className="icons"/> <Language_wrapper string_in_slovak={'Profil'} string_in_english={"Profile Details"} string_in_russian={'Профиль'} string_in_german={'Profil'} language={language}/></div>:<div className='userProfileOptions_item mt-2 test_yellow_hover' style={{color:'#ffca4f',backgroundColor:'rgba(255, 210, 105, 0.07)',borderRadius:'4px'}}><AddLinkIcon style={{fontSize:'17px',marginRight:'3px',top:'-2px',position:'relative',color:'#ffca4f'}} className="icons"/> <Language_wrapper string_in_slovak={'Pridať Trade Link'} string_in_english={"Add Trade Link"} string_in_russian={'Добавить торговую ссылку'} string_in_german={'Handelslink hinzufügen'} language={language}/></div>}
                  
                  </Link>
                  <Link to='/backpack' style={{textDecoration:'none'}}><div className='userProfileOptions_item mt-2'><Inventory2Icon style={{fontSize:'17px',marginRight:'3px',top:'-2px',position:'relative'}} className="icons"/> <Language_wrapper string_in_slovak={'Tvoj batoh'} string_in_english={"Your Backpack"} string_in_russian={'Рюкзак'} string_in_german={'Rucksack'} language={language}/></div></Link>
                  <Link to='/transactions' style={{textDecoration:'none'}}><div className='userProfileOptions_item mt-2'><ReceiptLongIcon style={{fontSize:'16px',marginRight:'3px',top:'-2px',position:'relative'}} className="icons"/> <Language_wrapper string_in_slovak={'Transakcie'} string_in_english={"Transaction History"} string_in_russian={'транзакций'} string_in_german={'Transaktionen'} language={language}/></div></Link>
                  <Link to='/affiliate' style={{textDecoration:'none'}}><div className='userProfileOptions_item mt-2'><AddLinkIcon style={{fontSize:'17px',marginRight:'3px',top:'-2px',position:'relative'}} className="icons"/> <Language_wrapper string_in_slovak={'Partnerský program'} string_in_english={"Affiliate Program"} string_in_russian={'Партнер'} string_in_german={'Affiliate'} language={language}/></div></Link>
                  <Link onClick={() => setOpenReferralCodeAlert(true)} style={{textDecoration:'none'}}><div className='userProfileOptions_item mt-2'><DiscountIcon style={{fontSize:'17px',marginRight:'3px',top:'-2px',position:'relative'}}   className="icons_green"/> <Language_wrapper string_in_slovak={'Promokód'} string_in_english={"Claim Code"} string_in_russian={'направления'} string_in_german={'Verweisung'} language={language}/></div></Link>
                  
                  <div className='userProfileOptions_item logoutProfile' onClick={() => (logOut(),window.location.href=(link === 'http://127.0.0.1:5000'?'http://127.0.0.1:3000':'https://toptrade.gg/'))}><ArrowOutwardIcon/> Sign Out</div>
                </div>
                </div>
                :         
                <Steam_login_button setTradeLink={setTradeLink} setEmail={setEmail} link={link} setUserBalance={setUserBalance} style={{float:'right'}} userID={userID} setUserVisibility={setUserVisibility} set_userID={set_userID} setUserAvatar={setUserAvatar} loggedIn={loggedIn} setLoggedIn={setLoggedIn} setUserName={setUserName} set_user_items={set_user_items} setCreatedAt={setCreatedAt}/>}
                </Nav>
            </Col>
        </Row>
        <div id="borderTopDiv"></div>
    </Navbar>
    )
};