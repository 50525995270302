import React, { useEffect } from 'react';
import Modal from 'react-modal';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import DoneIcon from '@mui/icons-material/Done';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import Card from 'react-bootstrap/Card';
import '../Css/Main_app.css';
import { useState } from 'react';
import { Link } from 'react-router-dom';

Modal.setAppElement('#root');

const customAlertStyles = {
  overlay: {
    backgroundColor: 'rgba(	22, 22, 24, 0.9)', // Change this color as needed
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
    paddingTop: '20px', // Adjust as needed for spacing from the top
    zIndex: 5,
  },
  content: {
    overflow: 'hidden',
    position: 'relative',
    left: '-25%',
    border: 'none',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
    paddingLeft: '0px',
    paddingRight: '0px',
    paddingTop: '0px',
    boxShadow: '0 0 4px 10px rgba(12, 12, 13, 0.1)',
    maxWidth: '50%', // Adjust as needed for width
    backgroundColor: '#24252D',
    paddingBottom: '45px',
    marginTop: '20px',
  },
};

const CustomAlert = ({ isOpen, onClose, addBalance ,setOverlay, link, addPopup, fetchUserUsingPromoCode, promoCodeUsed, logOut}) => {

  const [paymentAmmount,setPaymentAmmount] = useState(parseFloat(5).toFixed(2));

  function changepaymentAmmout(event){
    if (event.target.value == ''){
      setPaymentAmmount(0)
    } else if (event.target.value.includes('.') && event.target.value.length <= 7) {
      setPaymentAmmount(parseFloat(event.target.value));
    } else if (event.target.value.length <= 4) {
      setPaymentAmmount(parseFloat(event.target.value));
    }
  }

  function selectPaymenAmmount(ammount){
    if (ammount == 5){
      setPaymentAmmount(parseFloat(5).toFixed(2));

      document.getElementById('d1').style.display = 'block';
      document.getElementById('d2').style.display = 'none';
      document.getElementById('d3').style.display = 'none';
      document.getElementById('d4').style.display = 'none';
      document.getElementById('d5').style.display = 'none';

      document.getElementById('b1').style.backgroundColor = '#2b2d36';
      document.getElementById('b1').style.border = '#ffca4f 1px solid';
      document.getElementById('b1').style.color = '#dcdcdc';

      document.getElementById('b2').style.backgroundColor = '#2B2D36';
      document.getElementById('b2').style.border = '#2b2d36 solid 1px';
      document.getElementById('b2').style.color = '#7F808E';

      document.getElementById('b3').style.backgroundColor = '#2B2D36';
      document.getElementById('b3').style.border = '#2b2d36 solid 1px';
      document.getElementById('b3').style.color = '#7F808E';
      document.getElementById('b4').style.backgroundColor = '#2B2D36';
      document.getElementById('b4').style.border = '#2b2d36 solid 1px';
      document.getElementById('b4').style.color = '#7F808E';
      document.getElementById('b5').style.backgroundColor = '#2B2D36';
      document.getElementById('b5').style.border = '#2b2d36 solid 1px';
      document.getElementById('b5').style.color = '#7F808E';

    } else if (ammount == 10){
      setPaymentAmmount(parseFloat(10).toFixed(2));

      document.getElementById('d1').style.display = 'none';
      document.getElementById('d2').style.display = 'block';
      document.getElementById('d3').style.display = 'none';
      document.getElementById('d4').style.display = 'none';
      document.getElementById('d5').style.display = 'none';

      document.getElementById('b2').style.backgroundColor = '#2b2d36';
      document.getElementById('b2').style.border = '#ffca4f 1px solid';
      document.getElementById('b2').style.color = '#dcdcdc';

      document.getElementById('b3').style.backgroundColor = '#2B2D36';
      document.getElementById('b3').style.border = '#2b2d36 solid 1px';
      document.getElementById('b3').style.color = '#7F808E';

      document.getElementById('b1').style.backgroundColor = '#2B2D36';
      document.getElementById('b1').style.border = '#2b2d36 solid 1px';
      document.getElementById('b1').style.color = '#7F808E';
      document.getElementById('b4').style.backgroundColor = '#2B2D36';
      document.getElementById('b4').style.border = '#2b2d36 solid 1px';
      document.getElementById('b4').style.color = '#7F808E';
      document.getElementById('b5').style.backgroundColor = '#2B2D36';
      document.getElementById('b5').style.border = '#2b2d36 solid 1px';
      document.getElementById('b5').style.color = '#7F808E';
    } else if (ammount == 25){
      setPaymentAmmount(parseFloat(25).toFixed(2));

      document.getElementById('d1').style.display = 'none';
      document.getElementById('d2').style.display = 'none';
      document.getElementById('d3').style.display = 'block';
      document.getElementById('d4').style.display = 'none';
      document.getElementById('d5').style.display = 'none';

      document.getElementById('b3').style.backgroundColor = '#2b2d36';
      document.getElementById('b3').style.border = '#ffca4f 1px solid';
      document.getElementById('b3').style.color = '#dcdcdc';

      document.getElementById('b1').style.backgroundColor = '#2B2D36';
      document.getElementById('b1').style.border = '#2b2d36 solid 1px';
      document.getElementById('b1').style.color = '#7F808E';
      document.getElementById('b2').style.backgroundColor = '#2B2D36';
      document.getElementById('b2').style.border = '#2b2d36 solid 1px';
      document.getElementById('b2').style.color = '#7F808E';
      document.getElementById('b4').style.backgroundColor = '#2B2D36';
      document.getElementById('b4').style.border = '#2b2d36 solid 1px';
      document.getElementById('b4').style.color = '#7F808E';
      document.getElementById('b5').style.backgroundColor = '#2B2D36';
      document.getElementById('b5').style.border = '#2b2d36 solid 1px';
      document.getElementById('b5').style.color = '#7F808E';
    }
    else if (ammount == 50){
      setPaymentAmmount(parseFloat(50).toFixed(2));

      document.getElementById('d1').style.display = 'none';
      document.getElementById('d2').style.display = 'none';
      document.getElementById('d3').style.display = 'none';
      document.getElementById('d4').style.display = 'block';
      document.getElementById('d5').style.display = 'none';

      document.getElementById('b4').style.backgroundColor = '#2b2d36';
      document.getElementById('b4').style.border = '#ffca4f 1px solid';
      document.getElementById('b4').style.color = '#dcdcdc';

      document.getElementById('b1').style.backgroundColor = '#2B2D36';
      document.getElementById('b1').style.border = '#2b2d36 solid 1px';
      document.getElementById('b1').style.color = '#7F808E';
      document.getElementById('b2').style.backgroundColor = '#2B2D36';
      document.getElementById('b2').style.border = '#2b2d36 solid 1px';
      document.getElementById('b2').style.color = '#7F808E';
      document.getElementById('b3').style.backgroundColor = '#2B2D36';
      document.getElementById('b3').style.border = '#2b2d36 solid 1px';
      document.getElementById('b3').style.color = '#7F808E';
      document.getElementById('b5').style.backgroundColor = '#2B2D36';
      document.getElementById('b5').style.border = '#2b2d36 solid 1px';
      document.getElementById('b5').style.color = '#7F808E';
    } else if (ammount == 100){
      setPaymentAmmount(parseFloat(100).toFixed(2));

      document.getElementById('d1').style.display = 'none';
      document.getElementById('d2').style.display = 'none';
      document.getElementById('d3').style.display = 'none';
      document.getElementById('d4').style.display = 'none';
      document.getElementById('d5').style.display = 'block';

      document.getElementById('b5').style.backgroundColor = '#2b2d36';
      document.getElementById('b5').style.border = '#ffca4f 1px solid';
      document.getElementById('b5').style.color = '#dcdcdc';

      document.getElementById('b1').style.backgroundColor = '#2B2D36';
      document.getElementById('b1').style.border = '#2b2d36 solid 1px';
      document.getElementById('b1').style.color = '#7F808E';
      document.getElementById('b2').style.backgroundColor = '#2B2D36';
      document.getElementById('b2').style.border = '#2b2d36 solid 1px';
      document.getElementById('b2').style.color = '#7F808E';
      document.getElementById('b3').style.backgroundColor = '#2B2D36';
      document.getElementById('b3').style.border = '#2b2d36 solid 1px';
      document.getElementById('b3').style.color = '#7F808E';
      document.getElementById('b4').style.backgroundColor = '#2B2D36';
      document.getElementById('b4').style.border = '#2b2d36 solid 1px';
      document.getElementById('b4').style.color = '#7F808E';
    }
  }

  function animateOfferValue(newValue, element, index, currentOfferValues, setCurrentOfferValues) {

    if (element == null) {
      return;
    }

    const animationDuration = 375; // in milliseconds
    const frames = 15; // Assuming 60 frames per second
  
    const valueDifference = newValue - currentOfferValues[index];
    const valueChangePerFrame = valueDifference / frames;
  
    let currentFrame = 0;
  
    const animationInterval = setInterval(() => {
      currentOfferValues[index] += valueChangePerFrame;
      element.textContent = '$' + parseFloat(currentOfferValues[index]).toFixed(2);
      currentFrame++;
  
      if (currentFrame >= frames) {
        clearInterval(animationInterval);
        currentOfferValues[index] = newValue; // Ensure the animation ends at the exact value
        element.textContent = '$' + parseFloat(newValue).toFixed(2);
        setCurrentOfferValues([...currentOfferValues]);
      }
    }, animationDuration / frames);
  }


  const [initialRender, setInitialRender] = useState(true);
  useEffect(() => {
    if (initialRender) {
      setInitialRender(false);
      return;
    } else {
      setCurrentOfferValues(newValues);
      (divElements || []).forEach((element, index) => {
        animateOfferValue(newValues[index], element,index,currentOfferValues,setCurrentOfferValues);
      }
      );
    }
  }, [paymentAmmount]);
  
  // Example usage:
  const divElements = [document.getElementById("offerValue1"), document.getElementById("offerValue2")];
  const newValues = [paymentAmmount*1, paymentAmmount*(promoCodeUsed == ''?1.2:1.25)]; // Replace with your desired values

  const [currentOfferValues, setCurrentOfferValues] = useState(promoCodeUsed == ''?[5, 6.5]:[5, 6.75]);

  async function handlePayment(){
    if (localStorage.getItem('user_id') !== '' && paymentAmmount >= 5.00){
      const response = await fetch(`${link}/api/stripe-checkout`,{method:'POST',headers:{'Content-Type':'application/json'},body:JSON.stringify({ID:localStorage.getItem('user_id'),auth_session_token:localStorage.getItem('auth_session_token'),amount:paymentAmmount,promocode:promoCodeUsed})});
      
      if (response.status == 303){
        const url  = await response.json()
        window.location.href = url
      } else if (response.data == 'minimum amount not met'){
        addPopup('Minimum amount to deposit is $ 5.00')
      } else if (response.data === 'logout'){
        logOut();
      }
    }
  }

  // async function handlePaymentCrypto(){
  //   if (localStorage.getItem('user_id') !== '' && paymentAmmount >= 5.00){
  //     const response = await fetch(`${link}/api/crypto-checkout`,{method:'POST',headers:{'Content-Type':'application/json'},body:JSON.stringify({ID:localStorage.getItem('user_id'),auth_session_token:localStorage.getItem('auth_session_token'),amount:10,promocode:promoCodeUsed})});
      
  //     if (response.ok){
  //       const data = await response.json()
  //       if (data.payment_url) {
  //         // Redirect the user to the payment page
  //         window.open(data.payment_url, '_blank');
  //       } else if (data.error === 'minimum amount not met'){
  //         addPopup('Minimum amount to deposit is $ 5.00')
  //       } else if (data.error === 'logout'){
  //         logOut();
  //       }
  //     } else {
  //       addPopup('There was an error processing your payment, please try again later');
  //     }
  //   }
  // }

  const [promoCode,setPromoCode] = useState(promoCodeUsed);

  useEffect(() => {
    setPromoCode(promoCodeUsed)
  }, [promoCodeUsed,isOpen])

  async function checkPromoCode(){
    if (localStorage.getItem('user_id') !== ''){
      const response = await fetch(`${link}/api/claimPromoCode`,{method:'POST',headers:{'Content-Type':'application/json'},body:JSON.stringify({ID:localStorage.getItem('user_id'),auth_session_token:localStorage.getItem('auth_session_token'),code:promoCode})});
      const data  = await response.json()
      
      if (response.status == 200 && data == 'success'){
        addPopup('Promo code successfully claimed')
        fetchUserUsingPromoCode()
        selectPaymenAmmount(paymentAmmount)
      } else if (response.status == 200 && data == 'invalid code'){
        addPopup('Invalid promo code')
      } else if (response.status == 200 && data == 'verification error'){
        addPopup('Verification error, try to re-login')
      } else if (response.status == 200 && data == 'error'){
        addPopup('If the problem persists, contact support')
        addPopup('There was an error claiming this promocode, try again later')
      } else if (response.status == 429){
        addPopup('Too many requests, wait a minute and try again')
      } else if (data === 'logout'){
        logOut();
      }
    }
  }
  
  return (
    <Modal closeTimeoutMS={250} isOpen={isOpen} className='customAlertStyles_mobile_adapted' onRequestClose={onClose} style={customAlertStyles}>
        <div>
        <div className="row">
          <div className="col-12" style={{padding:'5px',color:'#dcdcdc'}}>
            <h5 className='selectCursor selectText' style={{padding:'10px',float:'left',fontWeight:'500',fontSize:'17px',color:'#dcdcdc'}}><AddShoppingCartIcon  style={{fontSize:'24px',marginRight:'3px',marginLeft:'1px',marginTop:'-2px'}}/>Add funds to your balance</h5>
            <ClearOutlinedIcon className='x_button' style={{float:'right',color:'#7F808E'}} onClick={() => setOverlay(false)}/>
          </div>
          <div className="col-12 selectCursor selectText" style={{paddingLeft:'15px',color:'#7F808E',fontSize:'15px',position:'relative',paddingBottom:'5px',fontWeight:'500'}}>Select a payment method</div>
        </div>

        <div className="row p-2 container-fluid" style={{backgroundColor:'#1a1c23'}}>
          <div className="col-lg-2 col-3 mx-1"  >
            <Card className='creditCard' style={{position:'relative',cursor:'pointer'}}>
              <DoneIcon style={{fontSize:'medium',position:'absolute',right:'2%',top:'2%',color:'#ffca4f'}}/>
              <Card.Img className='creditCardSvg' src={require('../images/creditCard.png')}/>
              <center className='creditCardText'>Credit Card <a className='bonusCredit' style={{backgroundColor:'#56514d',color:'#ffca4f',padding:'2px',borderRadius:'3px',fontSize:'10px',fontWeight:'500',position:'relative',top:'-2px',left:'2px'}}>{promoCodeUsed == ''?'+20%':'+25%'}</a></center>
            </Card>
          </div>
        </div>

        <div className="row overlayPadding">
          <div className="col-5 mb-3 " style={{marginRight:'60%',borderRadius:'4px',marginTop:'-10px',paddingBottom:'23px'}}>{promoCodeUsed == ''?<a className='selectCursor selectText' style={{textDecoration:'none',color:'#dcdcdc',fontSize:'15px',fontWeight:'500'}}>Promo Code <a style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}>(Adds</a><a style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}> 5% </a><a style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}>deposit bonus)</a></a>:<a className='defaultCursor' style={{textDecoration:'none',color:'#dcdcdc',fontSize:'15px',fontWeight:'500'}}>Promo Code <a style={{color:'#7F808E'}}>(</a><a style={{color:'#7f808e',fontSize:'15px',fontWeight:'500'}}>Claimed</a><a style={{color:'#7F808E'}}>)</a></a>}

          <div style={{backgroundColor:'#33353F',height:'auto', borderRadius:'4px',position:'relative'}}><input className='grey-placeholder mt-1 promocodeInput' placeholder={'Enter promo code...'} value={promoCode} onChange={(e) => setPromoCode((e.target.value).toLowerCase())} style={{border:'solid 0px',padding:'3px 3px 3px 5px',borderRadius:'4px',color:'#dcdcdc',backgroundColor:'#2b2c36',float:'left',fontSize:'15px',position:'relative',width:'70%',marginRight:'5px',height:'38px',paddingLeft:'10px'}}></input><button onClick={checkPromoCode} className=' mt-1' style={{border:'none',display:(promoCode != promoCodeUsed?'block':'none'),backgroundColor:'#89C74B',color:'black',borderRadius:'4px',fontSize:'13px',float:'left',padding:'3px 9px',position:'relative',fontWeight:'500',marginLeft:'-62px',top:'6px'}}>Claim</button>
          </div>
          </div>

          <div className="col-5 mt-2 padding-stripe" style={{backgroundColor:'#2B2D36',width:'35%',marginRight:'1%',color:'#7F808E',borderRadius:'4px 4px 4px 4px'}}><a className='selectCursor selectText' style={{textDecoration:'none',color:'#7F808E',fontSize:'15px',fontWeight:'500'}}>Deposit Amount</a><div className='dollarSignInput'>$</div><input type='number' className='paymentAmmount ammounts-input mt-2' value={paymentAmmount == 0?'':paymentAmmount} onChange={changepaymentAmmout} style={{border:'solid 1px',borderRadius:'4px',width:'100%',color:'#dcdcdc',backgroundColor:'#33353F'}}></input>{parseFloat(paymentAmmount).toFixed(2) < 5.00?<div style={{color:'red',position:'absolute',fontSize:'0.9rem'}}>Minimum $ 5.00</div>:<div></div>}</div>
          
          <div className="col-7 mt-2 padding-stripe payment_bg" style={{width:'64%',color:'#dcdcdc',borderRadius:'4px 4px 4px 4px'}}><div style={{position:'relative'}}><a className='selectCursor selectText' style={{textDecoration:'none',color:'#7F808E',fontSize:'15px',fontWeight:'500'}}>Receiving Amount</a> <a className='bonusCredit defaultCursor' style={{backgroundColor:'#444444',color:'#ffca4f',padding:'2px',borderRadius:'3px',fontSize:'10px',position:'absolute',top:'3px',marginLeft:'6px',width:'auto',fontWeight:'500'}}>{promoCodeUsed == ''?'+20%':'+25%'} Bonus</a></div>
          
          <div className='defaultCursor ammounts' style={{display:(paymentAmmount == 0?'block':'none'),color:'#7F808E'}}>Select amount to deposit</div>
          
          <><div className='ammounts selectCursor selectText' style={{ display: 'flex', alignItems: 'center' }}><a id="offerValue1" style={{color: '#dcdcdc', textDecoration: 'none', display: (paymentAmmount == 0 ? 'none' : 'block')}}>${parseFloat(paymentAmmount).toFixed(2)}</a><KeyboardDoubleArrowRightIcon id="KeyboardDoubleArrowRightIcon" style={{ fontSize: '30px', position: 'relative', top: '0px', color: '#dcdcdc', display: (paymentAmmount == 0 ? 'none' : 'block'), marginLeft:'7px',marginRight:'7px' }} /><a id="offerValue2" style={{ color: '#ffca4f', textDecoration: 'underline', display: (paymentAmmount == 0 ? 'none' : 'block') }}>{promoCodeUsed == ''?'$'+parseFloat(paymentAmmount*1.2).toFixed(2):'$'+parseFloat(paymentAmmount*1.25).toFixed(2)}</a></div></>
          
          </div>

          <div id='b1' className="col amountSelection" style={{width:'20%',marginRight:'0.25rem',fontSize:'15px',marginTop:'8px',paddingTop:'5px',borderRadius:'4px',fontWeight:'500',border:(parseFloat(paymentAmmount).toFixed(2) != 5.00 ? '#2b2d36 solid 1px':'#ffca4f solid 1px'),color:(parseFloat(paymentAmmount).toFixed(2) != 5.00 ?'':'#dcdcdc'),backgroundColor:(parseFloat(paymentAmmount).toFixed(2) != 5.00 ?'':'#2b2d36')}} onClick={() => selectPaymenAmmount(5)}><center>$5.00</center><DoneIcon id='d1' className='check' style={{fontSize:'medium',position:'absolute',right:'5%',top:'25%',color:'#ffca4f',display:(parseFloat(paymentAmmount).toFixed(2) != 5.00?'none':'block')}}/></div>
          <div className="col amountSelection" style={{width:'20%',marginRight:'0.25rem',marginLeft:'0.25rem',fontSize:'15px',marginTop:'8px',paddingTop:'5px',borderRadius:'4px',fontWeight:'500',border:(parseFloat(paymentAmmount).toFixed(2) != 10.00 ? '#2b2d36 solid 1px':'#ffca4f solid 1px'),color:(parseFloat(paymentAmmount).toFixed(2) != 10.00?'':'#dcdcdc'),backgroundColor:(parseFloat(paymentAmmount).toFixed(2) != 10.00 ?'':'#2b2d36')}}  id='b2' onClick={() => selectPaymenAmmount(10)} ><center>$10.00</center><DoneIcon className='check' id='d2'   style={{fontSize:'medium',position:'absolute',right:'5%',top:'25%',color:'#ffca4f',display:'none'}}/></div>
          <div className="col amountSelection" style={{width:'20%',marginRight:'0.25rem',marginLeft:'0.25rem',fontSize:'15px',marginTop:'8px',paddingTop:'5px',borderRadius:'4px',fontWeight:'500',border:(parseFloat(paymentAmmount).toFixed(2) != 25.00 ? '#2b2d36 solid 1px':'#ffca4f solid 1px'),color:(parseFloat(paymentAmmount).toFixed(2) != 25.00 ?'':'#dcdcdc'),backgroundColor:(parseFloat(paymentAmmount).toFixed(2) != 25.00 ?'':'#2b2d36')}}  id='b3' onClick={() => selectPaymenAmmount(25)}><center>$25.00</center><DoneIcon className='check' id='d3'   style={{fontSize:'medium',position:'absolute',right:'5%',top:'25%',color:'#ffca4f',display:'none'}}/></div>
          <div className="col amountSelection" style={{width:'20%',marginRight:'0.25rem',marginLeft:'0.25rem',fontSize:'15px',marginTop:'8px',paddingTop:'5px',borderRadius:'4px',fontWeight:'500',border:(parseFloat(paymentAmmount).toFixed(2) != 50.00 ? '#2b2d36 solid 1px':'#ffca4f solid 1px'),color:(parseFloat(paymentAmmount).toFixed(2) != 50.00?'':'#dcdcdc'),backgroundColor:(parseFloat(paymentAmmount).toFixed(2) != 50.00 ?'':'#2b2d36')}}  id='b4' onClick={() => selectPaymenAmmount(50)}><center>$50.00</center><DoneIcon className='check' id='d4'   style={{fontSize:'medium',position:'absolute',right:'5%',top:'25%',color:'#ffca4f',display:'none'}}/></div>
          <div className="col amountSelection" style={{width:'20%',marginLeft:'0.25rem',fontSize:'15px',marginTop:'8px',paddingTop:'5px',borderRadius:'4px',fontWeight:'500',border:(parseFloat(paymentAmmount).toFixed(2) != 100.00 ? '#2b2d36 solid 1px':'#ffca4f solid 1px'),color:(parseFloat(paymentAmmount).toFixed(2) != 100.00 ?'':'#dcdcdc'),backgroundColor:(parseFloat(paymentAmmount).toFixed(2) != 100.00 ?'':'#2b2d36')}}  id='b5' onClick={() => selectPaymenAmmount(100)}><center>$100.00</center><DoneIcon className='check' id='d5'   style={{fontSize:'medium',position:'absolute',right:'5%',top:'25%',color:'#ffca4f',display:'none'}}/></div>
        </div>

        <div className="row" style={{paddingRight:'40px'}}>
          <div className="col-12">
            <div style={{float:'right'}}>
              <div className='defaultCursor' style={{float:'left',color:'#7F808E',fontSize:'12px',marginLeft:'20px',fontWeight:'500'}}>Credit Card Fee:</div>
              <br></br>
              <div className='defaultCursor' style={{float:'right',color:'#dcdcdc',fontSize:'12px',fontWeight:'500',marginTop:'-5px'}}>$ 0.00</div>
            </div>
          </div>

          <div className="col-7 col-sm-8 col-md-7 col-lg-9 mt-2 px-2">
            <div className='selectCursor selectText' style={{textAlign:'left',color:'#7F808E',fontSize:'13px',marginLeft:'37px',fontWeight:'500'}}>By proceeding to checkout you agree to our <Link onClick={() => setOverlay(false)} to='/refund-policy' style={{color:'#dcdcdc',cursor:'pointer',textDecoration:'none',fontWeight:'500',fontSize:'13px'}}>Refund Policy</Link>.</div>
          </div>

          <div className="col-5 col-sm-4 col-md-5 col-lg-3 mt-2">
            <div  style={{width:'100%'}}>
              <button onClick={handlePayment} className='paymentButton' style={{width:'90%', backgroundColor:'#ffca4f',border:'none',color:'black',fontSize:'15px',padding:'10px',borderRadius:'4px',
marginLeft:'22px',fontWeight:'500'}}>Purchase Now</button>
            </div>
          </div>

          {/* <div className="col-5 col-sm-4 col-md-5 col-lg-3 mt-2">
            <div  style={{width:'100%'}}>
              <button onClick={handlePaymentCrypto} className='paymentButton' style={{width:'90%', backgroundColor:'#ffca4f',border:'none',color:'black',fontSize:'15px',padding:'10px',borderRadius:'4px',
marginLeft:'22px',fontWeight:'500'}}>Crypto</button>
            </div>
          </div> */}
        </div>
        </div>
    </Modal>
  );
};

export default CustomAlert;