import React, { useEffect } from "react";
import Menu from "../Components/Menu";
import {Link} from 'react-router-dom';
import Language_wrapper from '../Components/Language_wrapper';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';import AddLinkIcon from '@mui/icons-material/AddLink';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import CustomAlert from '../Components/CustomAlert';
import '../Css/Main_app.css';
import EditIcon from '@mui/icons-material/Edit';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function Profile({language,changeLanguage,userAvatar,setUserAvatar,setUserVisibility,currency,setCurrency,loggedIn,setLoggedIn,addBalance,overlay,userProfileOptionsState,setUserProfileOptionsState,userProfileOptions,logOut,link,userBalance,setUserBalance,userID,set_userID,userName,setUserName,set_user_items,createdAt,setCreatedAt,setEmail,setTradeLink,email,tradeLink,apiKey,setAPIKey,auth_session_token,getuserrefferals,addPopup,renderPopups,setOverlay,email_verification_status,set_email_verification_status,get_user_profile_data,fetchUserUsingPromoCode,promoCodeUsed}) {

    function parseDateJoined(date){
        const parsedDate = new Date(date);
        const options = { weekday: 'short', day: 'numeric', month: 'short', year: 'numeric' };
        const outputString = parsedDate.toLocaleDateString('en-US', options);
        return outputString;
    }

    function isValidEmail(email) {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        return emailPattern.test(email);
    }

    const [inputValueMail, setInputValueMail] = React.useState('');
    const [inputValueTradelink, setInputValueTradelink] = React.useState('');

    useEffect(() => {
        if (email !== ''){
            setInputValueMail(email);
        }
        if (tradeLink !== ''){
            setInputValueTradelink(tradeLink);
        }
    }, [email,tradeLink])

    const [emailSwitch, setEmailSwitch] = React.useState('');
    const [tradeLinkSwitch, setTradeLinkSwitch] = React.useState('');

    const handleInputChangeMail = (event) => {
        setInputValueMail(event.target.value);
    };

    const [inputValue6DigitCode, setInputValue6DigitCode] = React.useState('');

    const handleInput6DigitCoede = (event) => {
        if (event.target.value.length == 6){
            setInputValue6DigitCode(event.target.value);
        }
    };

    const handleInputChangeTradelink = (event) => {
        setInputValueTradelink(event.target.value);
    };

    const [email_updated, set_email_updated] = React.useState(false);

    const [email_verification_sent, setEmailVerificationSent] = React.useState(localStorage.getItem('email_verification_sent') == 'true'?true:false);

    async function updateMail(){
        if (inputValueMail !== '' && isValidEmail(inputValueMail)){
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({email: inputValueMail, userID:localStorage.getItem('user_id'),auth_session_token:localStorage.getItem('auth_session_token')})
            };
            const response = await fetch(`${link}/api/updateEmail`, requestOptions);
            const data = await response.json();
            if (response.status === 200 && data == 'true'){
                getuserrefferals(); 
                addPopup('Email set successfully'); 
                set_email_verification_status(false);
                localStorage.setItem('email_verification_sent','false')
                setEmailVerificationSent(false);
                if (document.getElementById('verifyEmailCodeField')){
                    document.getElementById('verifyEmailCodeField').style.display = 'none';
                }
                setEmailInputAvailable(false);
                setInputValueMail(email);
                get_user_profile_data();
                set_email_updated(true);
            } else if (response.status === 429){
                addPopup('Wait a minute and try again');
            } else if (response.status === 200 && data == 'false') {
                addPopup('This email is already in use')
            } else if (response.status === 200 && data === 'logout'){
                logOut();
            }
        } else {
            addPopup('Invalid email');
        }
    }

    async function updateTradeLink(){
        if (inputValueTradelink !== '' && (/https:\/\/steamcommunity\.com\/tradeoffer\/new\/\?partner=[0-9]+&token=[a-zA-Z0-9-_]+/).test(inputValueTradelink)){
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({tradeLink: inputValueTradelink, userID:localStorage.getItem('user_id'),auth_session_token:localStorage.getItem('auth_session_token')})
            };
            const response = await fetch(`${link}/api/updateTradeLink`, requestOptions);
            const data = await response.json();
            if (response.status === 200 && data == 'true'){
                getuserrefferals(); 
                addPopup('Trade link set successfully');  
                setTradelinkInputAvailable(false);  
                setInputValueTradelink(tradeLink);
                get_user_profile_data();
            } else if (response.status === 429){
                addPopup('Wait a minute and try again');
            } else if (response.status === 200 && data == 'false') {
                addPopup('This trade link is already in use')
            } else if (response.status === 200 && data === 'logout'){
                logOut();
            }
        } else {    
            addPopup('Invalid trade link');
        }
    }

    async function sendVerificationCode(){
        if (localStorage.getItem('user_id') !== null){
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({userID: localStorage.getItem('user_id'),auth_session_token:localStorage.getItem('auth_session_token')})
            };
            const response = await fetch(`${link}/api/sendVerificationCode`, requestOptions);
            const data = await response.json();
            if (response.status === 200 && data == 'true'){
                addPopup('Verification email sent, make sure to check your spam folder');
                setEmailVerificationSent(true);
                localStorage.setItem('email_verification_sent','true')
                if (document.getElementById('verifyEmailCodeField')){
                    document.getElementById('verifyEmailCodeField').style.display = 'block';
                }
            } else if (response.status === 429){
                addPopup('Wait a minute and try again');
            } else if (response.status === 200 && data == 'code already sent') {
                addPopup('Verification code already sent')
            } else if (response.status === 200 && data == 'false') {
                addPopup('There was an error sending verification email')
            } else if (response.status === 200 && data == 'wrong email') {
                addPopup('Select an email to verify')
            } else if (data === 'logout'){
                logOut();
            }
        } else {
            addPopup('Please log in and select an email to verify');
        }
    }

    async function verifyEmail(){
        if (localStorage.getItem('user_id') != null){
            const requestOptions = {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({userID: localStorage.getItem('user_id'),auth_session_token:localStorage.getItem('auth_session_token'),verificationCode: inputValue6DigitCode})
            };
            const response = await fetch(`${link}/api/verifyEmail`, requestOptions);
            const data = await response.json();
            if (response.status === 200 && data == 'true'){
                addPopup('Email verified successfully');
                set_email_verification_status(true);
                document.getElementById('verifyEmailCodeField').style.display = 'none';
                setEmailVerificationSent(false);
                setEmailInputAvailable(false)
                localStorage.setItem('email_verification_sent','false')
            } else if (response.status === 429){
                addPopup('Wait a minute and try again');
            } else if (response.status === 200 && data == 'false') {
                addPopup('There was an error verifying your email, make sure to enter the right verification code')
            }  else if (response.status === 200 && data == 'error') {
                addPopup('There was an error verifying your email, if this issue persists please contact support')
            } else if (data === 'logout'){
                logOut();
            }
        }
    }


    // const [apiSwitch, setApiSwitch] = React.useState(null);
    // const [inputValueAPI, setInputValueAPI] = React.useState('');

    // function handleInputSteamKey(event){
    //     setInputValueAPI(event.target.value);
    // }

    // async function updateSteamKey(){
    //     if (inputValueAPI !== ''){
    //         const requestOptions = {
    //             method: 'POST',
    //             headers: {'Content-Type': 'application/json'},
    //             body: JSON.stringify({steamAPIKey: inputValueAPI, userID: userID,auth_session_token})

    //         };
    //         const response = await fetch(`${link}/api/updateSteamAPIKey`, requestOptions);
    //         const data = await response.json();
    //         if (data == 'true'){
    //             setApiSwitch(true);
    //         } else {
    //             setApiSwitch(false);
    //         }
    //     } else {
    //         setApiSwitch(false);
    //     }
    // }

    const [tradelinkInputAvailable, setTradelinkInputAvailable] = React.useState(false);
    const [emailInputAvailable, setEmailInputAvailable] = React.useState(false);

    return (
        <div>
            {renderPopups}
            <CustomAlert isOpen={overlay} onClose={() =>  setOverlay(false)} addBalance={addBalance} setOverlay={setOverlay} link={link} addPopup={addPopup} fetchUserUsingPromoCode={fetchUserUsingPromoCode} promoCodeUsed={promoCodeUsed} logOut={logOut}/>

            <Menu language={language} changeLanguage={changeLanguage} currency={currency} setCurrency={setCurrency} loggedIn={loggedIn} userBalance={userBalance} addBalance={addBalance} setUserProfileOptionsState={setUserProfileOptionsState} userProfileOptions={userProfileOptions} logOut={logOut} userAvatar={userAvatar} userName={userName} userProfileOptionsState={userProfileOptionsState} userID={userID} set_userID={set_userID} setUserAvatar={setUserAvatar} setUserBalance={setUserBalance} setUserVisibility={setUserVisibility} setLoggedIn={setLoggedIn} setUserName={setUserName} set_user_items={set_user_items} link={link} setCreatedAt={setCreatedAt} setEmail={setEmail} setTradeLink={setTradeLink} getuserrefferals={getuserrefferals} fetchUserUsingPromoCode={fetchUserUsingPromoCode} addPopup={addPopup} promoCodeUsed={promoCodeUsed} tradeLink={tradeLink}/>

            <div className="mt-4 profileLeft" style={{backgroundColor:'#24252E',borderRadius:'8px'}}>
                <div className="on_profile_padding">
                    <Link to='/profile' style={{textDecoration:'none'}}><div className='userProfileOptions_item_on_profile mt-2' style={{color:'#dcdcdc',backgroundColor:'#383A45', borderRadius:'4px'}}><AccountCircleIcon  style={{color:'#ffca4f',fontSize:'17px',marginRight:'3px'}} className="icon"/> <Language_wrapper string_in_slovak={'Profil'} string_in_english={"Profile Details"} string_in_russian={'детали профиля'} string_in_german={'Profildetails'} language={language}/></div></Link>
                    <Link to='/backpack' style={{textDecoration:'none'}}><div className='userProfileOptions_item_on_profile mt-2'><Inventory2Icon style={{fontSize:'17px',marginRight:'3px',marginTop:'1px'}} className="icon"/> <Language_wrapper string_in_slovak={'Tvoj Batoh'} string_in_english={"Your Backpack"} string_in_russian={'Ваш рюкзак'} string_in_german={'Dein Rucksack'} language={language}/></div></Link>
                    <Link to='/transactions' style={{textDecoration:'none'}}><div className='userProfileOptions_item_on_profile mt-2'><ReceiptLongIcon style={{fontSize:'17px',marginRight:'3px'}} className="icon"/> <Language_wrapper string_in_slovak={'Transakcie'} string_in_english={"Transaction History"} string_in_russian={'История транзакций'} string_in_german={'Verlauf der Transaktionen'} language={language}/></div></Link>
                    <Link to='/affiliate' style={{textDecoration:'none'}}><div className='userProfileOptions_item_on_profile mt-2'><AddLinkIcon style={{fontSize:'17px',marginRight:'3px'}} className="icon"/> <Language_wrapper string_in_slovak={'Partnerský program'} string_in_english={"Affiliate Program"} string_in_russian={'Партнерская программа'} string_in_german={'Partnerprogramm'} language={language}/></div></Link>
                    <div className='userProfileOptions_item_on_profile logout' onClick={() => (logOut(),window.location.href=(link == 'http://127.0.0.1:5000'?'http://127.0.0.1:3000':'https://toptrade.gg/'))}><ArrowOutwardIcon style={{fontSize:'17px',marginRight:'3px',position:'relative',top:'-2px'}}/> Sign Out</div>
                </div>
            </div>
            <div className="mt-4 profileRight on_profile_padding_right_box" style={{backgroundColor:'#24252E',borderRadius:'8px',paddingBottom:'20px'}}>
                <div className="Profile_details selectCursor selectText"><AccountCircleIcon style={{color:'#ffca4f',top:'-2px',position:'relative',fontSize:'20px',left:'1px',marginLeft:'0px'}}/><strong style={{color:'#dcdcdc',fontSize:'17px',fontWeight:'500',position:'relative',top:'-1px',marginLeft:'7px'}}><Language_wrapper string_in_slovak={'Profil'} string_in_english={"Profile Details"} string_in_russian={'детали профиля'} string_in_german={'Profildetails'} language={language}/></strong></div>
                <div className="rightBoxProfile">
                    <img style={{filter: 'drop-shadow(4px 4px 4px transparent)',borderRadius:'8px',height:'50px',width:'auto',float:'left',marginTop:'-4px'}} src={userAvatar}></img>
                    <div className="row">
                        <div className="col-12 selectCursor selectText">
                        <div style={{padding:'15px 0px 0px 10px',color:'#dcdcdc',fontSize:'15px',fontWeight:'500',marginTop:'-17px'}}>{userName}</div>
                        </div>
                        <div className="col-12 selectCursor selectText">
                        <div style={{padding:'0px 0px 0px 10px',color:'rgb(127, 128, 142)',fontSize:'15px',marginTop:'0px',fontWeight:'500'}}>Joined {parseDateJoined(createdAt)}</div>
                        </div>
                    </div>
                    <br></br>

                    <div style={{color:'#dcdcdc',fontSize:'17px',fontWeight:'500',marginTop:'29px'}} className="mt-4 selectCursor selectText"><Language_wrapper string_in_slovak={'Obchodné nastavenia'} string_in_english={"Trading Settings"} string_in_russian={'Торговые настройки'} string_in_german={'Handelseinstellungen'} language={language}/></div>
                    <a className="fontForSubTextProfile selectCursor selectCursor selectText" style={{color:'#7F808E',textDecoration:'none',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Steam Trade Link '} string_in_english={"Steam Trade Link "} string_in_russian={'Ссылка на трейд Steam '} string_in_german={'Steam-Handelslink '} language={language}/></a><a className="selectCursor selectText" style={{color:'#ffca4f',fontSize:'13px',fontWeight:'500'}} target="_blank" href={'https://steamcommunity.com/profiles/'+userID+'/tradeoffers/privacy#trade_offer_access_url'}><Language_wrapper string_in_slovak={'Získať link'} string_in_english={"Get link"} string_in_russian={'Найти торговую ссылку'} string_in_german={'Finden Sie den Handelslink'} language={language}/></a>
                    <br></br>

                    {tradeLink != 'https://example.com/trade'
                    ?
                    <div><input className="profile_input_fields" disabled={!tradelinkInputAvailable?true:false} value={inputValueTradelink} onChange={handleInputChangeTradelink} style={{borderRadius:'3px',backgroundColor:'#2B2C36',color:(tradelinkInputAvailable?'#dcdcdc':'#7F808E'),paddingRight:'35px',width:'100%',transition:'color 0.25s',fontWeight:'500',fontSize:'15px'}} placeholder={"Enter your Steam Trade Link..."}></input><EditIcon onClick={() => (setTradelinkInputAvailable(!tradelinkInputAvailable))} style={{position:'absolute',marginLeft:'-35px',marginTop:'10px',color:(tradelinkInputAvailable?'#dcdcdc':'#7F808E'),backgroundColor:'#2B2C36',fontSize:'1.25rem',width:'35px',cursor:'pointer',visibility:(tradeLink == inputValueTradelink?'visible':'hidden'),transition:'color 0.25s'}}/></div>
                    :
                    <input className="profile_input_fields" onChange={handleInputChangeTradelink} style={{borderRadius:'3px',backgroundColor:'#2B2C36',color:(tradelinkInputAvailable?'#dcdcdc':'#7F808E'),paddingRight:'35px',width:'100%',transition:'color 0.25s'}} placeholder={"Enter your Steam Trade Link..."}></input>
                    }

                    {tradeLink == inputValueTradelink?'':(tradeLink != 'https://example.com/trade'?<div><a style={{color:'#ffca4f',float:'right',fontSize:'12px',cursor:'pointer'}} onClick={(updateTradeLink)}>Change</a><a style={{color:'#7F808E',float:'right',fontSize:'12px',marginRight:'5px',cursor:'pointer'}} className="cancel" onClick={() => (setTradelinkInputAvailable(false),setInputValueTradelink(tradeLink))}>Cancel</a></div>:(inputValueTradelink == ''?'':<a onClick={updateTradeLink} style={{color:'#ffca4f',cursor:'pointer',fontSize:'12px',float:'right'}}>Save</a>))}
                    <div style={{color:(tradeLinkSwitch == 'Trade link set successfully'?'green':'red'),position:'absolute'}}> {tradeLinkSwitch}</div>

                    <div style={{color:'#dcdcdc',fontSize:'17px',fontWeight:'500',marginTop:'29px'}} className="selectCursor selectText"><Language_wrapper string_in_slovak={'Kontaktné údaje'} string_in_english={"Contact Details"} string_in_russian={'Контактная информация'} string_in_german={'Kontaktdetails'} language={language}/></div>
                    <a className="fontForSubTextProfile selectCursor selectText" style={{color:'#7F808E',textDecoration:'none',fontSize:'15px',fontWeight:'500'}}>Email Adress</a>{email_verification_status == true?<a style={{color:'#81BD1B',backgroundColor:'#455736',borderRadius:'3px',padding:'2px',fontSize:'9px',position:'relative',top:'-2px',left:'5px',fontWeight:'500'}}>Verified</a>:<a style={{color:'#F96868',backgroundColor:'#674C5C',borderRadius:'3px',padding:'2px',fontSize:'9px',position:'relative',top:'-2px',left:'5px',fontWeight:'500'}}>Not Verified</a>}
                    <br></br>


                    {email != 'user@example.com'
                    ?
                    <div><input className="profile_input_fields " disabled={!emailInputAvailable?true:false} onChange={handleInputChangeMail} value={inputValueMail} style={{borderRadius:'3px',backgroundColor:'#2B2C36',float:'left',border:'none',paddingRight:'35px',color:(emailInputAvailable?'#dcdcdc':'#7F808E'),width:'100%',transition:'color 0.25s',fontWeight:'500',fontSize:'15px'}} placeholder={"Enter your email adress..."}></input><EditIcon onClick={() => (setEmailInputAvailable(!emailInputAvailable))} style={{position:'absolute',marginLeft:'-35px',marginTop:'10px',color:(emailInputAvailable?'#dcdcdc':'#7F808E'),backgroundColor:'#2B2C36',fontSize:'1.25rem',width:'35px',cursor:'pointer',visibility:(inputValueMail == email?'visible':'hidden'),transition:'color 0.25s'}}/></div>
                    :
                    <input className="profile_input_fields" onChange={handleInputChangeMail} style={{borderRadius:'3px',backgroundColor:'#2B2C36',float:'left',border:'none',paddingRight:'35px',color:(emailInputAvailable?'#dcdcdc':'#7F808E'),width:'100%',transition:'color 0.25s'}} placeholder={"Enter your Email..."}></input>
                    }

                    <br></br>
                    {inputValueMail != email && inputValueMail != ''?(email != 'user@example.com'?<div><a onClick={updateMail} style={{color:'#ffca4f',cursor:'pointer',fontSize:'12px',float:'right'}}>Change</a><a style={{color:'#7F808E',float:'right',fontSize:'12px',marginRight:'5px',cursor:'pointer'}} className="cancel" onClick={() => ((setEmailInputAvailable(false),setInputValueMail(email)))}>Cancel</a></div>:<a onClick={updateMail} style={{color:'#ffca4f',cursor:'pointer',fontSize:'12px',float:'right'}}>Save</a>):(email_verification_status == false && email != 'user@example.com'?(email_verification_sent == false && email_verification_status == false && email != 'user@example.com' && email != ''?<a style={{color:'#ffca4f',float:'right',cursor:'pointer',fontSize:'12px'}} onClick={sendVerificationCode}>Send verification code</a>:<a style={{color:'#ffca4f',float:'right',cursor:'pointer',fontSize:'12px'}} onClick={sendVerificationCode}>Resend code</a>):'')}
                    
                    {email_verification_sent && inputValueMail == email?<div id="verifyEmailCodeField" style={{display:'block'}}><input className="profile_input_fields" onChange={handleInput6DigitCoede} style={{borderRadius:'3px',backgroundColor:'#2B2C36',border:'none',width:'50%'}}placeholder={"Verification code"}></input><br></br><div style={{width:'50%'}}><a style={{color:'#81BD1B',float:'right',cursor:'pointer'}} onClick={verifyEmail}>Verify</a></div></div>:''}

                    {/* <h3 style={{color:'#dcdcdc'}} className="mt-4"><Language_wrapper string_in_english={"Steam API Key"} string_in_russian={'Steam API Key'} string_in_german={'Steam API Key'} language={language}/></h3>
                    <a className="fontForSubTextProfile" style={{color:'#dcdcdc',textDecoration:'none'}}>API Key <a style={{color:'#ffca4f'}} target="_blank" href={'https://steamcommunity.com/dev/apikey'}><Language_wrapper string_in_english={"Find API Key"} string_in_russian={'Найти ключ API'} string_in_german={'Finden Sie den API-Schlüssel'} language={language}/></a></a>
                    <br></br>
                    <input className="profile_input_fields" onChange={handleInputSteamKey} style={{borderRadius:'3px',backgroundColor:'#2B2C36'}}placeholder={apiKey == ''?"Enter your API Key":apiKey}></input>
                    <button className="trade_url_button" onClick={updateSteamKey}>Update</button>
                    <div style={{color:'red',position:'absolute'}}>{apiSwitch == ''?'Invalid API Key':''}</div>
                    {apiSwitch == true?<div style={{color:'green',position:'absolute'}}>API Key updated successfully</div>:''} */}
                </div>
            </div>
        </div>
    )
} 