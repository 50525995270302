import React, { useEffect } from "react";
import Menu from "../Components/Menu";
import {Link} from 'react-router-dom';
import Language_wrapper from '../Components/Language_wrapper';
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';import AddLinkIcon from '@mui/icons-material/AddLink';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import ShareIcon from '@mui/icons-material/Share';
import PercentIcon from '@mui/icons-material/Percent';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CustomAlert from "../Components/CustomAlert";
import '../Css/Main_app.css';
import EditIcon from '@mui/icons-material/Edit';
import HttpsIcon from '@mui/icons-material/Https';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function Affiliate({language,changeLanguage,userAvatar,setUserAvatar,setUserVisibility,currency,setCurrency,loggedIn,setLoggedIn,addBalance,overlay,userProfileOptionsState,setUserProfileOptionsState,userProfileOptions,logOut,link,userBalance,setUserBalance,userID,set_userID,userName,setUserName,set_user_items,createdAt,setCreatedAt,userRefferals,setEmail,setTradeLink,setUserRefferals,get_user_refferals,auth_session_token,getuserrefferals,addPopup,renderPopups,setOverlay,email_verification_status,fetchUserUsingPromoCode,promoCodeUsed,get_user_balance,tradeLink}) {

    const [inputValue,setInputValue] = React.useState(userRefferals[0])
    const [refferalResponse,setRefferalResponse] = React.useState('')
    const [tryAgainLaterRefferal,setTryAgainLaterRefferal] = React.useState(false)

    useEffect(() => {
        if (userRefferals[0] == null){
            setInputValue('')
        } else {
            setInputValue(userRefferals[0])
        }
    },[userRefferals])

    const handleInputChange = (event) => {
        const newValue = event.target.value.slice(0, 17).toLowerCase(); // Get only the first 17 characters
        setInputValue(newValue);
    };

    async function changeRefferal(){
        if (inputValue.trim() == '' | inputValue == null | inputValue.length <= 2){
            addPopup('Invalid code')
            return
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userID:localStorage.getItem('user_id'), new_refferal: inputValue ,auth_session_token:localStorage.getItem('auth_session_token')})
        };
        const response = await fetch(`${link}/api/changeRefferal`,requestOptions)
        const data = await response.json();
        if (response.status === 200 && data == 'true'){
            getuserrefferals(); 
            addPopup('Code set successfully');  
            setInputAvailable(false)  
        } else if (response.status === 429){
            addPopup('Wait a minute and try again');
        } else if (response.status === 200 && data == 'false') {
            addPopup('This code is already in use')
        } else if (data === 'logout'){
            logOut();
          }
    }

    const [refferalWithdrawal,setRefferalWithdrawal] = React.useState('')

    async function handleRefferalWithdrawal(){
        if (localStorage.getItem('user_id') !== ''){
            const request = await fetch(`${link}/api/withdrawUserRefferalBalance`,{method:'POST',headers:{'Content-Type':'application/json'},body:JSON.stringify({userID:localStorage.getItem('user_id'),auth_session_token:localStorage.getItem('auth_session_token')})});
            const data = await request.json();
            if (request.status == 200 && data === 'true'){
                get_user_refferals();
                get_user_balance();
                addPopup('Withdrawal successful')
                return;
            } else if (request.status == 429) {
                addPopup('You have tried to make a withdrawal recently, wait an hour and try again')
                return;
            } else if (request.status == 200 && data === 'false'){
                addPopup('Insufficient balance, you need at least $ 5.00 in order to withdraw')
                return;
            } else if (request.status == 200 && data === 'not verified'){
                addPopup('You need to verify your email first')
                return;
            } else if (data === 'logout'){
                logOut();
            }
        }
    }

    const [inputAvailable,setInputAvailable] = React.useState(false)

    return (
        <div>
            {renderPopups}
            <CustomAlert isOpen={overlay} onClose={() =>  setOverlay(false)} addBalance={addBalance} setOverlay={setOverlay} link={link} addPopup={addPopup} fetchUserUsingPromoCode={fetchUserUsingPromoCode} promoCodeUsed={promoCodeUsed} logOut={logOut}/>

            <Menu language={language} changeLanguage={changeLanguage} currency={currency} setCurrency={setCurrency} loggedIn={loggedIn} userBalance={userBalance} addBalance={addBalance} setUserProfileOptionsState={setUserProfileOptionsState} userProfileOptions={userProfileOptions} logOut={logOut} userAvatar={userAvatar} userName={userName} userProfileOptionsState={userProfileOptionsState} userID={userID} set_userID={set_userID} setUserAvatar={setUserAvatar} setUserBalance={setUserBalance} setUserVisibility={setUserVisibility} setLoggedIn={setLoggedIn} setUserName={setUserName} set_user_items={set_user_items} link={link} setCreatedAt={setCreatedAt} setEmail={setEmail} setTradeLink={setTradeLink} getuserrefferals={getuserrefferals} fetchUserUsingPromoCode={fetchUserUsingPromoCode} promoCodeUsed={promoCodeUsed} addPopup={addPopup} tradeLink={tradeLink}/>

            <div className="mt-4 profileLeft" style={{backgroundColor:'#24252E',borderRadius:'3px'}}>
                <div className="on_profile_padding">
                    <Link to='/profile' style={{textDecoration:'none'}}><div className='userProfileOptions_item_on_profile mt-2' ><AccountCircleIcon style={{fontSize:'17px',marginRight:'3px'}} className="icon"/> <Language_wrapper string_in_slovak={'Profil'} string_in_english={"Profile Details"} string_in_russian={'детали профиля'} string_in_german={'Profildetails'} language={language}/></div></Link>
                    <Link to='/backpack' style={{textDecoration:'none'}}><div className='userProfileOptions_item_on_profile mt-2'><Inventory2Icon style={{fontSize:'17px',marginRight:'3px',marginTop:'1px'}} className="icon"/> <Language_wrapper string_in_slovak={'Tvoj Batoh'} string_in_english={"Your Backpack"} string_in_russian={'Ваш рюкзак'} string_in_german={'Dein Rucksack'} language={language}/></div></Link>
                    <Link to='/transactions' style={{textDecoration:'none'}}><div className='userProfileOptions_item_on_profile mt-2'><ReceiptLongIcon style={{fontSize:'17px',marginRight:'3px'}} className="icon"/> <Language_wrapper string_in_slovak={'Transakcie'} string_in_english={"Transaction History"} string_in_russian={'История транзакций'} string_in_german={'Verlauf der Transaktionen'} language={language}/></div></Link>
                    <Link to='/affiliate' style={{textDecoration:'none'}}><div style={{color:'#dcdcdc',backgroundColor:'#383a45', borderRadius:'4px'}} className='userProfileOptions_item_on_profile mt-2'><AddLinkIcon style={{fontSize:'17px',marginRight:'3px',color:'#ffd269'}} className="icon"/> <Language_wrapper string_in_slovak={'Partnerský program'} string_in_english={"Affiliate Program"} string_in_russian={'Партнерская программа'} string_in_german={'Partnerprogramm'} language={language}/></div></Link>
                    <div className='userProfileOptions_item_on_profile logout' onClick={() => (logOut(),window.location.href=(link == 'http://127.0.0.1:5000'?'http://127.0.0.1:3000':'https://toptrade.gg/'))}><ArrowOutwardIcon style={{fontSize:'17px',marginRight:'3px',position:'relative',top:'-2px'}}/> Sign Out</div>
                </div>
            </div>
            {email_verification_status == true
            ?
            <div className="mt-4 profileRight on_profile_padding_right_box" style={{backgroundColor:'#24252E',borderRadius:'8px',paddingBottom:'15px'}}>
                <div className="Profile_details selectCursor selectText"><AddLinkIcon style={{color:'#ffca4f',top:'-2px',position:'relative',fontSize:'20px',left:'1px',marginLeft:'0px'}}/><strong style={{color:'#dcdcdc',fontSize:'17px',fontWeight:'500',position:'relative',top:'-1px',marginLeft:'2px'}}><Language_wrapper string_in_slovak={' Partnerský program'} string_in_english={" Affiliate Program"} string_in_russian={' Партнерская программа'} string_in_german={' Partnerprogramm'} language={language}/></strong></div>
                <div className="affiliateBoxLeft" style={{width:'50%',float:'left',padding:'10px'}}>
                    <h6 style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}} className="selectCursor selectText"><Language_wrapper string_in_slovak={'Môj promokód'} string_in_english={"My Refferal Code"} string_in_russian={'Мой реферальный код'} string_in_german={'Mein Empfehlungscode'} language={language}/></h6>

                    <input disabled={!inputAvailable?true:false} style={{borderRadius:'3px',backgroundColor:'#2B2C36',color:(inputAvailable?'#dcdcdc':'#7F808E'),transition:'color 0.25s',width:(userRefferals[0] == inputValue?'90%':'90%')}} className="affiliate_input_fields" onChange={handleInputChange} value={inputValue} placeholder={userRefferals[0] == null?'Enter your refferal code':userRefferals[0]}></input>
                    
                    {userRefferals[0] == inputValue?<EditIcon onClick={() => (setInputAvailable(!inputAvailable),setInputValue(userRefferals[0]))} style={{color:(inputAvailable?'#dcdcdc':'#7F808E'),transition:'color 0.25s',fontSize:'1.2rem',position:'relative',right:'25px',float:'left',top:'6px',cursor:'pointer',visibility:(inputValue == userRefferals[0]?'visible':'hidden')}}/>:<div style={{width:'90%'}}><br></br><a onClick={changeRefferal} style={{color:'#ffca4f',cursor:'pointer',fontSize:'12px',float:'right'}}>Change</a><a className="cancel" style={{color:'#7F808E',float:'right',fontSize:'12px',marginRight:'5px',cursor:'pointer'}} onClick={() => (setInputAvailable(false),setInputValue(userRefferals[0]))}>Cancel</a></div>}

                    <div style={{color:(refferalResponse == 'Code set successfully'?'green':'red')}}>{refferalResponse}</div>
                    <br></br>
                    <h6 className="mt-3 selectCursor selectText" style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Môj referenčný odkaz'} string_in_english={"My Refferal Link"} string_in_russian={'Моя реферальная ссылка'} string_in_german={'Mein Empfehlungslink'} language={language}/></h6>
                    <div className="affiliate_refferal_link white_hover" style={{backgroundColor:'#2B2C36',width:'100%',position:'relative'}}>https://toptrade.gg/?r={userRefferals[0]}<ContentCopyIcon style={{float:'right',color:'#7F808E',fontSize:'1.25rem',top:'9px',right:'5px',position:'absolute',cursor:'pointer'}} onClick={() => (navigator.clipboard.writeText('https://toptrade.gg/?r='+userRefferals[0]), addPopup('Refferal link coppied to clipboard'))}/></div>
                    
                    <br></br>
                    <div className="mt-2 selectCursor selectText">
                        <div style={{color:'#dcdcdc',fontSize:'17px',fontWeight:'500',margin:0,marginTop:'20px'}}><Language_wrapper string_in_slovak={'Čo dostanem?'} string_in_english={"What do I get?"} string_in_russian={'Что я могу получить?'} string_in_german={'Was bekomme ich?'} language={language}/></div>
                        <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Dostaneš 1% '} string_in_english={"You get 1% "} string_in_russian={'Вы получаете 1% '} string_in_german={'Du erhältst 1% '} language={language}/><Language_wrapper string_in_slovak={'podiel na príjmoch z celkových obchodov uskutočnených prostredníctvom vášho referenčného kódu.'} string_in_english={"revenue share from total trades done through your refferal code or link."} string_in_russian={'доля дохода от общего количества сделок, совершенных с помощью вашего реферального кода.'} string_in_german={'Umsatzbeteiligung an der Gesamtzahl der über Ihren Empfehlungscode getätigten Trades.'} language={language}/></p>
                    </div>
                    <div className="mt-2 selectCursor selectText">
                        <div style={{color:'#dcdcdc',fontSize:'17px',fontWeight:'500',margin:0}}><Language_wrapper string_in_slovak={'Čo získajú tí, čo použijú môj promokód?'} string_in_english={"What do my refferals get?"} string_in_russian={'Что получают мои рефералы?'} string_in_german={'Was bekommen meine Empfehlungen?'} language={language}/></div>
                        <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Tí, čo použijú tvoj promokód dostanú 5% '} string_in_english={"Your referrals get a chance to earn up to $5 plus an additional 5% "} string_in_russian={'Ваши рефералы получают 5% '} string_in_german={'Ihre Empfehlungen erhalten 5% '} language={language}/><strong></strong><Language_wrapper string_in_slovak={'vkladací bonus v našom platobnom systéme.'} string_in_english={"deposit bonus in our payment system."} string_in_russian={'бонус на депозит в нашей платежной системе'} string_in_german={'Einzahlungsbonus in unserem Zahlungssystem.'} language={language}/></p>
                    </div>

                    <br></br>
                    <div className="howdoesitwork mt-2 selectCursor selectText" style={{backgroundColor:'#2B2C36',width:'205%',padding:'7px',borderRadius:'4px'}}>
                        <div style={{color:'#dcdcdc',paddingLeft:'5px',fontSize:'17px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Ako to funguje ?'} string_in_english={"How does it work?"} string_in_russian={'Как это работает ?'} string_in_german={'Wie funktioniert es ?'} language={language}/></div>
                        <div style={{padding:'10px',width:'90%',marginBottom:'-17px'}}>
                            <div>
                                <ShareIcon style={{color:'#dcdcdc',fontSize:'xx-large'}}/><a style={{position:'relative',top:'-7px',color:'#dcdcdc',left:'5px',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Zdieľajte svoj referenčný kód alebo odkaz.'} string_in_english={"Share your refferal code or link"} string_in_russian={'Поделитесь своим реферальным кодом или ссылкой.'} string_in_german={'Teilen Sie Ihren Empfehlungscode oder Link.'} language={language}/></a><div style={{position:'relative',top:'-18px',left:'37px',color:'#7F808E',fontSize:'12px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Použite svoj referenčný kód alebo odkaz na pozvanie svojich priateľov a komunity na TopTrade.'} string_in_english={"Use your refferal code or link to invite your friends and community to TopTrade."} string_in_russian={'Используйте свой реферальный код или ссылку, чтобы пригласить своих друзей и сообщество в TopTrade.'} string_in_german={'Verwenden Sie Ihren Empfehlungscode oder Link, um Ihre Freunde und Community zu TopTrade einzuladen.'} language={language}/></div>
                            </div>
                            <div>
                                <PercentIcon style={{color:'#dcdcdc',fontSize:'xx-large',position:'relative',top:'-1px'}}/><a style={{position:'relative',top:'-7px',color:'#dcdcdc',left:'5px',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Kedykoľvek si vyberte peniaze.'} string_in_english={"Withdraw at any time"} string_in_russian={'Предоставьте премиальный бонус своим друзьям'} string_in_german={'Bieten Sie Ihren Freunden einen Premium-Bonus'} language={language}/></a><div style={{position:'relative',top:'-18px',left:'37px',color:'#7F808E',fontSize:'12px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Tí, ktorí použijú váš referenčný kód alebo odkaz, ako aj vy, získajú prioritné spracovanie obchodnej ponuky a získajú 5% vkladový bonus na TopTrade.'} string_in_english={"Those who use your refferal code or link, as well as you, will get priority trade offer handling and be given 5% deposit bonus."} string_in_russian={'Друзья, которые используют вашу реферальную ссылку или код, а также вы получите приоритетную обработку торговых предложений и 5% бонус на депозит на нашем сайте.'} string_in_german={'Freunde, die Ihren Empfehlungslink oder -code verwenden, sowie Sie, erhalten eine vorrangige Bearbeitung des Handelsangebots und erhalten einen Einzahlungsbonus von 5 % auf unserer Website.'} language={language}/></div>
                            </div>
                            <div>
                                <ReceiptIcon style={{color:'#dcdcdc',fontSize:'xx-large',position:'relative',top:'-1px'}}/><a style={{position:'relative',top:'-7px',color:'#dcdcdc',left:'5px',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={"Získajte zaplatené za každý obchod, ktorý urobia vaše odporúčania."} string_in_english={"Get paid for every trade your refferals make"} string_in_russian={'Получайте оплату за каждую сделку, которую совершают ваши рефералы.'} string_in_german={'Lassen Sie sich für jeden Trade bezahlen, den Ihre Empfehlungen tätigen.'} language={language}/></a><div style={{position:'relative',top:'-18px',left:'37px',color:'#7F808E',fontSize:'12px',fontWeight:'400'}}><Language_wrapper string_in_slovak={'Po použití vášho referenčného kódu alebo odkazu dostanete 1% z celkovej hodnoty, ktorú zobchodovali na TopTrade.'} string_in_english={"Once your refferal code or link is used, you will receive 1% of the total value they have traded on TopTrade."} string_in_russian={'Как только ваш реферальный код или ссылка будут использованы, вы получите 1% от общей стоимости, которую они продали на TopTrade.'} string_in_german={'Sobald Ihr Empfehlungscode oder Link verwendet wird, erhalten Sie 1 % des Gesamtwerts, den sie bei TopTrade gehandelt haben.'} language={language}/></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="affiliateBoxRight selectCursor selectText" style={{width:'45%',float:'right',backgroundColor:'#2B2C36',padding:'15px',marginRight:'10px',borderRadius:'4px'}}>
                    <h6 style={{color:'#7F808E',fontSize:'15px'}}><Language_wrapper string_in_slovak={"Príjem, ktorý možno vybrať"} string_in_english={"Revenue available to Withdraw"} string_in_russian={'Доход, доступный для вывода'} string_in_german={'Zum Abheben verfügbarer Umsatz'} language={language}/></h6>
                    <h2 style={{color:'#dcdcdc',fontWeight:'500'}}>$ {parseFloat(userRefferals[3] - userRefferals[4]).toFixed(2)}</h2>
                    <Link><button onClick={handleRefferalWithdrawal} className="mt-3 yellow-hover-button" style={{backgroundColor:'#ffca4f',border:'none',borderRadius:'4px',padding:'5px 10px 5px 10px',fontWeight:'500',fontSize:'17px'}}><Language_wrapper string_in_slovak={'Vybrať'} string_in_english={"Withdraw"} string_in_russian={'Отзывать'} string_in_german={'Zurückziehen'} language={language}/></button></Link>
                    <div style={{color:(refferalWithdrawal == 'Withdrawal successful'?'green':'red'),position:'absolute'}}>{refferalWithdrawal}</div>
                    <br></br>
                    <div className="mt-4">
                        <div className="affiliateBox" style={{width:'48.5%',float:'left',borderRadius:'4px'}}><h6 className="affiliateBoxHeading" style={{margin:'0'}}><Language_wrapper string_in_slovak={'Odporúčania'} string_in_english={"Total Refferals"} string_in_russian={'Всего рефералов'} string_in_german={'Verwendet'} language={language}/></h6><div className="affiliateBoxDiv" style={{fontWeight:'500',color:'#dcdcdc'}}>{userRefferals[1]}</div></div>
                        <div className="affiliateBox" style={{width:'48.5%',float:'right',borderRadius:'4px'}}><h6 className="affiliateBoxHeading" style={{margin:'0'}}><Language_wrapper string_in_slovak={'Celkové príjmy'} string_in_english={"Total Revenue"} string_in_russian={'Общий доход'} string_in_german={'Gesamtumsatz'} language={language}/></h6><div className="affiliateBoxDiv" style={{fontWeight:'500',color:'#dcdcdc'}}>$ {parseFloat(userRefferals[3]).toFixed(2)}</div></div>
                        <div className="affiliateBox2" style={{width:'48.5%',float:'left',borderRadius:'4px'}}><h6 className="affiliateBoxHeading" style={{margin:'0'}}><Language_wrapper string_in_slovak={'Obchodovaný objem'} string_in_english={"Traded Volume"} string_in_russian={'Торговый объем'} string_in_german={'Gehandeltes Volumen'} language={language}/></h6><div className="affiliateBoxDiv" style={{fontWeight:'500',color:'#dcdcdc'}}>$ {parseFloat(userRefferals[2]).toFixed(2)}</div></div>
                        <div className="affiliateBox2" style={{width:'48.5%',float:'right',borderRadius:'4px'}}><h6 className="affiliateBoxHeading" style={{margin:'0'}}><Language_wrapper string_in_slovak={'Vybrané peniaze'} string_in_english={"Total Withdrawn"} string_in_russian={'Всего снято'} string_in_german={'Insgesamt zurückgezogen'} language={language}/></h6><div className="affiliateBoxDiv" style={{fontWeight:'500',color:'#dcdcdc'}}>$ {parseFloat(userRefferals[4]).toFixed(2)}</div></div>
                    </div>
                </div>

                <div className="affiliateBoxRight" style={{width:'45%',float:'right',marginTop:'10px',marginRight:'10px'}}>
                    <a href="https://discord.com/invite/jBXDUfS5wn" target='_blank'><button className="affiliateButtons test_yellow_hover" style={{width:'49%',color:'#ffd269',backgroundColor:'#2B2C36',border:'none',borderRadius:'4px',padding:'7px',marginTop:'-3px',fontSize:'15px',fontWeight:'500'}}>Business Inquiries</button></a>
                    <Link to='/help'><button className="affiliateButtons test_yellow_hover" style={{width:'49%',color:'#ffd269',backgroundColor:'#2B2C36',border:'none',borderRadius:'3px',float:'right',padding:'7px',marginTop:'-3px',fontSize:'15px',fontWeight:'500'}}>Support</button></Link>
                </div>
            </div>
            : 
            <div className="mt-4 profileRight" style={{backgroundColor:'#24252E',borderRadius:'8px',padding:'15px',position:'relative'}}>
                <div className="selectCursor selectText" style={{position:'absolute',top:'35%',fontSize:'17px',zIndex:'1',width:'100%',left:'0px'}}><HttpsIcon style={{position:'relative',fontSize:'3rem',left:'47.4%',color:'#ffca4f',marginTop:'50',top:'-5px'}}/><div style={{position:'relative',color:'#dcdcdc'}}><center>In order to get access to our affiliate program you need to verify your email address.</center></div><div style={{position:'relative',color:'#dcdcdc'}}><center>You can do this under the Profile Details section.</center></div></div>
                
                <div style={{opacity:'0.1'}}>
                    <div className="Profile_details defaultCursor"><AddLinkIcon style={{color:'#ffca4f',top:'-8px',position:'relative',fontSize:'20px',left:'1px',marginLeft:'0px'}}/><strong style={{color:'#dcdcdc',fontSize:'17px',fontWeight:'500',position:'relative',top:'-6px',marginLeft:'2px'}}><Language_wrapper string_in_slovak={' Partnerský program'} string_in_english={" Affiliate Program"} string_in_russian={' Партнерская программа'} string_in_german={' Partnerprogramm'} language={language}/></strong></div>
                    <div className="affiliateBoxLeft" style={{width:'50%',float:'left',padding:'10px'}}>
                        <h6 style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}} className="defaultCursor"><Language_wrapper string_in_slovak={'Môj promokód'} string_in_english={"My Refferal Code"} string_in_russian={'Мой реферальный код'} string_in_german={'Mein Empfehlungscode'} language={language}/></h6>

                        <input disabled={!inputAvailable?true:false} style={{borderRadius:'3px',backgroundColor:'#2B2C36',color:(inputAvailable?'#dcdcdc':'#7F808E'),transition:'color 0.25s',width:(userRefferals[0] == inputValue?'90%':'90%')}} className="affiliate_input_fields" value={''} placeholder={userRefferals[0] == null?'Enter your refferal code':userRefferals[0]}></input>
                        
                        {userRefferals[0] == inputValue?<EditIcon style={{color:(inputAvailable?'#dcdcdc':'#7F808E'),transition:'color 0.25s',fontSize:'1.2rem',position:'relative',right:'25px',float:'left',top:'6px',cursor:'default',visibility:(inputValue == userRefferals[0]?'visible':'hidden')}}/>:<div style={{width:'90%'}}><br></br><a style={{color:'#ffca4f',cursor:'default',fontSize:'12px',float:'right'}}>Change</a><a className="cancel" style={{color:'#7F808E',float:'right',fontSize:'12px',marginRight:'5px',cursor:'default'}}>Cancel</a></div>}

                        <div style={{color:(refferalResponse == 'Code set successfully'?'green':'red')}}>{refferalResponse}</div>
                        <br></br>
                        <h6 className="mt-3 defaultCursor" style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Môj referenčný odkaz'} string_in_english={"My Refferal Link"} string_in_russian={'Моя реферальная ссылка'} string_in_german={'Mein Empfehlungslink'} language={language}/></h6>
                        <div className="affiliate_refferal_link white_hover" style={{backgroundColor:'#2B2C36',width:'100%',position:'relative',cursor:'default'}}>https://toptrade.gg/?r=<ContentCopyIcon style={{float:'right',color:'#7F808E',fontSize:'1.25rem',top:'9px',right:'5px',position:'absolute',cursor:'default'}}/></div>
                        
                        <br></br>
                        <div className="mt-2 selectCursor selectText">
                            <div style={{color:'#dcdcdc',fontSize:'17px',fontWeight:'500',margin:0,marginTop:'20px'}}><Language_wrapper string_in_slovak={'Čo dostanem?'} string_in_english={"What do I get?"} string_in_russian={'Что я могу получить?'} string_in_german={'Was bekomme ich?'} language={language}/></div>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Dostaneš 1% '} string_in_english={"You get 1% "} string_in_russian={'Вы получаете 1% '} string_in_german={'Du erhältst 1% '} language={language}/><Language_wrapper string_in_slovak={'podiel na príjmoch z celkových obchodov uskutočnených prostredníctvom vášho referenčného kódu.'} string_in_english={"revenue share from total trades done through your refferal code or link."} string_in_russian={'доля дохода от общего количества сделок, совершенных с помощью вашего реферального кода.'} string_in_german={'Umsatzbeteiligung an der Gesamtzahl der über Ihren Empfehlungscode getätigten Trades.'} language={language}/></p>
                        </div>
                        <div className="mt-2 selectCursor selectText">
                            <div style={{color:'#dcdcdc',fontSize:'17px',fontWeight:'500',margin:0}}><Language_wrapper string_in_slovak={'Čo získajú tí, čo použijú môj promokód?'} string_in_english={"What do my refferals get?"} string_in_russian={'Что получают мои рефералы?'} string_in_german={'Was bekommen meine Empfehlungen?'} language={language}/></div>
                            <p style={{color:'#7F808E',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Tí, čo použijú tvoj promokód dostanú 5% '} string_in_english={"Your referrals get a chance to earn up to $5 plus an additional 5% "} string_in_russian={'Ваши рефералы получают 5% '} string_in_german={'Ihre Empfehlungen erhalten 5% '} language={language}/><strong></strong><Language_wrapper string_in_slovak={'vkladací bonus v našom platobnom systéme.'} string_in_english={"deposit bonus in our payment system."} string_in_russian={'бонус на депозит в нашей платежной системе'} string_in_german={'Einzahlungsbonus in unserem Zahlungssystem.'} language={language}/></p>
                        </div>

                        <br></br>
                        <div className="howdoesitwork mt-2 defaultCursor" style={{backgroundColor:'#2B2C36',width:'205%',padding:'7px',borderRadius:'4px'}}>
                            <div style={{color:'#dcdcdc',paddingLeft:'5px',fontSize:'17px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Ako to funguje ?'} string_in_english={"How does it work?"} string_in_russian={'Как это работает ?'} string_in_german={'Wie funktioniert es ?'} language={language}/></div>
                            <div style={{padding:'10px',width:'90%',marginBottom:'-17px'}}>
                                <div>
                                    <ShareIcon style={{color:'#dcdcdc',fontSize:'xx-large'}}/><a style={{position:'relative',top:'-7px',color:'#dcdcdc',left:'5px',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Zdieľajte svoj referenčný kód alebo odkaz.'} string_in_english={"Share your refferal code or link"} string_in_russian={'Поделитесь своим реферальным кодом или ссылкой.'} string_in_german={'Teilen Sie Ihren Empfehlungscode oder Link.'} language={language}/></a><div style={{position:'relative',top:'-18px',left:'37px',color:'#7F808E',fontSize:'12px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Použite svoj referenčný kód alebo odkaz na pozvanie svojich priateľov a komunity na TopTrade.'} string_in_english={"Use your refferal code or link to invite your friends and community to TopTrade."} string_in_russian={'Используйте свой реферальный код или ссылку, чтобы пригласить своих друзей и сообщество в TopTrade.'} string_in_german={'Verwenden Sie Ihren Empfehlungscode oder Link, um Ihre Freunde und Community zu TopTrade einzuladen.'} language={language}/></div>
                                </div>
                                <div>
                                    <PercentIcon style={{color:'#dcdcdc',fontSize:'xx-large',position:'relative',top:'-1px'}}/><a style={{position:'relative',top:'-7px',color:'#dcdcdc',left:'5px',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Kedykoľvek si vyberte peniaze.'} string_in_english={"Withdraw at any time"} string_in_russian={'Предоставьте премиальный бонус своим друзьям'} string_in_german={'Bieten Sie Ihren Freunden einen Premium-Bonus'} language={language}/></a><div style={{position:'relative',top:'-18px',left:'37px',color:'#7F808E',fontSize:'12px',fontWeight:'500'}}><Language_wrapper string_in_slovak={'Tí, ktorí použijú váš referenčný kód alebo odkaz, ako aj vy, získajú prioritné spracovanie obchodnej ponuky a získajú 5% vkladový bonus na TopTrade.'} string_in_english={"Those who use your refferal code or link, as well as you, will get priority trade offer handling and be given 5% deposit bonus."} string_in_russian={'Друзья, которые используют вашу реферальную ссылку или код, а также вы получите приоритетную обработку торговых предложений и 5% бонус на депозит на нашем сайте.'} string_in_german={'Freunde, die Ihren Empfehlungslink oder -code verwenden, sowie Sie, erhalten eine vorrangige Bearbeitung des Handelsangebots und erhalten einen Einzahlungsbonus von 5 % auf unserer Website.'} language={language}/></div>
                                </div>
                                <div>
                                    <ReceiptIcon style={{color:'#dcdcdc',fontSize:'xx-large',position:'relative',top:'-1px'}}/><a style={{position:'relative',top:'-7px',color:'#dcdcdc',left:'5px',fontSize:'15px',fontWeight:'500'}}><Language_wrapper string_in_slovak={"Získajte zaplatené za každý obchod, ktorý urobia vaše odporúčania."} string_in_english={"Get paid for every trade your refferals make"} string_in_russian={'Получайте оплату за каждую сделку, которую совершают ваши рефералы.'} string_in_german={'Lassen Sie sich für jeden Trade bezahlen, den Ihre Empfehlungen tätigen.'} language={language}/></a><div style={{position:'relative',top:'-18px',left:'37px',color:'#7F808E',fontSize:'12px',fontWeight:'400'}}><Language_wrapper string_in_slovak={'Po použití vášho referenčného kódu alebo odkazu dostanete 1% z celkovej hodnoty, ktorú zobchodovali na TopTrade.'} string_in_english={"Once your refferal code or link is used, you will receive 1% of the total value they have traded on TopTrade."} string_in_russian={'Как только ваш реферальный код или ссылка будут использованы, вы получите 1% от общей стоимости, которую они продали на TopTrade.'} string_in_german={'Sobald Ihr Empfehlungscode oder Link verwendet wird, erhalten Sie 1 % des Gesamtwerts, den sie bei TopTrade gehandelt haben.'} language={language}/></div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="affiliateBoxRight defaultCursor" style={{width:'45%',float:'right',backgroundColor:'#2B2C36',padding:'15px',marginRight:'10px',borderRadius:'4px'}}>
                        <h6 style={{color:'#7F808E',fontSize:'15px'}}><Language_wrapper string_in_slovak={"Príjem, ktorý možno vybrať"} string_in_english={"Revenue available to Withdraw"} string_in_russian={'Доход, доступный для вывода'} string_in_german={'Zum Abheben verfügbarer Umsatz'} language={language}/></h6>
                        <h2 style={{color:'#dcdcdc',fontWeight:'500'}}>$ {parseFloat(userRefferals[3] - userRefferals[4]).toFixed(2)}</h2>
                        <Link><button className="mt-3 yellow-hover-button" style={{backgroundColor:'#ffca4f',border:'none',borderRadius:'4px',padding:'5px 10px 5px 10px',fontWeight:'500',fontSize:'17px',cursor:'default'}}><Language_wrapper string_in_slovak={'Vybrať'} string_in_english={"Withdraw"} string_in_russian={'Отзывать'} string_in_german={'Zurückziehen'} language={language}/></button></Link>
                        <div style={{color:(refferalWithdrawal == 'Withdrawal successful'?'green':'red'),position:'absolute'}}>{refferalWithdrawal}</div>
                        <br></br>
                        <div className="mt-4">
                            <div className="affiliateBox" style={{width:'48.5%',float:'left',borderRadius:'4px'}}><h6 className="affiliateBoxHeading" style={{margin:'0'}}><Language_wrapper string_in_slovak={'Odporúčania'} string_in_english={"Total Refferals"} string_in_russian={'Всего рефералов'} string_in_german={'Verwendet'} language={language}/></h6><div className="affiliateBoxDiv" style={{fontWeight:'500',color:'#dcdcdc'}}>{userRefferals[1]}</div></div>
                            <div className="affiliateBox" style={{width:'48.5%',float:'right',borderRadius:'4px'}}><h6 className="affiliateBoxHeading" style={{margin:'0'}}><Language_wrapper string_in_slovak={'Celkové príjmy'} string_in_english={"Total Revenue"} string_in_russian={'Общий доход'} string_in_german={'Gesamtumsatz'} language={language}/></h6><div className="affiliateBoxDiv" style={{fontWeight:'500',color:'#dcdcdc'}}>$ {parseFloat(userRefferals[3]).toFixed(2)}</div></div>
                            <div className="affiliateBox2" style={{width:'48.5%',float:'left',borderRadius:'4px'}}><h6 className="affiliateBoxHeading" style={{margin:'0'}}><Language_wrapper string_in_slovak={'Obchodovaný objem'} string_in_english={"Traded Volume"} string_in_russian={'Торговый объем'} string_in_german={'Gehandeltes Volumen'} language={language}/></h6><div className="affiliateBoxDiv" style={{fontWeight:'500',color:'#dcdcdc'}}>$ {parseFloat(userRefferals[2]).toFixed(2)}</div></div>
                            <div className="affiliateBox2" style={{width:'48.5%',float:'right',borderRadius:'4px'}}><h6 className="affiliateBoxHeading" style={{margin:'0'}}><Language_wrapper string_in_slovak={'Vybrané peniaze'} string_in_english={"Total Withdrawn"} string_in_russian={'Всего снято'} string_in_german={'Insgesamt zurückgezogen'} language={language}/></h6><div className="affiliateBoxDiv" style={{fontWeight:'500',color:'#dcdcdc'}}>$ {parseFloat(userRefferals[4]).toFixed(2)}</div></div>
                        </div>
                    </div>

                    <div className="affiliateBoxRight" style={{width:'45%',float:'right',marginTop:'10px',marginRight:'10px'}}>
                        <Link ><button className="affiliateButtons test_yellow_hover" style={{width:'49%',color:'#ffca4f',backgroundColor:'#2B2C36',border:'none',borderRadius:'4px',padding:'7px',marginTop:'-3px',fontSize:'15px',fontWeight:'500',cursor:'default'}}>Business Inquiries</button></Link>
                        <Link ><button className="affiliateButtons test_yellow_hover" style={{width:'49%',color:'#ffca4f',backgroundColor:'#2B2C36',border:'none',borderRadius:'3px',float:'right',padding:'7px',marginTop:'-3px',fontSize:'15px',fontWeight:'500',cursor:'default'}}>Support</button></Link>
                    </div>
                </div>
            </div>
            
            }
        </div>
    )
} 